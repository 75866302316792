import React, { useMemo } from "react";

import "./index.styles.scss";
import YoutubIcon from "assets/svg/socials/YoutubeIcon";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import TwitterIcon from "assets/svg/socials/TwitterIcon";
import { SocialItem } from "./SocialItem";
import { useSelector } from "react-redux";
import { RootState } from "redux/interfaces";
import { useTranslation } from "react-i18next";

export const SettingSocialMedia = () => {
  const { t } = useTranslation();
  const { userSocials } = useSelector((state: RootState) => state.app);

  const arrSocial = useMemo(() => {
    return userSocials ?? [];
  }, [userSocials]);

  const twitterInfo = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === "twitter").length > 0) {
      return arrSocial.filter((item, index) => item.type === "twitter")[0];
    } else {
      return null;
    }
  }, [userSocials]);

  const youtubeInfo = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === "youtube").length > 0) {
      return arrSocial.filter((item, index) => item.type === "youtube")[0];
    } else {
      return null;
    }
  }, [arrSocial]);

  const redditInfo = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === "reddit").length > 0) {
      return arrSocial.filter((item, index) => item.type === "reddit")[0];
    } else {
      return null;
    }
  }, [arrSocial]);

  const spotifyInfo = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === "spotify").length > 0) {
      return arrSocial.filter((item, index) => item.type === "spotify")[0];
    } else {
      return null;
    }
  }, [arrSocial]);

  const instaInfo = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === "instagram").length > 0
    ) {
      return arrSocial.filter((item, index) => item.type === "instagram")[0];
    } else {
      return null;
    }
  }, [arrSocial]);

  return (
    <div className="setting-social-media">
      <div className="social-title font-regular font14">{t("social")}</div>
      <SocialItem type="youtube" socialInfo={youtubeInfo} />
      <SocialItem type="twitter" socialInfo={twitterInfo} />
      <SocialItem type="reddit" socialInfo={redditInfo} />
      <SocialItem type="spotify" socialInfo={spotifyInfo} />
      {/* <SocialItem type="instagram" socialInfo={instaInfo} /> */}
    </div>
  );
};
