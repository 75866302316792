import React from "react";
import "./RoundedIconButton.styles.scss";
import { RoundedIconButtonProps } from "shared/utils/type.def";
import { LoadingCircular } from "../LoadingCircular";

const RoundedIconButton = (props: RoundedIconButtonProps) => {
  return (
    <button
      className={`button-container ${props.size} ${props.color} ${
        props.rounded
      }-rounded ${props.syncColor ? `border-${props.color}` : ``}`}
      style={props.style}
      onClick={props.onClick}
    >
      {props.isLoading ? (
        <LoadingCircular color="main" size={16} />
      ) : (
        props.children
      )}
    </button>
  );
};

export default RoundedIconButton;
