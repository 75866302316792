import i18next from "i18next";
import Cookies from "js-cookie";

export const getToken = async () => {
  let token = Cookies.get("accessToken");
  let attempts = 0;

  while (!token && attempts < 3) {
    await new Promise((resolve) => setTimeout(resolve, 100));
    token = Cookies.get("accessToken");
    attempts++;
  }

  return token || "";
};

export const removeToken = () => {
  Cookies.set("accessToken", null);
};

export const removeCookie = () => {
  Cookies.remove("accessToken");
};

export const getCurrentLng = async () => {
  return await i18next.language;
};

export const getCurrentLngFullName = async () => {
  const lng = await getCurrentLng();

  if (lng) {
    return lng.substring(0, 2);
  } else {
    return "en";
  }
};

export const getReferralCode = () => {
  let code: string = Cookies.get("referralCode") || undefined;

  return code;
};
