import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const HideIcon = (props: IconProps) => {
  return (
    <svg
      style={props.style}
      width={props.width ?? 24}
      height={props.height ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M 4.2070312 2.7929688 L 2.7929688 4.2070312 L 5.0820312 6.4960938 C 2.4151866 8.6404109 1.1608367 11.416191 1.0800781 11.605469 L 0.91210938 12 L 1.0800781 12.394531 C 1.2130781 12.705531 4.428 20 12 20 C 14.066349 20 15.797205 19.449537 17.238281 18.652344 L 19.792969 21.207031 L 21.207031 19.792969 L 4.2070312 2.7929688 z M 12 4 C 10.789 4 9.7000313 4.200625 8.7070312 4.515625 L 10.353516 6.1621094 C 10.874516 6.0631094 11.419 6 12 6 C 17.396 6 20.167625 10.588094 20.890625 11.996094 C 20.596625 12.559094 19.952359 13.651953 18.943359 14.751953 L 20.369141 16.177734 C 22.049141 14.359734 22.854922 12.545531 22.919922 12.394531 L 23.087891 12 L 22.919922 11.605469 C 22.786922 11.294469 19.572 4 12 4 z M 6.5117188 7.9257812 L 8.5625 9.9765625 C 8.2079471 10.569059 8 11.258899 8 12 C 8 14.206 9.794 16 12 16 C 12.741101 16 13.430941 15.792053 14.023438 15.4375 L 15.748047 17.162109 C 14.674347 17.671227 13.428307 18 12 18 C 6.604 18 3.832375 13.411906 3.109375 12.003906 C 3.5249986 11.207948 4.6402378 9.3628241 6.5117188 7.9257812 z M 12.212891 8.0214844 L 15.978516 11.787109 C 15.869516 9.7541094 14.245891 8.1304844 12.212891 8.0214844 z M 10.074219 11.488281 L 12.511719 13.925781 C 12.347951 13.969804 12.177911 14 12 14 C 10.897 14 10 13.103 10 12 C 10 11.822089 10.030196 11.652049 10.074219 11.488281 z"
        fill={"black"}
      />
    </svg>
  );
};
