import React, { useMemo } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import appAction from "redux/app/action";

import "./index.styles.scss";
import { useNavigate } from "react-router-dom";
import { getArrFromString, getFormatedDate } from "shared/utils/utils";
import EmptyHighLightIcon from "assets/svg/EmptyHighLightIcon";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/interfaces";
import { NormalPlusIcon } from "assets/svg/NormalPlusIcon";
import { useTranslation } from "react-i18next";

interface Props {
  data: any;
}

export const CardVibeMatch = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const naivgate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.app);

  const imageUrl = useMemo(() => {
    if (data.images) {
      return getArrFromString(data.images, ",")[0];
    } else {
      return null;
    }
  }, [data]);

  const keyWord = useMemo(() => {
    if (data.keyword_name) {
      const arrTmpKey = getArrFromString(data.keyword_name, ",");
      if (arrTmpKey.length > 0) {
        return arrTmpKey[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }, [data]);

  const highLighted = useMemo(() => {
    if (data.highlight_id) {
      return true;
    } else {
      return false;
    }
  }, [data]);

  // const onDetail = () => {
  //   if (data.creator_id === userData?.id && !highLighted) {
  //     naivgate("/profile");
  //   }
  // };

  const onViewFullImage = () => {
    if (!!imageUrl) {
      dispatch(appAction.onShowImageFullView(imageUrl));
    }
  };

  const renderIcon = () => {
    if (imageUrl?.startsWith("https")) {
      return (
        <img
          src={imageUrl}
          className="image clickable"
          onClick={onViewFullImage}
        />
      );
    } else if (data.creator_id === userData?.id && !highLighted) {
      return (
        <div className="image">
          <NormalPlusIcon width={32} height={32} />
        </div>
      );
    } else {
      return (
        <div className="image">
          <EmptyHighLightIcon />
        </div>
      );
    }
  };

  const renderInfo = () => {
    if (highLighted) {
      return (
        <div className="info">
          <div
            className="font-regular font10 description"
            data-tooltip-id={`mood_desc_${data.id}`}
            data-tooltip-html={`<div class="tooltipe-layout"><div class="font-regular font14">${data.text}</div></div>`}
          >
            {data.text}
          </div>
          <div className="name_container">
            <div className="dot"></div>
            <div
              className="name font-medium font16"
              data-tooltip-id={`mood_title_${data.id}`}
              data-tooltip-html={`<div class="tooltipe-layout"><div class="font-regular font14">${
                keyWord ?? data.topic_name
              }</div></div>`}
            >
              {keyWord ?? data.topic_name}
            </div>
          </div>
          <div className="font-regular font8">
            {getFormatedDate(data.timestamp)}
          </div>
        </div>
      );
    } else {
      return (
        <div className="info">
          <div className="name font-medium font16">{t("no_mood_added")}</div>
        </div>
      );
    }
  };

  return (
    <div className="vibe-match-card">
      <div className="vibe-card-back"></div>
      <div className="vibe-card-front row_align_items clickable">
        {renderIcon()}
        {renderInfo()}
        <ReactTooltip id={`mood_title_${data.id}`} html="true" />
        <ReactTooltip id={`mood_desc_${data.id}`} html="true" />
      </div>
    </div>
  );
};
