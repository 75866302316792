import React from "react";
import Toggle from "react-toggle";

import "./index.styles.scss";

interface Props {
  isSelected: boolean;
  onChanged: (newStatus: boolean) => void;
}

export const CustomSwitchButton = ({ isSelected, onChanged }: Props) => {
  return (
    <Toggle
      defaultChecked={isSelected}
      icons={false}
      className="custom_switch_button center"
      onChange={(e) => onChanged(e.target.checked)}
    />
  );
};
