import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { AuthRoutes } from "./AuthRoutes";
import { RootState } from "redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { MainRoutes } from "./MainRoutes";
import appAction from "redux/app/action";
import { Lightbox } from "react-modal-image";

import "./AppRoutes.styles.scss";
import { StickyHeader } from "components/Common/Header/StickyHeader";
import SideMenu from "components/Common/SideMenu/SideMenu";
import { ReferralRoute } from "components/Common/Referral";
import { CreatingMoodModal } from "components/Common/Modals/CreatingMood";
import { RewardModal } from "components/Common/Modals/RewardModal";
import { RewardHistoryModal } from "components/Common/Modals/RewardHistoryModal";
import { SendInvitationModal } from "components/Common/Modals/SendInvitationModal";
import { SuccessInvitationModal } from "components/Common/Modals/SuccessInvitationModal";
import { SearchModal } from "components/Common/Modals/SearchModal";
import { RewardDetailModal } from "components/Common/Modals/RewardDetailModal";
import { RootNames } from "./rootUrl";
import { RewardCode } from "components/Main/RewardCode";
import { AddReferralModal } from "components/Common/Modals/AddReferralCodeModal";
import { useMediaQuery } from "react-responsive";
import { DeviceSize } from "shared/utils/type.def";
import { MobileIntro } from "components/Auth/MobileIntro";
import { ConfirmDownloadAppModal } from "components/Common/Modals/ConfirmDownloadAppModal";
import { UpdateEmailScreen } from "components/Main/Setting/UpdateEmail";
import EditIcon from "assets/svg/EditIcon";
import { UserQRModal } from "components/Common/Modals/UserQRModal";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { NoExtension } from "components/Auth/NoExtension";
import { Dashboard } from "components/Auth/Dashboard";
import { SetProfile } from "components/Auth/SetProfile";
import Cookies from "js-cookie";

export const AppRoutes = () => {
  const isMobile = useMediaQuery({ maxWidth: DeviceSize.mobile });
  const socialConnect = localStorage.getItem("socialConnect");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    searchQuery,
    showSearchModal,
    isLoggedIn,
    isNewUser,
    isCreatingMood,
    showRewardModal,
    showRewardListModal,
    showInvitationModal,
    showRewardDetailModal,
    showSuccessInvitationModal,
    showAddReferralCodeModal,
    showConfirmDownloadAppModal,
    showQRModal,
    rewardDetail,

    showImageFullView,
    fullImageUrl,
  } = useSelector((state: RootState) => state.app);

  let publicExtensionDetected: any = null;
  const [extensionDetected, setExtensionDetected] = useState<null | boolean>(
    null
  ); // using null to represent an "unknown" initial state

  useEffect(() => {
    let attempts = 0;
    if(socialConnect) {
      if(socialConnect === "reddit" || socialConnect === "twitter")
        navigate("/settings");
    }
    const handleExtensionConfirm = () => {
      console.log("EXTENSION INSTALLED");
      setExtensionDetected(true);
      publicExtensionDetected = true;
    };

    // Set up the listener for the confirmation from the content script
    window.addEventListener(
      "extensionInstalledConfirm",
      handleExtensionConfirm
    );

    function checkForExtension() {
      if (publicExtensionDetected === null && attempts < 2) {
        // limited to 3 attempts
        console.log("Dispatching checkExtensionInstalled event");
        window.dispatchEvent(new CustomEvent("checkExtensionInstalled"));

        attempts++;
        setTimeout(checkForExtension, 1000 * attempts);
      } else if (publicExtensionDetected === null) {
        console.log("Extension not detected after 3 attempts");
        setExtensionDetected(false);
      }
    }

    checkForExtension();

    // Clean up the listener when the component is unmounted
    return () => {
      window.removeEventListener(
        "extensionInstalledConfirm",
        handleExtensionConfirm
      );
    };
  }, [extensionDetected]); // useEffect dependency array ensures it only runs once and when extensionDetected changes

  useEffect(() => {
    const interval = setInterval(() => {
      const token = Cookies.get("accessToken");
      if(!token ||
        token === "" ||
        token === "undefined")
      {
        onLogout();
      }
    }, 3000); 
    return () => clearInterval(interval);
  }, []);

  const onLogout = () => {
    Cookies.set("accessToken", null);
    localStorage.removeItem("userId");

    dispatch(appAction.successLogout());
    // logout();
  };

  useEffect(() => {
    dispatch(appAction.toogleCreatingMoodModal(false));
    dispatch(appAction.toogleRewardHistoryModal(false));
    dispatch(appAction.toogleRewardModal(false));
    dispatch(appAction.toogleInvitationModal(false));
    dispatch(appAction.toogleInvitationSuccessModal(false));
    dispatch(appAction.toogleAddReferralCodeModal(false));
    dispatch(appAction.toogleSearchModal(false));
    dispatch(appAction.onChangedSearch(""));
    dispatch(appAction.onHideImageFullView());
    dispatch(appAction.toogleRewardDetailModal(false, null));
    dispatch(appAction.toogleConfirmDownloadAppModal(false));
    dispatch(appAction.toogleUserQRModal(false));
  }, []);

  if (extensionDetected || (!extensionDetected && isMobile)) {
    if (isMobile) {
      return <MobileIntro />;
    } else {
      return (
        <Routes>
          <Route path={RootNames.rewardCode} element={<RewardCode />} />
          <Route
            path={"*"}
            element={
              isLoggedIn ? (
                isNewUser ? (
                  <SetProfile />
                ) : (
                  <div className="main-container">
                    <StickyHeader />
                    <SideMenu />
                    <div className="main_body">
                      <MainRoutes />
                    </div>
                    {isCreatingMood && <CreatingMoodModal />}
                    {showRewardModal && <RewardModal />}
                    {showRewardListModal && <RewardHistoryModal />}
                    {showInvitationModal && <SendInvitationModal />}
                    {showSuccessInvitationModal && <SuccessInvitationModal />}
                    {showRewardDetailModal && rewardDetail && (
                      <RewardDetailModal data={rewardDetail} />
                    )}
                    {showAddReferralCodeModal && <AddReferralModal />}
                    {(!!searchQuery || showSearchModal) && <SearchModal />}
                    {showImageFullView && (
                      <Lightbox
                        medium={fullImageUrl}
                        large={fullImageUrl}
                        hideDownload={true}
                        hideZoom={true}
                        className="image_view_modal"
                        onClose={() => {
                          dispatch(appAction.onHideImageFullView());
                        }}
                      />
                    )}
                    {showConfirmDownloadAppModal && <ConfirmDownloadAppModal />}
                    {showQRModal && <UserQRModal />}
                  </div>
                )
              ) : (
                <AuthRoutes />
              )
            }
          />
          <Route path="/referral/:code" element={<ReferralRoute />} />
          {isLoggedIn && (
            <Route
              path={RootNames.updateEmail}
              element={<UpdateEmailScreen />}
            />
          )}
        </Routes>
      );
    }
  } else 
  if (extensionDetected === false) {
    return (
      <Routes>
        <Route index path={RootNames.root} element={<Dashboard />} />
        <Route index path={RootNames.allRoot} element={<NoExtension />} />
      </Routes>
    );
  } else {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RectangleLoadingAnimation />
      </div>
    );
  }
};
