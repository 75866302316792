import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";


function RedditConnect() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  if(token == "duplicate"){
    console.error("Social account already linked with another user"); 
  }

  useEffect(() => {
      navigate("/settings");
  }, [navigate]);

  return null;
}

export default RedditConnect;
