import React from "react";
import { getDeviceType } from "shared/utils/utils";

import { useParams } from "react-router-dom";
import Cookies from "js-cookie";

export function ReferralRoute() {
  const { code } = useParams();

  const deviceType = getDeviceType();

  if (deviceType === "desktop") {
    Cookies.set("referralCode", code, {
      expires: process.env.REACT_APP_REFERALL_EXPIRATION,
    });
    window.location.href = process.env.REACT_APP_CHROME_STORE ?? "";
    return null;
  }

  if (deviceType === "android") {
    window.location.href = process.env.REACT_APP_GOOGLE_PLAY ?? "";
    return null;
  }

  if (deviceType === "ios") {
    window.location.href = process.env.REACT_APP_APP_STORE ?? "";
    return null;
  }

  return null;
}
