import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";

import "./index.styles.scss";
import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import { useDispatch, useSelector } from "react-redux";
import { useUserMatch } from "shared/hook/fetchData";
import { RootState } from "redux/interfaces";
import { VibeCard } from "components/Main/Explore/Vibes/VibeCard";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { useTranslation } from "react-i18next";

export const SearchModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { searchQuery } = useSelector((state: RootState) => state.app);

  const {
    data: arrBeData,
    fetchNextPage: fetchNextPageBeTopic,
    hasNextPage: hasNextPageBeTopic,
    isFetching: isFetchingTopic,
    isFetched: isFetchedBeTopic,
    refetch: refetchBeTopics,
  } = useUserMatch(dispatch, searchQuery);

  useEffect(() => {
    refetchBeTopics();
  }, [searchQuery]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      hasNextPageBeTopic && !isFetchingTopic && fetchNextPageBeTopic();
    }
  };

  let arrBeTopics = arrBeData
    ? arrBeData.pages.flatMap((page) => page?.data)
    : [];

  const onHide = () => {
    dispatch(appAction.toogleSearchModal(false));
    dispatch(appAction.onChangedSearch(""));
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_search">
        <div
          className="modal_body"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* {isFetchingTopic && (
            <div style={{ marginBottom: 16 }}>
              <RectangleLoadingAnimation backgroundColor="#E3F4FF" />
            </div>
          )} */}
          {
            <div onScroll={handleScroll} className="vibe-list-body">
              {arrBeTopics.map((item, index) => {
                if (item)
                  return (
                    <VibeCard
                      key={`vibe_card_${index}`}
                      data={item}
                      enablePreview={false}
                    />
                  );
              })}
            </div>
          }
        </div>
      </div>
    </OpacityModal>
  );
};
