import HttpClient from "./apiClient";
import { getCurrentLng } from "./storageHelper";

export async function apiLoginWithEmail(params: {
  email: string;
  password: string;
  is_extension: boolean;
  referral?: string;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/user/loginEmailPassword", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiLoginWithSocial(params: {
  idToken: string;
  is_extension: boolean;
}) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER_URL}/user/loginWithSocial`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${params.idToken}`,
        },
        body: JSON.stringify(params),
      }
    );

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export async function apiRegister(params: {
  username: string;
  fullname: string;
  email: string;
  password: string;
  referral?: string;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/user/registerEmailPassword", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiConfirmEmail(params: {
  email: string;
  verificationCode: string;
  is_extension: boolean;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/user/confirmEmail", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiResendCode(params: { email: string }) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/user/resendCode", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRecoverPassword(params: {
  email: string;
  verificationCode: string;
  newPassword: string;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/user/recoverPassword", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiVerifyRecoverCode(params: {
  email: string;
  verificationCode: string;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/user/validateRecoveryCode", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRequestRecoverPassword(params: { email: string }) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/user/requestRecoverPassword", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditProfileImage(params) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.postWithToken(
      "/user/editProfileImage",

      { ...params, language }
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditProfile(params) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.postWithToken("/user/edit", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserSocials(userId = "") {
  try {
    const language = await getCurrentLng();
    const params = {
      userId,
    };

    const response = await HttpClient.getWithToken(`user/socials`, {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserInfo(userId = "") {
  try {
    const language = await getCurrentLng();
    const params = {
      userId,
    };

    const response = await HttpClient.getWithToken(`user/info`, {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditSocialHandle(params) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.postWithToken("/user/editSocialHandle", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiDeletionSocialHandle(params: { id: any }) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.postWithToken(`deletion/socialHandle`, {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetUserMobileQR(params: {}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.getWithToken(`user/mobileQR`, {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRedditAuthUrl() {
  try {
    const response = await HttpClient.get("/user/redditAuthUrl?app=WEB");
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiRedditConnectAuthUrl() {
  try {
    const response = await HttpClient.getWithToken(
      "/user/redditConnectAuthUrl?app=WEB"
    );
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiUpdateEmail(params: {
  email: string;
  password: string;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.postWithToken(
      "/user/updateEmail",

      { ...params, language }
    );
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiConfirmUpdatedEmail(params: {
  email: string;
  verificationCode: string;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.postWithToken(
      "/user/confirmUpdateEmail",

      { ...params, language }
    );
    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiLoginWithPhone(params: {
  phone_number: string;
  referral?: string;
  captcha?: string;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/login/phone", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiLoginResendCode(params: { phone_number?: string, captcha?: string }) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/login/resendCode", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiLoginVerifyOTP(params: {
  phone_number?: string;
  verificationCode: string;
  referral: any;
  is_extension: boolean;
}) {
  try {
    const language = await getCurrentLng();
    const response = await HttpClient.post("/login/verifyOTP", {
      ...params,
      language,
    });
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
