import React from "react";

function EmptyHighLightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 64}
      height={props.size ?? 64}
      fill="none"
      viewBox="0 0 64 64"
    >
      <mask
        id="mask0_311_18094"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="64"
        height="64"
      >
        <circle cx="32" cy="32" r="32" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_311_18094)">
        <rect
          opacity="0.2"
          x="-60"
          y="-45"
          width="184.5"
          height="123"
          fill="transparent"
        />
        <path
          d="M21.5572 32.0311L18.7744 35.0219C17.0123 36.9158 18.3553 40 20.9422 40H42.0572C44.6441 40 45.9872 36.9158 44.225 35.0219L37.5851 27.8857C36.5115 26.7319 34.6843 26.7319 33.6108 27.8857L29.4491 32.3585C28.4731 33.4074 26.812 33.4074 25.8361 32.3585L25.5315 32.0311C24.458 30.8773 22.6308 30.8773 21.5572 32.0311Z"
          fill="white"
        />
        <ellipse
          cx="27.1611"
          cy="24.3929"
          rx="3.375"
          ry="3.39286"
          fill="white"
        />
      </g>
    </svg>
  );
}

export default EmptyHighLightIcon;
