import React from "react";

function CheckIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width ?? 28}
      height={props.height ?? 24}
      fill="none"
      viewBox="0 0 28 24"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M3.62725 10.0032C2.68225 9.35398 1.41989 9.50159 0.638223 10.3512C-0.142281 11.1995 -0.215785 12.5051 0.466719 13.4399L7.46675 23.0396C7.88909 23.618 8.54242 23.9708 9.24592 23.9984C9.94826 24.0248 10.6261 23.7248 11.0893 23.18L27.4227 3.98051C28.2289 3.03374 28.1857 1.60697 27.3247 0.711797C26.4637 -0.18338 25.0777 -0.240977 24.1478 0.577401L9.15727 13.7939L3.62725 10.0032Z"
        fill="#FAFAFA"
      />
    </svg>
  );
}

export default CheckIcon;
