import React, { useEffect, useState } from "react";

import "./index.styles.scss";
import { useNavigate } from "react-router-dom";
import { RewardCodeBody } from "./RewardCodeBody";
import { useTranslation } from "react-i18next";

export const RewardCode = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onHomePage = () => {
    navigate("/");
  };

  const renderLogo = () => {
    return (
      <div>
        <div
          className="row_align_items clickable"
          style={{
            alignItems: "flex-start",
            justifyContent: "flex-start",
            alignContent: "flex-start",
          }}
          onClick={onHomePage}
        >
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="main-body">
        <div className="main-content">
          <RewardCodeBody onSuccess={onHomePage} onCancel={onHomePage} />
        </div>
      </div>
    );
  };

  return (
    <div className="reward_code row" style={{ marginLeft: 0 }}>
      <div className="left-panel col-md-8">
        {renderLogo()}
        {renderBody()}
      </div>
      <div className="right-panel col-md-4"></div>
    </div>
  );
};
