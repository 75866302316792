import React from "react";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

import "./index.styles.scss";
import ArrowUpperIcon from "assets/svg/ArrowUpperIcon";
import ArrowBottomIcon from "assets/svg/ArrowBottomIcon";

interface Props {
  options: Array<any>;
  defaultOption: any;
  onSelected: (item: string) => void;
}

export const CommonDropdown = ({
  options,
  defaultOption,
  onSelected,
}: Props) => {
  return (
    <div className="common_dropdown clickable">
      <Dropdown
        options={options}
        onChange={(value) => {
          onSelected(value.value);
        }}
        value={defaultOption}
        placeholder=""
        arrowClosed={
          <span className="arrow-closed">
            <ArrowUpperIcon />
          </span>
        }
        arrowOpen={
          <span className="arrow-open">
            <ArrowBottomIcon />
          </span>
        }
        placeholderClassName={"font-bold font16"}
        menuClassName={"font-regular"}
      />
    </div>
  );
};
