import { PercentBtnIcon } from "assets/svg/PercentBtnIcon";
import React from "react";
import VibeMatchPercentCircle from "../VibeMatchPercentCircle";

interface Props {
  fontSize?: number;
  subFontSize?: number;
  borderWidth?: number;
  percent: number;
  vibeType: string;
  buttonSize?: number;
}

export const PercentBtn = ({
  fontSize = 30,
  percent = 0,
  vibeType,
  buttonSize = 60,
  subFontSize,
  borderWidth,
}: Props) => {
  return (
    <div
      style={{
        position: "relative",
        width: buttonSize,
        height: buttonSize,
        display: "flex",
      }}
    >
      <div>
        <VibeMatchPercentCircle
          size={buttonSize}
          percent={percent}
          vibeType={vibeType}
          borderWidth={borderWidth}
        />
      </div>

      <div
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          className="font-bold"
          style={{
            fontSize: fontSize,
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
          }}
        >
          {percent}{" "}
          <div
            style={{ fontSize: subFontSize ?? fontSize - 14, marginLeft: 2 }}
          >
            %
          </div>
        </div>
      </div>
    </div>
  );
};
