import React from "react";
import { Grid } from "@mui/material";

import "./index.styles.scss";
import { CardSubInterest } from "components/Main/SubInterest/CardSubInterest";
import { useTranslation } from "react-i18next";

interface Props {
  data: Array<any>;
}

export const InterestDetailSubInterest = ({ data }: Props) => {
  const { t } = useTranslation();

  const renderEmptyView = () => {
    return (
      <div className="empty_container">
        <img
          src={require("assets/gif/empty_content.gif")}
          className="empty_image"
        />
        <div className="font-bold font18">{t("no_deep_subinterests")}</div>
        <div
          className="font-regular font14"
          style={{
            color: "rgba(0, 0, 0, 0.6)",
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          {t("more_into_other_subinterests")}
        </div>
      </div>
    );
  };

  return (
    <div className="interest_sub_interest">
      <div className="font-medium font24">{t("subinterests")}</div>
      {data.length > 0 ? (
        <Grid container spacing={3}>
          {data.map((item, index) => {
            return (
              <Grid item xs={6}>
                <CardSubInterest
                  key={`card_subinterest_${index}`}
                  data={item}
                  enableAction={false}
                  fontSize={20}
                  onRefresh={() => {}}
                />
              </Grid>
            );
          })}
        </Grid>
      ) : (
        renderEmptyView()
      )}
    </div>
  );
};
