import React, { useEffect, useMemo, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./index.styles.scss";
import { getArrFromString } from "shared/utils/utils";
import { useDispatch } from "react-redux";
import appAction from "redux/app/action";
import {
  apiEditTopicInterest,
  apiHideInterest,
  apiUnHideInterest,
} from "shared/api/topics";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";
import RoundedIconButton from "components/Common/RoundedIconButton/RoundedIconButton";
import { ShowIcon } from "assets/svg/ShowIcon";
import { HideIcon } from "assets/svg/HideIcon";
import { getBase64String } from "shared/utils-IPFS/functions";
import useIPFS from "shared/utils-IPFS/useIPFS";
import EditIcon from "assets/svg/EditIcon";
import { useNavigate } from "react-router-dom";

interface Props {
  data: any;
}

export const CardTrend = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isChangingVisual, setIsChangingVisual] = useState(false);
  const [displayImageUrl, setDisplayImageUrl] = useState<any>(null);
  const [isHidden, setIsHidden] = useState(data.is_hidden);

  const hiddenFileInput = useRef<any>(null);
  const { uploadBase64 } = useIPFS();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  const image = useMemo(() => {
    if (data && (data.images || data.topic_image)) {
      return getArrFromString(data.images ?? data.topic_image, ",")[0];
    } else {
      return null;
    }
  }, [data]);

  useEffect(() => {
    setDisplayImageUrl(image);
  }, [image]);

  const onVisableAction = (e) => {
    e.stopPropagation();

    if (!isChangingVisual) {
      if (isHidden) {
        onShowInterest();
      } else {
        onHideInterest();
      }
    }
  };

  const onHideInterest = async () => {
    setIsChangingVisual(true);

    const res = await apiHideInterest({ vibe: data.id });
    if (res.success) {
      showToast("success", t("msg_interest_hidden"));
      setIsHidden(1);
    } else {
      showToast("error", "Sorry, hiding interest failed - try again!");
    }

    setIsChangingVisual(false);
  };

  const onShowInterest = async () => {
    setIsChangingVisual(true);

    const res = await apiUnHideInterest({ vibe: data.id });
    if (res.success) {
      showToast("success", t("msg_interest_showing_again"));
      setIsHidden(0);
    } else {
      showToast("error", "Sorry, unhiding interest failed - try again!");
    }

    setIsChangingVisual(false);
  };

  const handleClick = (event) => {
    event.stopPropagation();

    if (!isUploadingFile) hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, "");

      if (url !== undefined && url !== "" && url !== null) {
        const res = await apiEditTopicInterest({
          vibe: data.id,
          images: url,
        });

        if (res.success) {
          showToast("success", t("msg_interest_updated"));
          setDisplayImageUrl(url);
        }
      }

      setIsUploadingFile(false);
    }
  };

  const onViewFullImage = (event) => {
    event.stopPropagation();

    navigate(`/trendDetail/${data.id}`);
    // if (!!displayImageUrl) {
    //   dispatch(appAction.onShowImageFullView(displayImageUrl));
    // }
  };

  const renderBg = () => {
    return (
      <img
        src={require("./../../../../../assets/image/bg_card_trend.png")}
        className="bg_img"
      />
    );
  };

  const renderContent = () => {
    return (
      <div className="card_trend_content">
        {renderImg()}
        {renderBody()}

        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept="image/png, image/jpeg"
        />
      </div>
    );
  };

  const renderImg = () => {
    if (!!displayImageUrl) {
      return <img src={displayImageUrl} className="image_view" />;
    } else {
      return <div className="image_view"></div>;
    }
  };

  const renderBody = () => {
    return (
      <div className="card_trend_body" onClick={onViewFullImage}>
        <div style={{ flex: 1 }}></div>
        <div className="trend_title font-bold font24">
          <div
            className="clickable"
            data-tooltip-id={`trend_title_${data.id}`}
            data-tooltip-content={data.topic_name}
          >
            {data.topic_name}
          </div>
        </div>
      </div>
    );
  };

  const renderAction = () => {
    return (
      <div className="action_view">
        <div
          className="clickable"
          onClick={onVisableAction}
          data-tooltip-id={
            isHidden === 1 ? `trend_show_${data.id}` : `trend_hide_${data.id}`
          }
          data-tooltip-content={isHidden === 1 ? "Show" : "Hide"}
        >
          <RoundedIconButton
            children={isHidden == 0 ? <ShowIcon /> : <HideIcon />}
            color={"black"}
            rounded="circle"
            syncColor={true}
            isLoading={isChangingVisual}
            style={{ opacity: 0.7 }}
          />
        </div>
        <div
          className="clickable"
          onClick={handleClick}
          data-tooltip-id={`trend_edit_${data.id}`}
          data-tooltip-content={t("edit_image")}
        >
          <RoundedIconButton
            children={<EditIcon color="black" size={20} />}
            color={"black"}
            rounded="circle"
            syncColor={true}
            isLoading={isUploadingFile}
            style={{ opacity: 0.7 }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="card_trend clickable">
      {renderBg()}
      {renderContent()}
      {renderAction()}

      <ReactTooltip id={`trend_title_${data.id}`} />
      <ReactTooltip id={`trend_hide_${data.id}`} />
      <ReactTooltip id={`trend_show_${data.id}`} />
      <ReactTooltip id={`trend_edit_${data.id}`} />
    </div>
  );
};
