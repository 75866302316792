import React from "react";

import "./index.styles.scss";
import { useCommunityTrendData, useTrendData } from "shared/hook/fetchData";
import { CardTrend } from "./CardTrend";
import HorizontalScrollView from "components/Common/HorizontalScrollView";
import { useTranslation } from "react-i18next";
import { RootState } from "redux/interfaces";
import { useSelector } from "react-redux";

export const ExploreTrend = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: RootState) => state.app);

  const {
    data: trendData,
    fetchNextPage,
    hasNextPage,
    isFetched,
    isFetching,
    isFetchingNextPage,
    refetch,
  } = useTrendData(userData?.id);

  let arrBeMood = trendData
    ? trendData.pages.flatMap((page) => page?.data)
    : [];

  if (arrBeMood.length === 0) {
    return <></>;
  } else {
    return (
      <div className="explore_trend">
        <div className="trend_title font-adversal font24">{t("my_trends")}</div>
        <div className="trend_list">
          <div className="trend_list_scroll">
            <HorizontalScrollView
              reverseScroll={true}
              onEndReached={() => {
                if (!isFetchingNextPage && hasNextPage) {
                  fetchNextPage();
                }
              }}
            >
              {arrBeMood.map((item, index) => {
                if (item) {
                  return <CardTrend key={`card_mood_${index}`} data={item} />;
                } else {
                  return <div key={`card_mood_${index}`}></div>;
                }
              })}
            </HorizontalScrollView>
          </div>
        </div>
      </div>
    );
  }
};
