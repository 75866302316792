import React from "react";

function ArrowUpperIcon({ ...props}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ? props.size : "24"}
      height={props.size ? props.size : "24"}
      fill="none"
      viewBox="0 0 32 32"
    >
      <path
        fill={props.color ? props.color : "#EA21A2"}
        d="M22.667 12.727a1.334 1.334 0 00-1.88 0L16 17.447l-4.72-4.72A1.333 1.333 0 109.4 14.62l5.653 5.653a1.336 1.336 0 001.894 0l5.72-5.653a1.334 1.334 0 000-1.893z"
      ></path>
    </svg>
  );
}

export default ArrowUpperIcon;
