import React, { useEffect, useState } from "react";

import "./index.styles.scss";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { useTranslation } from "react-i18next";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Verification } from "../Verification";
import { apiLoginWithPhone } from "shared/api/user";
import { getReferralCode } from "shared/api/storageHelper";
import { useNavigate } from "react-router-dom";
import { RootNames } from "shared/routes/rootUrl";
import { useDispatch } from "react-redux";
import appAction from "redux/app/action";
import { showToast } from "components/Common/PrimaryToast";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { apiGetUserVibeInfo } from "shared/api/topics";
import axios from "axios";

export const PhoneLogin = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [phoneNumber, setPhoneNumber] = useState<any>("");
  const [step, setStep] = useState<"phone" | "verify">("phone");
  const [isSending, setIsSending] = useState(false);

  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [countryCode, setCountryCode] = useState("US");

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (token) setCaptchaError(false);
  };

  const onCaptchaError = (err) => {
    console.error("hCaptcha Error", err);
    setCaptchaError(true);
  };

  const getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        setCountryCode(data.country_code);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getGeoInfo();
  }, []);

  const loadUserVibeInfo = async () => {
    const res = await apiGetUserVibeInfo({});

    if (res.success) {
      const userVibeInfo = res.user_highlight;
      dispatch(appAction.onUpdateVibeUserInfo(userVibeInfo));
    }
  };

  const onSendOTP = async () => {
    if (!captchaToken) {
      setCaptchaError(true);
      return;
    }

    if (phoneNumber === "" && !isValidPhoneNumber(phoneNumber)) {
      showToast("error", t("invalid_phone_number"));
      return;
    }

    if (!isSending && !!phoneNumber) {
      setShowError(false);
      setIsSending(true);

      const res = await apiLoginWithPhone({
        phone_number: phoneNumber,
        referral: getReferralCode(),
        captcha: captchaToken
      });

      if (res) {
        if (res.success) {
          setStep("verify");
          dispatch(appAction.onUpdateIsNewUser(res.newUser));
          loadUserVibeInfo();
        } else {
          setShowError(true);
          setErrorMessage(res.error);
        }
      } else {
        setShowError(true);
        setErrorMessage(
          "Your number  is not authorized, please contact support"
        );
      }

      setIsSending(false);
    }
  };

  const onAlternativeSignIn = () => {
    navigate(RootNames.login);
  };

  const onReadTerm = () => {
    window.open(process.env.REACT_APP_TERMS, "_blank");
  };

  const onReadPrivacy = () => {
    window.open(process.env.REACT_APP_PRIVACY, "_blank");
  };

  const renderLogo = () => {
    return (
      <div>
        <div className="row_align_items">
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderPhoneBody = () => {
    return (
      <div className="phone_body">
        <div
          className="font-medium"
          style={{ fontSize: 36, textAlign: "center" }}
        >
          {t("sign_with_phone_number")}
        </div>
        <div
          className="font-medium font16"
          style={{ color: "#9DA9B6", textAlign: "center" }}
        >
          {t("enter_your_phone_number_to_get_sign")}
        </div>
        <PhoneInput
          className="phone_input_container font18 font-medium"
          placeholder={t("phone_number")}
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry={countryCode as any}
        />
        <HCaptcha
          sitekey={
            process.env.REACT_APP_HCAPTCHA_SITE_KEY ??
            "10000000-ffff-ffff-ffff-000000000001"
          }
          onVerify={onCaptchaChange}
          onError={onCaptchaError}
          onExpire={() => setCaptchaToken(null)}
        />
        {captchaError && (
          <div className="error-message">Captcha verification failed</div>
        )}
        <div
          className={`next-btn clickable ${!captchaToken ? "disabled" : ""}`}
          onClick={captchaToken ? onSendOTP : () => {}}
        >
          {isSending ? (
            <LoadingCircular color="white" size={24} />
          ) : (
            <div className="row_align_items">
              <div
                className="font-bold font16"
                style={{ color: "white", marginRight: 6 }}
              >
                {t("send_sms_code")}
              </div>
            </div>
          )}
        </div>
        <div
          className="font-bold font16 clickable"
          style={{ alignSelf: "end" }}
          onClick={onAlternativeSignIn}
        >
          {t("alernative_sign_in")}
        </div>
        <div style={{ marginTop: 16 }}>{renderError()}</div>
        {renderTerm()}
      </div>
    );
  };

  const renderTerm = () => {
    return (
      <div
        className="term_txt font-regular font14"
        style={{ textAlign: "center", zoom: 1.1 }}
      >
        <div>{t("by_connect_agree")}</div>
        <div
          style={{ color: "blue" }}
          className="clickable"
          onClick={onReadTerm}
        >
          {t("terms_conditions")}
        </div>
        <div>{t("_and_")}</div>
        <div
          style={{ color: "blue" }}
          className="clickable"
          onClick={onReadPrivacy}
        >
          {t("privacy_policy")}
        </div>
      </div>
    );
  };

  const renderError = () => {
    if (showError) {
      return (
        <div className="error-message font-regular font14">{errorMessage}</div>
      );
    }
  };

  const renderVerifyStep = () => {
    return (
      <div className="register_verify">
        <Verification
          email={""}
          type={"phone_verify"}
          phoneNumber={phoneNumber}
          onBack={() => {
            setStep("phone");
          }}
        />
      </div>
    );
  };

  return (
    <div className="phone_login_password row" style={{ marginLeft: 0 }}>
      <div className="left-panel col-md-8">
        {renderLogo()}
        <div className="main-body">
          <div className="main-content">
            {step === "phone" ? renderPhoneBody() : renderVerifyStep()}
          </div>
        </div>
      </div>
      <div className="right-panel col-md-4">
        <img
          src={require("assets/image/bg_mobile_app.png")}
          className="app_image"
        />
        <div className="border-box">
          <span className="font-bold font30" style={{ color: "#EA21A2" }}>
            {t("whats_vibe")}
          </span>
          <div>
            <span className="font-regular font18" style={{ color: "#EA21A2" }}>
              {t("vibe_find_out_prev")}
            </span>
            <span
              className="font-app-title font18"
              style={{ color: "#EA21A2" }}
            >
              {t("vibe_check")}
            </span>
          </div>
          <span className="font-regular font18" style={{ color: "#EA21A2" }}>
            {t("vibe_find_out")}
          </span>
        </div>
      </div>
    </div>
  );
};
