import React, { useMemo } from "react";

import "./index.styles.scss";
import { PercentBtn } from "components/Common/PercentBtn";
import { VibeItemProps } from "shared/utils/type.def";
import DefaultAvatarIcon from "assets/svg/DefaultAvatarIcon";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import appAction from "redux/app/action";

interface Props {
  data: VibeItemProps;
  enablePreview?: boolean;
}

export const VibeCard = ({ data, enablePreview = true }: Props) => {
  const naivgate = useNavigate();
  const dispatch = useDispatch();

  const onDetail = () => {
    dispatch(appAction.toogleSearchModal(false));
    dispatch(appAction.onChangedSearch(""));
    naivgate(`/vibes/${data.creator_id}`);
  };

  const onViewFullImage = (e: any) => {
    if (
      enablePreview &&
      !!data.creator_image &&
      data.creator_image.startsWith("https")
    ) {
      e.stopPropagation();
      dispatch(appAction.onShowImageFullView(data.creator_image));
    } else {
      onDetail();
    }
  };

  const vibeScore = useMemo(() => {
    const tmpScore = data.vibes_score * 100;

    return parseInt(tmpScore.toFixed(0));
  }, [data]);

  return (
    <div className="vibe-card clickable" onClick={onDetail}>
      <div className="vibe-card-back"></div>
      <div className="vibe-card-front row_align_items clickable">
        {data.creator_image.startsWith("https") ? (
          <img
            src={data.creator_image}
            className="image"
            onClick={onViewFullImage}
          />
        ) : (
          <div className="image">
            <DefaultAvatarIcon size={64} />
          </div>
        )}
        <div className="info">
          <div className="name_container">
            <div className="name font-medium font16">{data.creator_name}</div>
          </div>
        </div>
        <PercentBtn
          percent={vibeScore}
          fontSize={22}
          vibeType={data.vibes_type}
        />
      </div>
    </div>
  );
};
