import React, { useEffect, useMemo, useRef, useState } from "react";

import "./index.styles.scss";
import { LoginSocialItem } from "./SocialItem";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { apiLoginWithSocial, apiRedditAuthUrl } from "shared/api/user";
import { useNavigate } from "react-router-dom";
import { RootNames } from "shared/routes/rootUrl";
import Cookies from "js-cookie";

import appAction from "redux/app/action";
import { useTranslation } from "react-i18next";
import { apiGetUserVibeInfo } from "shared/api/topics";
interface Props {
  onVerify: (email: string) => void;
}

export const LoginSocial = ({ onVerify }: Props) => {
  const {
    user,
    loginWithPopup,
    logout,
    getIdTokenClaims,
    isAuthenticated,
    isLoading,
  } = useAuth0();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isLogging, setIsLogging] = useState(false);
  const initialRender = useRef(true);
  
  const loadUserVibeInfo = async () => {
    const res = await apiGetUserVibeInfo({});

    if (res.success) {
      const userVibeInfo = res.user_highlight;
      dispatch(appAction.onUpdateVibeUserInfo(userVibeInfo));
    }
  };
  useEffect(() => {
    if (isAuthenticated) {
      onProcessSocialLogin();
    }
  }, [isAuthenticated]);

  const onProcessSocialLogin = async () => {
    if (!isLogging) {
      setIsLogging(true);

      const idToken = await getIdTokenClaims();

      if (idToken && idToken?.__raw) {
        const res = await apiLoginWithSocial({
          idToken: idToken.__raw,
          is_extension: true,
        });

        if (res && res.accessToken && res.userData) {
          Cookies.set("accessToken", res.accessToken);
          Cookies.set("extensionToken", res?.extensionToken);
          localStorage.setItem("userId", res.userData.id);

          dispatch(appAction.successLogin(res.userData));
          dispatch(appAction.onUpdateIsNewUser(false));
          loadUserVibeInfo();
          if (res.is_sign_up) {
            navigate(RootNames.rewardCode);
          } else {
            navigate("/");
          }
        }
      }

      setIsLogging(false);
    }
  };

  const getConnectionName = (baseType) => {
    return baseType === "youtube"
      ? "google-oauth2"
      : baseType === "tiktok"
      ? "TikTok"
      : baseType;
  };

  const onSocialLogin = async (type) => {
    if (isLoading || isLogging) return;

    try {
      if (type === "spotify") {
        loginWithPopup({
          authorizationParams: {
            connection: getConnectionName(type),
            access_type: "offline",
            approvalPrompt: "force",
            scope: "openid profile",
            connection_scope: "user-top-read user-read-recently-played",
          },
        });
      } else if (type === "youtube") {
        loginWithPopup({
          authorizationParams: {
            connection: getConnectionName(type),
            access_type: "offline",
            approvalPrompt: "force",
            scope: "openid profile",
            connection_scope:
              "https://www.googleapis.com/auth/youtube.readonly,https://www.googleapis.com/auth/yt-analytics.readonly",
          },
        });
      } else if (type === "reddit") {
        const response = await apiRedditAuthUrl();
        if (response.success) {
          window.location.href = response.url;
        } else {
          console.error("No reddit auth url available");
        }
      } else {
        loginWithPopup({
          authorizationParams: {
            connection: getConnectionName(type),
            access_type: "offline",
            approvalPrompt: "force",
            scope: "openid profile",
          },
        });
      }
    } catch {}
  };

  return (
    <div className="login-social row_align_items">
      <LoginSocialItem type="youtube" onSocialLogin={onSocialLogin} />
      <LoginSocialItem type="twitter" onSocialLogin={onSocialLogin} />
      <LoginSocialItem type="reddit" onSocialLogin={onSocialLogin} />
      <LoginSocialItem type="spotify" onSocialLogin={onSocialLogin} />
    </div>
  );
};
