import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const SettingIcon = (props: IconProps) => {
    return (
        <svg className={props.className} width={props.width ?? 21} height={props.height ?? 23} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8.38889V14.4794C1 16.7172 1 16.7172 3.11111 18.1422L8.91667 21.4989C9.79278 22.0056 11.2178 22.0056 12.0833 21.4989L17.8889 18.1422C20 16.7172 20 16.7172 20 14.49V8.38889C20 6.16167 20 6.16167 17.8889 4.73667L12.0833 1.38C11.2178 0.873333 9.79278 0.873333 8.91667 1.38L3.11111 4.73667C1 6.16167 1 6.16167 1 8.38889Z" stroke={props.color ?? "#000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M10.4999 14.6061C12.2488 14.6061 13.6666 13.1883 13.6666 11.4394C13.6666 9.69055 12.2488 8.27278 10.4999 8.27278C8.75102 8.27278 7.33325 9.69055 7.33325 11.4394C7.33325 13.1883 8.75102 14.6061 10.4999 14.6061Z" stroke={props.color ?? "#000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};