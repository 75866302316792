import React from "react";

function CopyIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 17}
      height={props.size ?? 16}
      fill="none"
      viewBox="0 0 17 16"
    >
      <path
        d="M2.27778 3.55554H0.5V16H12.9444V14.2222H2.27778V3.55554Z"
        fill="white"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.7223 1.77778H5.83338V10.6667H14.7223V1.77778ZM4.0556 0V12.4444H16.5V0H4.0556Z"
        fill="white"
      />
    </svg>
  );
}

export default CopyIcon;
