import React from "react";

import "./index.styles.scss";
import { getDeviceType } from "shared/utils/utils";
import { useTranslation } from "react-i18next";

export const MobileIntro = () => {
  const { t } = useTranslation();
  const deviceType = getDeviceType();

  const onOpenApp = () => {
    if (deviceType === "android") {
      window.location.href = process.env.REACT_APP_GOOGLE_PLAY ?? "";
    }

    if (deviceType === "ios") {
      window.location.href = process.env.REACT_APP_APP_STORE ?? "";
    }
  };

  const renderLogo = () => {
    return (
      <div className="app_info">
        <img src={process.env.REACT_APP_LOGO ?? ""} className="app_image" />
        <div className="font-app-title font28">
          {process.env.REACT_APP_APP_NAME}
        </div>
      </div>
    );
  };

  const renderDescription = () => {
    return (
      <div className="description font-regular font18">
        {t("to_sign_up")}
        <br />
        {t("the_web_dashboard")}
        <br />
        <div className="web_link font-bold font18">
          {process.env.REACT_APP_DASHBOARD_URL}
        </div>
        {t("with_your_laptop")}
      </div>
    );
  };

  const renderGoToMobile = () => {
    return (
      <div className="go_to_mobile_btn clickable" onClick={onOpenApp}>
        <div className="row_align_items">
          <div className="btn-txt font-regular font16">
            {t("go_to_mobile_app")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="mobile_intro">
      {renderLogo()}
      {renderDescription()}
      {renderGoToMobile()}
    </div>
  );
};
