import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { apiGetUserInfo } from "shared/api/user";
import appAction from "redux/app/action";

function decodeJWT(token) {
  // Split the token into its components
  const parts = token.split(".");

  // Check if token format is correct
  if (parts.length !== 3) {
    throw new Error("Token format is invalid.");
  }

  // Decode the payload
  const payload = parts[1];
  const decodedPayload = JSON.parse(atob(payload));

  return decodedPayload;
}

function TokenHandler() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchAndProcessToken = async () => {
      const queryParams = new URLSearchParams(location.search);
      let token = queryParams.get("token");

      const decoded = decodeJWT(token);

      Cookies.set("accessToken", token, { path: "/" });
      try {
        const res = await apiGetUserInfo(decoded.id);
        localStorage.setItem("userId", res.id);
        dispatch(appAction.successLogin(res));
      } catch (error) {
        console.error("Error fetching user info:", error);
      }

      navigate("/");
    };
    fetchAndProcessToken();
  }, [navigate, location.search]);

  return null;
}

export default TokenHandler;
