import React, { useEffect, useState } from "react";

import "./SideMenu.styles.scss";
import MenuButton from "./MenuButton";
import Cookies from "js-cookie";
import { CategoryIcon } from "assets/svg/CategoryIcon";
import { CalendarIcon } from "assets/svg/CalendarIcon";
import { UserIcon } from "assets/svg/UserIcon";
import { FavoriteIcon } from "assets/svg/FavoriteIcon";
import { SettingIcon } from "assets/svg/SettingIcon";
import { LogoutIcon } from "assets/svg/LogoutIcon";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import appAction from "redux/app/action";
import { useAuth0 } from "@auth0/auth0-react";
import { RootNames } from "shared/routes/rootUrl";
import { PhoneIcon } from "assets/svg/PhoneIcon";
import { useTranslation } from "react-i18next";

const SideMenu = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { logout } = useAuth0();

  const [menuIndex, setMenuIndex] = useState(0);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith("/profile")) {
      setMenuIndex(1);
    } else if (location.pathname.startsWith("/setting")) {
      setMenuIndex(2);
    } else {
      setMenuIndex(0);
    }
  }, [location]);

  const onLogoutPressed = () => {
    Cookies.set("accessToken", null);
    localStorage.removeItem("userId");

    dispatch(appAction.successLogout());
    logout();
  };

  const onViewProfilePressed = () => {
    if (menuIndex === 1) {
      return;
    }
    navigate(RootNames.profile);
  };

  const onViewSettingPressed = () => {
    if (menuIndex === 2) {
      return;
    }
    navigate(RootNames.settings);
  };

  const onViewConnectMobile = () => {
    if (menuIndex === 0) {
      return;
    }

    dispatch(appAction.toogleConfirmDownloadAppModal(true));
  };

  const renderInfo = () => {
    return (
      <div className="check_info clickable" onClick={onViewConnectMobile}>
        <div className="check_info_title font-bold">
          {t("check_out_mobile_app", { name: process.env.REACT_APP_APP_NAME })}
        </div>
        <div
          className="check_info_list font-regular"
          dangerouslySetInnerHTML={{ __html: t("check_out_mobile_app_info") }}
        ></div>
      </div>
    );
  };

  return (
    <div className="menu-container sticky-menu">
      <MenuButton
        active={true}
        isMainActive={true}
        icon={<PhoneIcon width={36} height={36} />}
        activeColor="white"
        title={t("connect_mobile_app")}
        onClick={onViewConnectMobile}
      />
      <MenuButton
        active={menuIndex === 1}
        icon={<UserIcon />}
        title={t("profile")}
        onClick={onViewProfilePressed}
      />
      <MenuButton
        active={menuIndex === 2}
        icon={<SettingIcon />}
        title={t("settings")}
        onClick={onViewSettingPressed}
      />
      <div className="menu-footer">
        {renderInfo()}
        <MenuButton
          active={false}
          icon={<LogoutIcon color="#F32D68" />}
          title={t("log_out")}
          color="pink"
          syncColor={true}
          onClick={onLogoutPressed}
        />
      </div>
    </div>
  );
};

export default SideMenu;
