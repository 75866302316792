import React, { useState } from "react";

import "./index.styles.scss";
import { LogoIcon } from "assets/svg/LogoIcon";
import { LoginEmail } from "./Email";
import { LoginSocial } from "./Social";
import { useTranslation } from "react-i18next";
import { Verification } from "../Verification";

export const Login = () => {
  const { t } = useTranslation();

  const [step, setStep] = useState<"login" | "verify">("login");
  const [currentEmail, setCurrentEmail] = useState("");

  const onReadTerm = () => {
    window.open(process.env.REACT_APP_TERMS, "_blank");
  };

  const onReadPrivacy = () => {
    window.open(process.env.REACT_APP_PRIVACY, "_blank");
  };

  const renderLogo = () => {
    return (
      <div>
        <div className="row_align_items">
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderLoginMainBody = () => {
    return (
      <div className="main-body">
        {renderEmailLogin()}
        {renderSocial()}
        {renderTerm()}
      </div>
    );
  };

  const renderVerifyMainBody = () => {
    return (
      <div className="main-body">
        <div className="verify_content">
          <div className="register_verify">
            <Verification
              email={currentEmail}
              type="signup"
              onBack={() => {
                setStep("login");
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderEmailLogin = () => {
    return (
      <LoginEmail
        onVerify={(email) => {
          setCurrentEmail(email);
          setStep("verify");
        }}
      />
    );
  };

  const renderSocial = () => {
    return (
      <LoginSocial
        onVerify={(email) => {
          setCurrentEmail(email);
          setStep("verify");
        }}
      />
    );
  };

  const renderTerm = () => {
    return (
      <div
        className="term_txt font-regular font14"
        style={{ textAlign: "center" }}
      >
        <div>{t("by_connect_agree")}</div>
        <div
          style={{ color: "blue" }}
          className="clickable"
          onClick={onReadTerm}
        >
          {t("terms_conditions")}
        </div>
        <div>{t("_and_")}</div>
        <div
          style={{ color: "blue" }}
          className="clickable"
          onClick={onReadPrivacy}
        >
          {t("privacy_policy")}
        </div>
      </div>
    );
  };

  return (
    <div className="login-container row" style={{ marginLeft: 0 }}>
      <div className="left-panel col-md-8">
        {renderLogo()}
        {step === "login" ? renderLoginMainBody() : renderVerifyMainBody()}
      </div>
      <div className="right-panel col-md-4">
        <img
          src={require("assets/image/bg_mobile_app.png")}
          className="app_image"
        />
        <div className="border-box">
          <span className="font-bold font30" style={{color: '#EA21A2'}}>{t("whats_vibe")}</span> 
          <div>
            <span className="font-regular font18" style={{color: '#EA21A2'}}>{t("vibe_find_out_prev")}</span>
            <span className="font-app-title font18" style={{color: '#EA21A2'}}>{t("vibe_check")}</span>
          </div>
          <span className="font-regular font18" style={{color: '#EA21A2'}}>{t("vibe_find_out")}</span> 
        </div>
      </div>
    </div>
  );
};
