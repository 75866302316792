import React, { useState } from "react";
import appAction from "redux/app/action";
import { Scrollbars } from "react-custom-scrollbars";

import "./index.styles.scss";
import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import { useDispatch } from "react-redux";
import { useInvitation } from "shared/hook/fetchData";
import { InvitationItemProps } from "shared/utils/type.def";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { apiInviteByEmail } from "shared/api/util";
import { showToast } from "components/Common/PrimaryToast";
import { PercentBtn } from "components/Common/PercentBtn";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const RewardHistoryModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    data: arrBeData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isInitialLoading,
    isFetched,
    refetch,
  } = useInvitation(dispatch);

  const onHide = () => {
    dispatch(appAction.toogleRewardHistoryModal(false));
  };

  const onSend = () => {
    onHide();
    dispatch(appAction.toogleInvitationModal(true));
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      hasNextPage && !isFetchingNextPage && fetchNextPage();
    }
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items row_space_between">
        <div className="font-bold font18">{t("invitation_history")}</div>
        <div className="clickable" onClick={onHide}>
          <RedCloseIcon />
        </div>
      </div>
    );
  };

  let arrInvitations = arrBeData
    ? arrBeData.pages.flatMap((page) => page?.data)
    : [];

  const renderList = () => {
    return (
      <div className="history_list">
        {isInitialLoading ? (
          <div className="center">
            <RectangleLoadingAnimation />
          </div>
        ) : (
          <Scrollbars
            onScroll={handleScroll}
            renderThumbVertical={(props) => (
              <div {...props} className="thumb-vertical" />
            )}
            style={{ paddingBottom: 100 }}
          >
            {arrInvitations.map((item, index) => {
              return <HistoryItem data={item} onClose={onHide} />;
            })}
          </Scrollbars>
        )}
        {isFetchingNextPage && <LoadingCircular size={16} color="main" />}
      </div>
    );
  };

  const renderEmpty = () => {
    return (
      <div
        className="history_list center"
        style={{ gap: 16, flexDirection: "column" }}
      >
        <img
          src={require("assets/image/bg_empty.png")}
          style={{ width: 230, height: 230 }}
        />
        <div className="font-bold font24">{t("no_invitations_sent")}</div>
        <div className="font-regular font16" style={{ opacity: 0.5 }}>
          {t("invite_your_firend_to_join")}
        </div>
        <div className="btn_send_invitation clickable" onClick={onSend}>
          <div className="txt_btn font-regular font16">{t("send")}</div>
        </div>
      </div>
    );
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_reward_history">
        <div
          className="modal_body"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {renderHeader()}
          {isFetched && arrInvitations.length === 0
            ? renderEmpty()
            : renderList()}
        </div>
      </div>
    </OpacityModal>
  );
};

interface HistoryItemProps {
  data: InvitationItemProps;
  onClose: () => void;
}

const HistoryItem = ({ data, onClose }: HistoryItemProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const onClick = () => {
    if (data.is_joined === 1) {
      onClose();
      navigate(`/vibes/${data.creator_id}`);
    }
  };

  const onSend = async () => {
    if (!isSending) {
      setIsSending(true);

      const res = await apiInviteByEmail({ email: data.email });

      if (res.success) {
        setIsSent(true);
        showToast("success", t("msg_invitation_sent_again"));
      } else {
        showToast("error", res.error);
      }

      setIsSending(false);
    }
  };

  const renderTryAgainBtn = () => {
    return (
      <div className="btn_try_again clickable" onClick={onSend}>
        {isSending ? (
          <LoadingCircular size={16} color="main" />
        ) : (
          <div className="text_no_wrap txt_btn font-regular font16">
            {t("try_again")}
          </div>
        )}
      </div>
    );
  };

  const renderAcceptedBtn = () => {
    return (
      <div className="btn_accepted_btn">
        <div className="text_no_wrap txt_btn font-regular font16">
          {t("accepted")}
        </div>
      </div>
    );
  };

  const renderSentBtn = () => {
    return (
      <div className="btn_sent_btn">
        <div className="text_no_wrap txt_btn font-regular font16">
          {t("sent!")}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`history_item row_space_between ${
        data.is_joined === 1 ? "clickable" : ""
      }`}
      onClick={onClick}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {data.is_joined === 1 && (
          <div className="font-bold font14">{data.creator_name}</div>
        )}
        <div className="font-regular font14">
          {data.email ?? data.creator_name}
        </div>
      </div>
      <div className="row_align_items" style={{ gap: 8 }}>
        {data.is_joined === 1 && (
          <PercentBtn
            percent={Math.floor(data.vibes_score * 100)}
            vibeType={data.vibes_type}
            buttonSize={40}
            fontSize={10}
            borderWidth={2}
            subFontSize={8}
          />
        )}
        {data.is_joined === 0
          ? !isSent
            ? renderTryAgainBtn()
            : renderSentBtn()
          : renderAcceptedBtn()}
      </div>
    </div>
  );
};
