import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import appAction from "redux/app/action";

import "./index.styles.scss";
import UpgradeLevelIcon from "assets/svg/UpgradeLevelIcon";
import { VibeCard } from "./VibeCard";
import { useUserMatch } from "shared/hook/fetchData";
import { useDispatch, useSelector } from "react-redux";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { useTranslation } from "react-i18next";
import { useDebounce } from "use-debounce";
import { SearchIcon } from "assets/svg/SearchIcon";
import { RootState } from "redux/interfaces";

export const ExploreVibe = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { vibeUserScrollPos } = useSelector((state: RootState) => state.app);
  const scrollRef = useRef<any>(null);

  const [searchValue, setSearchValue] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const {
    data: arrBeData,
    fetchNextPage: fetchNextPageBeTopic,
    hasNextPage: hasNextPageBeTopic,
    isFetchingNextPage: isFetchingTopic,
    isFetched: isFetchedBeTopic,
    refetch: refetchBeTopics,
  } = useUserMatch(dispatch, debouncedSearchValue);

  useEffect(() => {
    if (scrollRef && scrollRef.current && vibeUserScrollPos) {
      scrollRef.current.scrollTop(vibeUserScrollPos);
    }
  }, [isFetchedBeTopic]);

  const onUpgradeLevel = () => {
    dispatch(appAction.toogleRewardModal(true));
  };

  const onInvite = () => {
    dispatch(appAction.toogleInvitationModal(true));
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handBlur = () => {
    setIsFocused(false);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      hasNextPageBeTopic && !isFetchingTopic && fetchNextPageBeTopic();
    }

    dispatch(appAction.onUpdateScrollPosVibes(e.target.scrollTop));
  };

  let arrBeTopics = arrBeData
    ? arrBeData.pages.flatMap((page) => page?.data)
    : [];

  const renderVibeList = () => {
    return (
      <div className="vibe-list">
        <div
          className="row_align_items row_space_between"
          style={{ paddingRight: 16 }}
        >
          <div className="font-adversal font24">{t("vibes")}</div>
          <div className="item_btn clickable" onClick={onInvite}>
            <div className="item_btn_txt font-regular font16">
              {t("invite")}
            </div>
          </div>
        </div>

        {renderSearch()}
        <Scrollbars
          ref={scrollRef}
          onScroll={handleScroll}
          className="vibe-list-body"
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
        >
          {arrBeTopics.map((item, index) => {
            if (item)
              return <VibeCard key={`vibe_card_${index}`} data={item} />;
          })}
        </Scrollbars>
        <div className="row_align_items align-center">
          {isFetchingTopic && <LoadingCircular color="main" size={16} />}
        </div>
      </div>
    );
  };

  const renderSearch = () => {
    return (
      <div className="search-vibe-container">
        <input
          value={searchValue}
          type="text"
          placeholder={isFocused ? "" : t("p_search_input")}
          className="search-input font-regular font14"
          onFocus={handleFocus}
          onBlur={handBlur}
          onChange={(e) => setSearchValue(e.target.value)}
        />
        <SearchIcon className="search-icon" />
      </div>
    );
  };

  const renderUpgradeLevel = () => {
    return (
      <div className="upgrade-level clickable" onClick={onUpgradeLevel}>
        <div className="btn-upgrade-level row_align_items clickable">
          <div>
            <UpgradeLevelIcon />
          </div>
          <div className="font-bold font16">{t("level_up")}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="explore-vibe">
      {renderVibeList()}
      {renderUpgradeLevel()}
    </div>
  );
};
