import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const SearchIcon = (props: IconProps) => {
    return (
        <svg className={props.className} width={props.width ?? 20} height={props.height ?? 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M14.3198 12.9057C15.3729 11.551 16 9.84873 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C9.8487 16 11.5509 15.3729 12.9056 14.3199L18.2929 19.7072L19.7071 18.293L14.3198 12.9057ZM14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z" fill={props.color ?? '#000'} />
        </svg>
    );
};