import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const BgPinkBgBtn = (props: IconProps) => {
  return (
    <svg
      style={{ width: "100%" }}
      viewBox="0 0 151 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0H143C147.418 0 151 3.58172 151 8V19C151 32.2548 140.255 43 127 43H16C7.16344 43 0 35.8366 0 27V16Z"
        fill="#EA21A2"
      />
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0H143C147.418 0 151 3.58172 151 8V19C151 32.2548 140.255 43 127 43H16C7.16344 43 0 35.8366 0 27V16Z"
        fill="url(#paint0_radial_2547_26889)"
        fillOpacity="0.7"
      />
      <path
        d="M0 16C0 7.16344 7.16344 0 16 0H143C147.418 0 151 3.58172 151 8V19C151 32.2548 140.255 43 127 43H16C7.16344 43 0 35.8366 0 27V16Z"
        fill="url(#paint1_radial_2547_26889)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2547_26889"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.8472 8.95833) rotate(19.4029) scale(84.4932 54.1512)"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.697917" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2547_26889"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(130.315 37.625) rotate(-102.695) scale(37.6496 140.816)"
        >
          <stop stopOpacity="0.23" />
          <stop offset="0.861815" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
