import React from "react";

import "./index.styles.scss";
import { Grid } from "@mui/material";
import { PercentBtn } from "components/Common/PercentBtn";
import { TopicNormalItemProps } from "shared/utils/type.def";
import { useTranslation } from "react-i18next";

interface Props {
  data: TopicNormalItemProps;
}

export const CardNotEnoughTopic = ({ data }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={12} lg={12} xl={6}>
      <div className="card_not_enough_topic_container">
        <div className="explore_card_topic">
          <img
            src={require("assets/image/bg_no_enough_interest.png")}
            className="bg_image"
          />
          <div className="card_content">
            <div className="font-medium font22">{t("vibe_match_not_high")}</div>
          </div>
          <div className="percent_view">
            <PercentBtn
              buttonSize={70}
              percent={Math.floor(data.threshold * 100)}
              vibeType={data.threshold_type}
            />
          </div>
        </div>
      </div>
    </Grid>
  );
};
