import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";

import "./index.styles.scss";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { apiGetTopicInterestDetail } from "shared/api/topics";
import BackIcon from "assets/svg/BackIcon";
import { useInterestTopUser, useMicroInterest } from "shared/hook/fetchData";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { InterestDetailImageView } from "../InterestDetail/ImageView";
import { InterestDetailPeople } from "../InterestDetail/InterestedPeople";

export const SubInterestDetail = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const vibeId = params.id;
  const [interestName, setInterestName] = useState("");
  const [interestDetail, setInterestDetail] = useState(null);

  useEffect(() => {
    loadDetail();
  }, [vibeId]);

  const loadDetail = async () => {
    const res = await apiGetTopicInterestDetail({ vibe: vibeId });

    if (res.success) {
      setInterestName(res.data.topic_name);
      setInterestDetail(res.data);
    }
  };

  const {
    data: users,
    fetchNextPage: fetchNextPageUser,
    hasNextPage: hasNextPageUser,
    isFetched,
    isFetching: isFetchingUser,
    isFetchingNextPage,
    refetch,
  } = useInterestTopUser(vibeId, 20);

  let arrUser = users ? users.pages.flatMap((page) => page?.data) : [];

  const onBack = () => {
    navigate(-1);
  };

  const handleLeftScroll = (e) => {
    // const bottom =
    //   e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10 &&
    //   e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight >= 0;
    // if (bottom) {
    //   hasNextPageBeTopic && !isFetchingTopic && fetchNextPageBeTopic();
    // }
  };

  const handleRightScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10 &&
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight >= 0;

    if (bottom) {
      hasNextPageUser && !isFetchingUser && fetchNextPageUser();
    }
  };

  const renderHeader = () => {
    return (
      <div className="detail_header row_align_items">
        <div className="clickable" onClick={onBack}>
          <BackIcon />
        </div>
        <div className="font-medium font32">{interestName}</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="detail_body">
        <div className="left_panel">
          <Scrollbars
            onScroll={handleLeftScroll}
            renderThumbVertical={(props) => (
              <div {...props} className="thumb-vertical" />
            )}
            style={{ paddingBottom: 100 }}
          >
            <div className="left_panel_content">
              {interestDetail && (
                <InterestDetailImageView
                  data={interestDetail}
                  onRefresh={() => {}}
                />
              )}
            </div>
          </Scrollbars>
        </div>
        <div className="right_panel">
          <Scrollbars
            onScroll={handleRightScroll}
            renderThumbVertical={(props) => (
              <div {...props} className="thumb-vertical" />
            )}
            style={{ paddingBottom: 100 }}
          >
            <div className="right_panel_content">
              {arrUser.length > 0 && <InterestDetailPeople arrUser={arrUser} />}
            </div>
          </Scrollbars>
        </div>
      </div>
    );
  };

  if (interestDetail) {
    return (
      <div className="subinterest_detail">
        {renderHeader()}
        {renderBody()}
      </div>
    );
  } else {
    return (
      <div
        className="subinterest_detail"
        style={{ alignItems: "center", justifyContent: "center" }}
      >
        <RectangleLoadingAnimation />
      </div>
    );
  }
};
