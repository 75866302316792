import React from "react";

import "./index.styles.scss";
import DefaultAvatarIcon from "assets/svg/DefaultAvatarIcon";
import { useDispatch } from "react-redux";
import appAction from "redux/app/action";

interface Props {
  size: number;
  url?: string;
  enableFullImageView?: boolean;
}

export const PinkRoundAvatar = ({
  size,
  url,
  enableFullImageView = true,
}: Props) => {
  const dispatch = useDispatch();

  const onViewFullImage = () => {
    if (!!url && enableFullImageView) {
      dispatch(appAction.onShowImageFullView(url));
    }
  };

  return (
    <div
      className="pink-round-avatar"
      style={{ width: size, height: size, borderRadius: size }}
    >
      {url?.startsWith("https") ? (
        <img
          src={url}
          className="user-image clickable"
          style={{ width: size - 6, height: size - 6, borderRadius: size - 6 }}
          onClick={onViewFullImage}
        />
      ) : (
        <DefaultAvatarIcon size={size - 6} />
      )}
    </div>
  );
};
