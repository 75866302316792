import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const EmailIcon = (props: IconProps) => {
  return (
    <svg
      style={props.style}
      width={props.width ?? 24}
      height={props.height ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20 6H4V18H20V6ZM2 4V20H22V4H2Z"
        fill="black"
      />
      <path
        d="M18 7.52258L12 10.8559L6 7.5226V9.81052L12 13.1438L18 9.8105V7.52258Z"
        fill="black"
      />
    </svg>
  );
};
