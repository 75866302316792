import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const RewardEmptyIcon = (props: IconProps) => {
  return (
    <svg
      width={props.width ?? 60}
      height={props.height ?? 60}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="60" height="60" rx="30" fill="url(#paint0_linear_137_358)" />
      <rect
        opacity="0.3"
        x="20"
        y="20"
        width="20"
        height="20"
        rx="2"
        fill="white"
      />
      <path
        d="M39 28L39 30H41L41 28H43V26H41L41 24H39L39 26H37V28H39Z"
        fill="white"
      />
      <path
        opacity="0.3"
        d="M30.5 32.7778C28.9045 32.7778 27.6111 31.4844 27.6111 29.8889C27.6111 28.2934 28.9045 27 30.5 27C32.0955 27 33.3889 28.2934 33.3889 29.8889C33.3889 31.4844 32.0955 32.7778 30.5 32.7778Z"
        fill="white"
      />
      <path
        d="M24.0004 39.4217C24.2804 35.9747 27.078 34.2222 30.4879 34.2222C33.9458 34.2222 36.7868 35.8784 36.9985 39.4222C37.0069 39.5634 37 40 37 40C34.3238 40 29.2774 40 24 40C24 40 23.9852 39.6094 24.0004 39.4217Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_137_358"
          x1="11.8966"
          y1="5.68966"
          x2="35.6897"
          y2="64.1379"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#92ECC8" />
          <stop offset="1" stop-color="#53AEE1" />
        </linearGradient>
      </defs>
    </svg>
  );
};
