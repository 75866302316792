import React, { useState } from "react";
import "./OpacityModal.styles.scss";
import { OpacityModalProps } from "shared/utils/type.def";

const OpacityModal = (props: OpacityModalProps) => {
    return (
        <div className="opacity-modal" onClick={props.onClick}>
            <div className="opacity-modal-content">
                {props.children}
            </div>
        </div>
    );
};

export default OpacityModal;