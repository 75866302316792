import React, { useEffect, useMemo, useRef, useState } from "react";

import "./index.styles.scss";
import { getArrFromString } from "shared/utils/utils";
import { InterestDetailBg } from "assets/svg/InterestDetailBg";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/interfaces";
import PictureIcon from "assets/svg/PictureIcon";
import useIPFS from "shared/utils-IPFS/useIPFS";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { NormalPlusIcon } from "assets/svg/NormalPlusIcon";
import { useTranslation } from "react-i18next";
import { getBase64String } from "shared/utils-IPFS/functions";
import { apiEditTopicInterest } from "shared/api/topics";
import { showToast } from "components/Common/PrimaryToast";
import appAction from "redux/app/action";

interface Props {
  data: any;
  onRefresh: () => void;
}

export const InterestDetailImageView = ({ data, onRefresh }: Props) => {
  const { t } = useTranslation();
  const { userId } = useSelector((state: RootState) => state.app);
  const dispatch = useDispatch();

  const hiddenFileInput = useRef<any>(null);
  const { uploadBase64 } = useIPFS();
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [displayImageUrl, setDisplayImageUrl] = useState<any>(null);

  const imageUrl = useMemo(() => {
    if (data && (data.images || data.topic_image)) {
      if (!!data.images) {
        return getArrFromString(data.images, ",")[0];
      } else {
        return getArrFromString(data.topic_image, ",")[0];
      }
    } else {
      return null;
    }
  }, [data]);

  useEffect(() => {
    setDisplayImageUrl(imageUrl);
  }, [imageUrl]);

  const isOwner = useMemo(() => {
    return data && userId === data.creator_id;
  }, [data, userId]);

  const handleClick = (event) => {
    event.stopPropagation();
    if (!isUploadingFile) hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, "");

      if (url !== undefined && url !== "" && url !== null) {
        const res = await apiEditTopicInterest({
          vibe: data.id,
          images: url,
        });

        if (res.success) {
          showToast("success", t("msg_interest_updated"));
          setDisplayImageUrl(url);
        }
      }

      setIsUploadingFile(false);
    }
  };

  const onViewFullImage = () => {
    !!imageUrl && dispatch(appAction.onShowImageFullView(imageUrl));
  };

  const renderSubSection = () => {
    return (
      <div className="sub_action">
        {isOwner ? renderAddBtn() : <PictureIcon />}
      </div>
    );
  };

  const renderAddBtn = () => {
    return isUploadingFile ? (
      <div className="add_image_btn clickable">
        <LoadingCircular size={16} color="main" />{" "}
      </div>
    ) : (
      <div
        className="add_image_btn clickable"
        onClick={handleClick}
        data-tooltip-id={`topic_edit_${data.topic}`}
        data-tooltip-content={t("edit_image")}
      >
        <div className="text_no_wrap font-regular font18">{t("add_photo")}</div>
        <div className="clickable" style={{ marginTop: 4, marginLeft: 8 }}>
          <NormalPlusIcon color="black" />
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept="image/png, image/jpeg"
        />
      </div>
    );
  };

  return (
    <div className="interest_image_view">
      <div
        className="clickable"
        style={{ display: "flex" }}
        onClick={onViewFullImage}
      >
        <InterestDetailBg imageUrl={displayImageUrl} />
      </div>
      {!displayImageUrl && renderSubSection()}
    </div>
  );
};
