import React, { useEffect, useMemo, useState } from "react";

import appAction from "redux/app/action";
import "./index.styles.scss";
import TwitterIcon from "assets/svg/socials/TwitterIcon";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import YoutubIcon from "assets/svg/socials/YoutubeIcon";
import RedditIcon from "assets/svg/socials/RedditIcon";
import SpotifyIcon from "assets/svg/socials/SpotifyIcon";
import ArrowRightIcon from "assets/svg/ArrowRightIcon";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { RootState } from "redux/interfaces";
import {
  apiDeletionSocialHandle,
  apiEditSocialHandle,
  apiRedditConnectAuthUrl,
} from "shared/api/user";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";
import InstagramIcon from "assets/svg/socials/InstagramIcon";
import { useSocket } from 'shared/contexts/socketContext';

interface Props {
  type: "youtube" | "twitter" | "reddit" | "spotify" | "instagram";
  socialInfo: any;
}

export const SocialItem = ({ type, socialInfo }: Props) => {
  const { t } = useTranslation();
  const { user, loginWithPopup, logout, isLoading } = useAuth0();
  const dispatch = useDispatch();

  const { userData, userId } = useSelector((state: RootState) => state.app);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [isTryingConnect, setIsTryingConnect] = useState(false);
  const socket = useSocket();

  useEffect(() => {
    if (
      user &&
      user.sub?.toLowerCase().includes(getConnectionName(type).toLowerCase()) &&
      !socialInfo &&
      !isDeleting &&
      isTryingConnect
    ) {
      onSaveSocial();
    }
  }, [user]);

  useEffect(() => {
    if(socket && userId)
    {
      socket.emit("join-room", `${userId}`);
      const telegramHandler = () => {
        console.log("---telegram activated---");
        showToast("success", t("msg_success_account_connection"));
        dispatch(appAction.loadUserInfo(userData!.id));
      };
      const whatsappHandler = () => {
        console.log("---whatsapp activated---");
        showToast("success", t("msg_success_account_connection"));
        dispatch(appAction.loadUserInfo(userData!.id));
      };

      socket.on("whatsapp-activated", whatsappHandler);
      socket.on("tg-activated", telegramHandler);

      return () => {
        socket.off('whatsapp-activated');
        socket.off('tg-activated');
      };
    }
  }, [socket, userId]);

  const onConnectSocial = async (
    type:
      | "twitter"
      | "reddit"
      | "youtube"
      | "google-oauth2"
      | "spotify"
      | "instagram"
  ) => {
    try {
      setIsTryingConnect(true);

      if (type === "spotify") {
        loginWithPopup({
          authorizationParams: {
            connection: getConnectionName(type),
            access_type: "offline",
            approvalPrompt: "force",
            scope: "openid profile",
            connection_scope: "user-top-read user-read-recently-played",
          },
        });
      } else if (type === "youtube") {
        loginWithPopup({
          authorizationParams: {
            connection: getConnectionName(type),
            access_type: "offline",
            approvalPrompt: "force",
            scope: "openid profile",
            connection_scope:
              "https://www.googleapis.com/auth/youtube.readonly,https://www.googleapis.com/auth/yt-analytics.readonly",
          },
        });
      } else if (type === "reddit") {
        const response = await apiRedditConnectAuthUrl();
        if (response.success) {
          window.location.href = response.url;
          localStorage.setItem("socialConnect", 'reddit');
        } else {
          console.error("No reddit auth url available");
        }
      } else {
        if(type === "twitter"){
          localStorage.setItem("socialConnect", 'twitter');
        }
        loginWithPopup({
          authorizationParams: {
            connection: getConnectionName(type),
            access_type: "offline",
            approvalPrompt: "force",
            scope: "openid profile",
          },
        });
      }
    } catch {}
  };


  const onSaveSocial = async () => {
    setIsConnecting(true);

    var params = {
      social_id: user?.sub,
      type: type === "youtube" ? "google-oauth2" : type,
      handle: user?.name,
    };
    const res = await apiEditSocialHandle(params);

    if (res.success && userData) {
      dispatch(appAction.loadUserInfo(userId!));
      showToast("success", t("msg_success_account_connection"));
    } else {
      showToast("error", t("msg_account_connected_with_another_user"));
    }

    setIsTryingConnect(false);
    setIsConnecting(false);
  };

  const onDelete = async () => {
    if (!isDeleting) {
      setIsDeleting(true);

      const res = await apiDeletionSocialHandle({ id: socialInfo.id });

      if (res.success && userData) {
        dispatch(appAction.loadUserInfo(userId!));
        showToast("success", t("msg_success_account_disconnect"));
        logout();
      }

      setIsDeleting(false);
    }
  };

  const getConnectionName = (baseType) => {
    return baseType === "youtube"
      ? "google-oauth2"
      : baseType === "tiktok"
      ? "TikTok"
      : baseType;
  };

  const isConnected = useMemo(() => {
    if (socialInfo) {
      return true;
    } else {
      return false;
    }
  }, [socialInfo]);

  const renderIcon = () => {
    if (type === "youtube") {
      return <YoutubIcon size={32} />;
    } else if (type === "twitter") {
      return <TwitterIcon size={20} />;
    } else if (type === "reddit") {
      return <RedditIcon size={28} />;
    } else if (type === "spotify") {
      return <SpotifyIcon size={40} />;
    } else if (type === "instagram") {
      return <InstagramIcon size={24} color="black" />;
    }
  };

  const renderConnectName = () => {
    if (type === "youtube") {
      return t("connect_youtube");
    } else if (type === "twitter") {
      return t("connect_twitter");
    } else if (type === "reddit") {
      return t("connect_reddit");
    } else if (type === "spotify") {
      return t("connect_spotify");
    } else if (type === "instagram") {
      return t("connect_instagram");
    }
  };

  const renderName = () => {
    if (!isConnected) {
      return <div>{renderConnectName()}</div>;
    } else {
      return <div>{socialInfo?.handle}</div>;
    }
  };

  const renderAction = () => {
    if (isConnecting || isDeleting) {
      return (
        <div>
          <LoadingCircular size={16} color="main" />
        </div>
      );
    }

    if (isConnected) {
      return (
        <div className="clickable" onClick={() => onDelete()}>
          <RedCloseIcon />
        </div>
      );
    } else {
      return (
        <div className="clickable" onClick={() => onConnectSocial(type)}>
          <ArrowRightIcon />
        </div>
      );
    }
  };

  return (
    <div
      className={`social-item ${!isConnected && "clickable"}`}
      onClick={() => {
        !isConnected && onConnectSocial(type);
      }}
    >
      <div className="row_align_items">
        <div className="social-icon">{renderIcon()}</div>
        <div className="social-name font-regular font14">{renderName()}</div>
      </div>
      <div className="social-action-icon clickable">{renderAction()}</div>
    </div>
  );
};
