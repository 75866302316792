import HttpClient from "./apiClient";
import { getCurrentLngFullName } from "./storageHelper";

export async function apiGetTopicUserFeed(params: {
  userId?: number;
  limit: number;
  offset?: number;
  // sortBy?: string;
  // query?: string;
  type?: any;
}) {
  try {
    const language = await getCurrentLngFullName();

    const response: any = await HttpClient.getWithToken("/interests/feed", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetTopicUserMatch(params: {
  limit: number;
  offset?: number;
  query?: string;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken("/topics/userMatches", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiCreateHighLight(params: {
  topic?: number;
  text: string;
  images?: string;
  item_id?: any;
  item_type?: any;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.postWithToken("/topics/highlight", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiUnHideInterest(params: { vibe: number }) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.postWithToken("/topics/unhide", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiHideInterest(params: { vibe: number }) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.postWithToken("/topics/hide", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetTopicWithVibe(params: {
  toUser: any;
  isPhysical?: boolean;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken("/topics/vibeWith", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetUserVibeInfo(params: {}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken("/topics/lastMood", {
      ...params,
      language,
    });

    return response.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetTopicHighlights(params: {
  item_id: number;
  item_type: string;
  limit: number;
  offset?: number;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken("/topics/highlights", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetTopicCommon(params: {
  toUser?: number;
  level?: number;
  limit: number;
  offset?: number;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken("/topics/common", {
      ...params,
      language,
    });

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiEditTopicInterest(params: {
  vibe: number;
  threshold?: number;
  description?: string;
  images?: string;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.postWithToken(
      "/topics/editInterest",
      { ...params, language }
    );

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiEditMoodImage(params: { mood: any; images?: string }) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.postWithToken(
      "/topics/editMoodImage",
      { ...params, language }
    );

    return response?.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetTopicTrend(params: {
  limit: number;
  offset?: number;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken("/topics/trends", {
      ...params,
      language,
    });

    return response.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetSubTopics(params: {
  vibe: number;
  filter?: "new" | "hidden" | "public";
  userId?: any;
  sortBy?: string;
  limit: number;
  offset?: number;
}) {
  try {
    const response: any = await HttpClient.getWithToken(
      "/topics/subTopics",
      params
    );

    return response.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetTopicInterestDetail(params: { vibe: any }) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken(
      "/topics/interestDetail",
      { ...params, language }
    );

    return response.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetTopicUserTrend(params: {
  userId?: number;
  limit: number;
  offset?: number;
}) {
  try {
    const language = await getCurrentLngFullName();
    const response: any = await HttpClient.getWithToken("/topics/userTrends", {
      ...params,
      language,
    });
    return response.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}

export async function apiGetTopicUsers(params: {
  vibe: number;
  limit: number;
  offset?: number;
}) {
  try {
    const response: any = await HttpClient.getWithToken(
      "/topics/users",
      params
    );

    return response.data;
  } catch (e: any) {
    console.log(e);
    throw new Error(e.message);
  }
}
