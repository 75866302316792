import React, { useState } from "react";

import "./index.styles.scss";
import { apiRegister } from "shared/api/user";
import { Verification } from "../Verification";
import { getReferralCode } from "shared/api/storageHelper";
import { TextField } from "@mui/material";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { validateEmail } from "shared/utils/utils";

export const SignUpScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState<"register" | "verify">("register");

  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onBack = () => {
    navigate(-1);
  };

  const onRegister = async () => {
    setShowError(false);
    setErrorMessage("");

    if (userName.length == 0) {
      setShowError(true);
      setErrorMessage(t("add_your_name_sign_up"));
      return;
    }

    if (!validateEmail(email)) {
      setShowError(true);
      setErrorMessage(t("msg_invalid_email_entered"));
      return;
    }

    if (password === confirmPassword && !!userName && !!email && !!password) {
      if (password.length < 10) {
        setShowError(true);
        setErrorMessage(t("msg_too_easy_to_guess"));
        return;
      }

      setShowError(false);
      setIsRegistering(true);

      const res = await apiRegister({
        username: userName,
        fullname: userName,
        email,
        password,
        referral: getReferralCode(),
      });

      if (res?.success) {
        setStep("verify");
      } else {
        setErrorMessage(res?.error);
        setShowError(true);
      }

      setIsRegistering(false);
    } else if (!!password && password !== confirmPassword) {
      setErrorMessage(t("msg_password_mismatch"));
      setShowError(true);
    }
  };

  const renderVerifyStep = () => {
    return (
      <div className="main-body">
        <div className="verify_content">
          <div className="register_verify">
            <Verification
              email={email}
              type="signup"
              onBack={() => {
                setStep("register");
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLogo = () => {
    return (
      <div>
        <div className="row_align_items">
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderSignUpBody = () => {
    return (
      <div className="main-body">
        <div className="main-content">
          <div className="font-medium font20">
            {t("sign_up_for", { name: process.env.REACT_APP_APP_NAME })}
          </div>
          <TextField
            id="outlined-basic"
            label={t("p_user_name")}
            variant="outlined"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            InputProps={{
              classes: {
                input: "font-regular font16",
              },
            }}
            style={{ width: "100%" }}
          />
          <TextField
            id="outlined-basic"
            label={t("p_email_address")}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              classes: {
                input: "font-regular font16",
              },
            }}
            style={{ width: "100%" }}
          />
          <TextField
            id="outlined-basic"
            label={t("p_password")}
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              classes: {
                input: "font-regular font16",
              },
            }}
            type="password"
            style={{ width: "100%" }}
          />

          <TextField
            id="outlined-basic"
            label={t("p_confirm_password")}
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              classes: {
                input: "font-regular font16",
              },
            }}
            type="password"
            style={{ width: "100%" }}
          />

          {renderSignUp()}
          {renderBackBtn()}
          {renderError()}
        </div>
      </div>
    );
  };

  const renderSignUp = () => {
    return (
      <div className="register-btn clickable" onClick={onRegister}>
        {isRegistering ? (
          <LoadingCircular color="white" size={16} />
        ) : (
          <div className="row_align_items">
            <div
              className="font-regular font14"
              style={{ color: "white", marginRight: 6 }}
            >
              {t("SIGN_UP")}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderBackBtn = () => {
    return (
      <div className="back-btn clickable" onClick={onBack}>
        <div className="btn-txt font-regular font14">={t("BACK_TO_LOGIN")}</div>
      </div>
    );
  };

  const renderError = () => {
    if (showError) {
      return (
        <div className="error-message font-regular font14">{errorMessage}</div>
      );
    }
  };

  return (
    <div className="sign_up_container row" style={{ marginLeft: 0 }}>
      <div className="left-panel col-md-8">
        {renderLogo()}
        {step === "register" ? renderSignUpBody() : renderVerifyStep()}
      </div>
      <div className="right-panel col-md-4"></div>
    </div>
  );
};
