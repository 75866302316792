import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./index.styles.scss";
import "react-toggle/style.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/interfaces";
import { CustomSwitchButton } from "components/Common/CustomSwitchButton";
import { apiEditPublishThreshold } from "shared/api/extensions";
import { LoadingCircular } from "components/Common/LoadingCircular";
import UCheckIcon from "assets/svg/UCheckIcon";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import { useTranslation } from "react-i18next";

export const SettingTopic = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userData, userId } = useSelector((state: RootState) => state.app);

  const [isEditing, setIsEditing] = useState(false);
  const [newThreshold, setNewThreshold] = useState<any>(
    userData?.publish_threshold
  );
  const [isSaving, setIsSaving] = useState(false);

  const onChangedToogle = (newStatus: boolean) => {
    if (newStatus) {
      setNewThreshold(2);
      onSave(2);
    } else {
      setNewThreshold(null);
      onSave(null);
    }
  };

  const onSave = async (newVal) => {
    if (!isSaving) {
      setIsSaving(true);

      const res = await apiEditPublishThreshold({ threshold: newVal });
      setIsEditing(false);
      if (res.success) {
        dispatch(appAction.loadUserInfo(userId!));
      }

      setIsSaving(false);
    }
  };

  const handleKeyPress = (e) => {
    // Disallow anything that's not a number
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
      setNewThreshold("");
    }
  };

  const renderActiveView = () => {
    return (
      <div
        className="val_threshold font-regular font14 clickable"
        onClick={() => {
          setIsEditing(true);
        }}
      >
        {t("threshold_days", { threshold: newThreshold })}
      </div>
    );
  };

  const renderNormalView = () => {
    return (
      <div className="row_align_items" style={{ gap: 8 }}>
        {newThreshold && renderActiveView()}
        <CustomSwitchButton
          isSelected={
            userData?.publish_threshold
              ? userData?.publish_threshold >= 0
              : false
          }
          onChanged={onChangedToogle}
        />
      </div>
    );
  };

  const renderEditView = () => {
    return (
      <div className="row_align_items">
        <input
          value={newThreshold}
          placeholder={t("p_threshold")}
          className="sub_input font-bold font14"
          type="number"
          min="0"
          step={1}
          onKeyDown={handleKeyPress}
          onChange={(e) => {
            const reg = /^$|^[0-9]+$/;
            const newValue = e.target.value;

            if (reg.test(newValue) && parseInt(newValue) > 0) {
              setNewThreshold(e.target.value);
            } else {
              setNewThreshold("");
            }
          }}
        />
        <div className="edit_done_btn font-bold font16">
          {isSaving ? (
            <div style={{ marginLeft: 8 }}>
              <LoadingCircular size={16} color="main" />
            </div>
          ) : (
            <div className="row_align_items" style={{ gap: 8 }}>
              <div
                className="clickable"
                style={{ marginLeft: 8 }}
                onClick={() => {
                  if (newThreshold !== "-" && !!newThreshold) {
                    onSave(newThreshold);
                  } else {
                    setNewThreshold("");
                  }
                }}
              >
                <UCheckIcon color="#2BBC3A" />
              </div>
              <div
                className="clickable"
                onClick={() => {
                  setNewThreshold(userData?.publish_threshold);
                  setIsEditing(false);
                }}
              >
                <RedCloseIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="setting_topic">
      <div className="topic_title font-regular font14">{t("interests")}</div>
      <div className="item-row">
        <div
          className="font-regular font14"
          data-tooltip-id={`setting_topic`}
          data-tooltip-html={`<div class="tooltipe-layout"><div class="font-regular font14">${t(
            "tooltip_after_this_new_interest_auto_pop"
          )}</div></div>`}
        >
          {t("autopublish_new_interest")}
        </div>
        {isEditing ? renderEditView() : renderNormalView()}
        <ReactTooltip id={`setting_topic`} html="true" />
      </div>
    </div>
  );
};
