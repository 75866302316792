import React from "react";

function WhatsappIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "24"}
      height={props.size ?? "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fill={props.color ?? "green"}
        d="M17.498 14.381c-.297-.149-1.766-.869-2.04-.967-.274-.1-.473-.15-.671.149-.2.296-.772.967-.947 1.165-.174.198-.348.222-.646.073a8.165 8.165 0 01-2.401-1.474 8.96 8.96 0 01-1.662-2.06c-.173-.297-.018-.458.132-.605.134-.134.299-.347.447-.521.123-.15.224-.318.3-.496a.544.544 0 00-.026-.521c-.075-.149-.672-1.612-.92-2.207-.242-.579-.488-.5-.673-.51a12.26 12.26 0 00-.571-.011 1.101 1.101 0 00-.796.373 3.333 3.333 0 00-1.046 2.479 5.763 5.763 0 001.22 3.075 13.286 13.286 0 005.1 4.487c.556.238 1.124.448 1.702.627.61.184 1.254.224 1.881.116a3.073 3.073 0 002.015-1.411c.196-.444.257-.936.174-1.414-.074-.125-.273-.198-.572-.347zm3.005-10.897A11.99 11.99 0 004.78 2.456 11.89 11.89 0 00.331 9.569a11.851 11.851 0 001.364 8.268L0 24l6.335-1.653a11.987 11.987 0 005.71 1.448h.006c2.363-.001 4.673-.7 6.638-2.007a11.913 11.913 0 004.4-5.34 11.85 11.85 0 00.682-6.872 11.884 11.884 0 00-3.268-6.092zm-3.175 16.79a9.977 9.977 0 01-5.277 1.511h-.005a9.95 9.95 0 01-5.055-1.379l-.363-.213-3.76.982 1.004-3.65-.235-.374a9.852 9.852 0 01.32-10.98 9.926 9.926 0 014.56-3.511 9.975 9.975 0 015.753-.398 9.943 9.943 0 015.004 2.851 9.845 9.845 0 011.84 10.831 9.9 9.9 0 01-3.786 4.33z"
      ></path>
    </svg>
  );
}

export default WhatsappIcon;
