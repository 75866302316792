import React from "react";

import "./index.styles.scss";

import { LoadingCircular } from "../LoadingCircular";
import { BgPinkBgBtn } from "assets/svg/BgPinkBgBtn";
import { BgPinkBgSmallBtn } from "assets/svg/BgPinkBgSmallBtn";

interface Props {
  title: string;
  isLoading: boolean;
  isSmall?: boolean;
  onClicked: () => void;
}

export const PinkBgBtn = ({
  title,
  isLoading,
  isSmall = false,
  onClicked,
}: Props) => {
  return (
    <div className="pink_bg_btn clickable" onClick={onClicked}>
      {isSmall ? <BgPinkBgSmallBtn /> : <BgPinkBgBtn />}
      <div className="btn_content">
        {isLoading ? (
          <LoadingCircular color="white" size={24} />
        ) : (
          <div className="font-regular font18" style={{ color: "white" }}>
            {title}
          </div>
        )}
      </div>
    </div>
  );
};
