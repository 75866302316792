import React from "react";
import { Scrollbars } from "react-custom-scrollbars";

import "./index.styles.scss";
import { useVibeMatchInterestData } from "shared/hook/fetchData";
import { Grid } from "@mui/material";
import { CardNormalTopic } from "../../Topics/CardNormalTopic";
import { CardNotEnoughTopic } from "../../Topics/CardNotEnoughTopic";
import { CardHiddenTopic } from "../../Topics/CardHiddenTopic";
import { useTranslation } from "react-i18next";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { useQueryClient } from "@tanstack/react-query";

interface Props {
  userId: number;
}

export const VibeMatchInterest = ({ userId }: Props) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const {
    data: interestData,
    fetchNextPage,
    hasNextPage,
    hasPreviousPage,
    isInitialLoading,
    isFetchingNextPage,
    isError,
    isFetched: isFetchedInterest,
    refetch: refetchInterestData,
  } = useVibeMatchInterestData(userId);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      hasNextPage && !isFetchingNextPage && fetchNextPage();
    }
  };

  const onUpdateHideStatus = (topicId: any, isHidden: number) => {
    queryClient.setQueryData(["vibe_check", userId, null], (oldData) => {
      if (!oldData) return null; // Return null if no data is available.

      // Clone the original data to avoid mutation.
      const newData = JSON.parse(JSON.stringify(oldData));

      // Find the index of the item with the provided itemId.
      const itemIndex = newData.pages
        .flatMap((page) => page.data)
        .findIndex((item) => item.id === topicId);

      if (itemIndex !== -1) {
        // Get the reference to the item to update
        const itemToUpdate = newData.pages.flatMap((page) => page.data)[
          itemIndex
        ];
        itemToUpdate.is_hidden = isHidden;

        return newData;
      }

      // If the item is not found, return the original data unchanged.
      return oldData;
    });
  };

  const renderBeTopicItem = (item, index) => {
    if (item.is_hidden === 1 || item.is_hidden_own === 1) {
      return <CardHiddenTopic key={`explore_card_be_topic_${index}`} />;
    }

    if ((item.id === "" || !item.id) && item.is_hidden !== 0) {
      return (
        <CardNotEnoughTopic
          key={`explore_card_be_topic_${index}`}
          data={item}
        />
      );
    }

    return (
      <CardNormalTopic
        data={item}
        key={`explore_card_be_topic_${index}`}
        isVibeMatch={true}
        onUpdateHideStatus={onUpdateHideStatus}
      />
    );
  };

  const allInterests = interestData
    ? interestData.pages.flatMap((page) => page.data)
    : [];

  if (isInitialLoading) {
    return (
      <div className="vibe-match-interest">
        <div className="center">
          <RectangleLoadingAnimation />
        </div>
      </div>
    );
  } else if (isFetchedInterest && allInterests.length === 0) {
    return (
      <div className="vibe-match-interest">
        <div className="empty_container">
          <img
            src={require("assets/gif/empty_content.gif")}
            className="empty_image"
          />
          <div className="font-bold font24">{t("no_shared_interest")}</div>
          <div
            className="font-regular font16"
            style={{
              color: "rgba(0, 0, 0, 0.6)",
              maxWidth: 400,
              textAlign: "center",
            }}
          >
            {t("you_two_might_vibe")}
            <br />
            <br />
            {t("link_up_your_go_spot")}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="vibe-match-interest">
        <Scrollbars
          onScroll={handleScroll}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
          style={{ paddingBottom: 100 }}
        >
          <Grid container spacing={2}>
            {allInterests.map((item, index) => {
              return renderBeTopicItem(item, index);
            })}
          </Grid>
        </Scrollbars>
      </div>
    );
  }
};
