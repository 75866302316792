import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const BgPinkBgSmallBtn = (props: IconProps) => {
  return (
    <svg style={{ width: "100%" }} fill="none" viewBox="0 0 87 52">
      <path
        fill="#EA21A2"
        d="M0 16C0 7.163 7.163 0 16 0h63a8 8 0 018 8v20c0 13.255-10.745 24-24 24H16C7.163 52 0 44.837 0 36V16z"
      ></path>
      <path
        fill="url(#paint0_radial_19_17406)"
        fillOpacity="0.7"
        d="M0 16C0 7.163 7.163 0 16 0h63a8 8 0 018 8v20c0 13.255-10.745 24-24 24H16C7.163 52 0 44.837 0 36V16z"
      ></path>
      <path
        fill="url(#paint1_radial_19_17406)"
        d="M0 16C0 7.163 7.163 0 16 0h63a8 8 0 018 8v20c0 13.255-10.745 24-24 24H16C7.163 52 0 44.837 0 36V16z"
      ></path>
      <defs>
        <radialGradient
          id="paint0_radial_19_17406"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(45.9167 33.94446 -33.18813 44.89361 22.958 10.833)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" stopOpacity="0.59"></stop>
          <stop offset="0.698" stopColor="#fff" stopOpacity="0"></stop>
          <stop offset="1" stopColor="#fff" stopOpacity="0"></stop>
        </radialGradient>
        <radialGradient
          id="paint1_radial_19_17406"
          cx="0"
          cy="0"
          r="1"
          gradientTransform="matrix(-4.76716 -44.4167 82.2181 -8.82432 75.082 45.5)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopOpacity="0.23"></stop>
          <stop offset="0.862" stopOpacity="0"></stop>
        </radialGradient>
      </defs>
    </svg>
  );
};
