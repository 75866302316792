import React from "react";

function CloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 14}
      height={props.size ?? 14}
      fill="none"
      viewBox="0 0 14 14"
    >
      <path
        d="M8.41403 6.99997L13.7069 1.70706L12.2927 0.292847L6.99982 5.58576L1.70703 0.292969L0.292818 1.70718L5.5856 6.99997L0.292725 12.2928L1.70694 13.7071L6.99981 8.41418L12.2928 13.7072L13.707 12.293L8.41403 6.99997Z"
        fill="black"
      />
    </svg>
  );
}

export default CloseIcon;
