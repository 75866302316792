import React from "react";

function ArrowRightIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.58531 18.5L8.5 17.3073L13.3294 12L8.5 6.69272L9.58531 5.5L15.5 12L9.58531 18.5Z"
        fill={props.color ?? "#DBDBDB"}
      />
    </svg>
  );
}

export default ArrowRightIcon;
