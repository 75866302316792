import * as React from "react";

interface Props {
  size: number;
  percent: number;
  borderWidth?: number;
  vibeType: "low" | "medium" | "high" | any;
}

function VibeMatchPercentCircle({
  size,
  percent,
  vibeType,
  borderWidth,
}: Props) {
  let gap = 4;
  let strokeWidth = borderWidth ?? 4;
  let radius = (size - strokeWidth * 2) / 2;
  let thumbRadius = strokeWidth;
  let circumference = 2 * Math.PI * radius;
  let utilArea = (circumference - gap * 4) / 4;

  const polarToCartesian = React.useCallback(
    (angleToChange: number) => {
      let r = radius;
      let hC = radius + thumbRadius;
      let a = ((angleToChange - 90) * Math.PI) / 180.0;
      let x = hC + r * Math.cos(a);
      let y = hC + r * Math.sin(a);
      return { x, y };
    },
    [radius, thumbRadius]
  );

  const startCoord = polarToCartesian(0);
  const endCoord = polarToCartesian(
    vibeType === "low"
      ? percent === 0
        ? 0
        : 90
      : vibeType === "medium"
      ? 180
      : percent < 80
      ? 270
      : 359.9
  );
  const endTintCoord = polarToCartesian(359.9);
  const trackColor =
    vibeType === "low"
      ? "#EA21A2"
      : vibeType === "medium"
      ? "#FBFF39"
      : "#00FF66";

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} fill="none">
      <path
        stroke={"rgba(0, 0, 0, 0.2)"}
        strokeWidth={strokeWidth}
        strokeDasharray={`${utilArea} ${gap}`}
        fill={"none"}
        d={[
          "M",
          startCoord.x,
          startCoord.y,
          "A",
          radius,
          radius,
          0,
          "1",
          1,
          endTintCoord.x,
          endTintCoord.y,
        ].join(" ")}
        transform={`rotate(320, ${radius + strokeWidth}, ${
          radius + strokeWidth
        })`}
      />
      <path
        stroke={trackColor}
        strokeWidth={strokeWidth}
        strokeDasharray={`${utilArea} ${gap}`}
        fill="none"
        d={`M${startCoord.x} ${startCoord.y} A ${radius} ${radius} 0 ${
          percent * 3.6 > 180 ? 1 : 0
        } 1 ${endCoord.x} ${endCoord.y}`}
        transform={`rotate(320, ${radius + strokeWidth}, ${
          radius + strokeWidth
        })`}
      />
    </svg>
  );
}

export default VibeMatchPercentCircle;
