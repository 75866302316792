import { RootState, SocialInfo, UserInfo } from "redux/interfaces";
import actions from "./action";

export type RootAppState = RootState["app"];

interface State extends RootAppState {
  isLoggedIn: boolean;
  userData?: UserInfo;
  userId?: number;
  userSocials: Array<SocialInfo>;
  isNewUser?: boolean;

  isCreatingMood: boolean;
  showRewardModal: boolean;
  showRewardListModal: boolean;
  showRewardDetailModal: boolean;
  showInvitationModal: boolean;
  showSuccessInvitationModal: boolean;
  showAddReferralCodeModal: boolean;
  showQRModal: boolean;
  showSearchModal: boolean;
  searchQuery: string;
  rewardDetail: any;

  topicScrollPos: any;
  vibeUserScrollPos: any;
  vibeUserInfo: any;
}

interface Action extends RootAppState {
  type: string;
  payload: any;
}

const initalState: State = {
  isLoggedIn: false,
  userId: undefined,
  userData: undefined,
  userSocials: [],
  isNewUser: true,

  isCreatingMood: false,
  showRewardModal: false,
  showRewardListModal: false,
  showRewardDetailModal: false,
  showInvitationModal: false,
  showSuccessInvitationModal: false,
  showAddReferralCodeModal: false,
  showConfirmDownloadAppModal: false,
  showQRModal: false,
  showSearchModal: false,
  rewardDetail: null,
  searchQuery: "",
  showImageFullView: false,
  fullImageUrl: "",

  topicScrollPos: null,
  vibeUserScrollPos: null,
  vibeUserInfo: null,
};

export default function appReducer(
  state: RootState["app"] = initalState,
  action: Action
) {
  const { type, payload } = action;

  switch (type) {
    case actions.SUCCESS_LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        userId: payload.id,
        userData: payload,
      };
    case actions.SUCCESS_LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userId: undefined,
        userData: undefined,
      };
    case actions.LOAD_USER_INFO:
      return {
        ...state,
      };
    case actions.LOAD_USER_INFO_SUCCESS:
      return {
        ...state,
        userData: payload.userInfo,
        userSocials: payload.socials,
      };
    case actions.LOAD_USER_INFO_FAILED:
      return {
        ...state,
      };
    case actions.TOOGLE_CREATING_MOOD_MODAL:
      return {
        ...state,
        isCreatingMood: payload,
      };
    case actions.TOOGLE_REWARD_MODAL:
      return {
        ...state,
        showRewardModal: payload,
      };
    case actions.TOOGLE_REWARD_HISTORY_MODAL:
      return {
        ...state,
        showRewardListModal: payload,
      };
    case actions.TOOGLE_INVITATION_MODAL:
      return {
        ...state,
        showInvitationModal: payload,
      };
    case actions.TOOGLE_INVITATION_SUCCESS_MODAL:
      return {
        ...state,
        showSuccessInvitationModal: payload,
      };
    case actions.TOOGLE_SEARCH_MODAL:
      return {
        ...state,
        showSearchModal: payload,
      };
    case actions.TOOGLE_REWARD_DETAIL_MODAL:
      return {
        ...state,
        showRewardDetailModal: payload.show,
        rewardDetail: payload.rewardInfo,
      };
    case actions.TOOGLE_ADD_REFERRAL_CODE_MODAL:
      return {
        ...state,
        showAddReferralCodeModal: payload,
      };
    case actions.SHOW_QR_MODAL:
      return {
        ...state,
        showQRModal: payload,
      };
    case actions.SHOW_CONFIRM_DOWNLOAD_APP_MODAL:
      return {
        ...state,
        showConfirmDownloadAppModal: payload,
      };
    case actions.CHANGED_SEARCH:
      return {
        ...state,
        searchQuery: payload,
      };
    case actions.SHOW_IMAGE_FULL_MODAL:
      return {
        ...state,
        showImageFullView: true,
        fullImageUrl: payload,
      };
    case actions.SHOW_IMAGE_FULL_MODAL:
      return {
        ...state,
        showImageFullView: true,
        fullImageUrl: payload,
      };
    case actions.CLOSE_IMAGE_FULL_MODAL:
      return {
        ...state,
        showImageFullView: false,
      };
    case actions.UPDATE_SCROLL_POS_TOPICS:
      return {
        ...state,
        topicScrollPos: payload,
      };
    case actions.UPDATE_SCROLL_POS_VIBEUSER:
      return {
        ...state,
        vibeUserScrollPos: payload,
      };
    case actions.UPDATE_IS_NEW_USER:
      return {
        ...state,
        isNewUser: payload,
      };
    case actions.UPDATE_VIBE_USER_INFO:
      return {
        ...state,
        vibeUserInfo: payload,
      };
    default:
      return state;
  }
}
