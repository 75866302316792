import React from "react";

import "./index.styles.scss";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

export const CardHiddenTopic = () => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} md={12} lg={12} xl={6}>
      <div className="card_hidden_topic_container">
        <div className="explore_card_topic">
          <img
            src={require("assets/image/bg_shake_vibe_interest.png")}
            className="bg_image"
          />
          <div className="card_content">
            <div className="font-bold font26">{t("interest_hidden")}</div>
          </div>
        </div>
      </div>
    </Grid>
  );
};
