import React from "react";

function BackIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "54"}
      height={props.size ?? "54"}
      fill="none"
      viewBox="0 0 54 54"
    >
      <rect
        x="0.75"
        y="0.75"
        width="52.5"
        height="52.5"
        rx="26.25"
        stroke="#808080"
        stroke-width="0.5"
      />
      <path
        d="M22.352 28.8372C21.6053 28.0906 21.6054 26.88 22.352 26.1335L28.7906 19.6948C29.1743 19.3111 29.7964 19.3111 30.1801 19.6948C30.5639 20.0785 30.5639 20.7006 30.1801 21.0844L27.2894 23.9751C25.33 25.9345 25.33 29.1114 27.2894 31.0708L30.1613 33.9427C30.5346 34.316 30.5346 34.9213 30.1613 35.2946C29.788 35.6679 29.1827 35.6679 28.8094 35.2946L22.3896 28.8749L22.352 28.8372Z"
        fill="black"
      />
    </svg>
  );
}

export default BackIcon;
