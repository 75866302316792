import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";

import "./index.styles.scss";
import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import CheckIcon from "assets/svg/CheckIcon";
import { useDispatch } from "react-redux";
import CloseIcon from "assets/svg/CloseIcon";
import { apiGetUserMobileQR } from "shared/api/user";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";

export const UserQRModal = () => {
  const dispatch = useDispatch();

  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await apiGetUserMobileQR({});

    if (res.success) {
      setUrl(res.qr_url);
    }
  };

  const onHide = () => {
    dispatch(appAction.toogleUserQRModal(false));
  };

  const renderHeader = () => {
    return (
      <div
        className="row_align_items"
        style={{ justifyContent: "flex-end", width: "100%" }}
      >
        <div className="close-btn clickable" onClick={onHide}>
          <CloseIcon />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="content-body">
        <div className="qr_code_view">
          {url ? (
            <img src={url ?? ""} className="qr_code_image" />
          ) : (
            <div className="qr_code_image">
              <RectangleLoadingAnimation backgroundColor="#e3f4ff" />
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_user_qr">
        <div
          className="modal_body"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {renderHeader()}
          {renderBody()}
          {/* <div className="btn-thank clickable" onClick={onHide}>
            <div className="btn-txt font-regular font16">Got it</div>
          </div> */}
        </div>
      </div>
    </OpacityModal>
  );
};
