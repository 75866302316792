import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const ShowIcon = (props: IconProps) => {
  return (
    <svg
      style={props.style}
      width={props.width ?? 24}
      height={props.height ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.83831 15.2561C4.1017 14.2992 3.7334 13.8207 3.7334 12.3999C3.7334 10.9791 4.1017 10.5006 4.83831 9.54364C6.3091 7.63284 8.77576 5.46655 12.4001 5.46655C16.0244 5.46655 18.491 7.63284 19.9618 9.54364C20.6984 10.5006 21.0667 10.9791 21.0667 12.3999C21.0667 13.8207 20.6984 14.2992 19.9618 15.2561C18.491 17.1669 16.0244 19.3332 12.4001 19.3332C8.77576 19.3332 6.3091 17.1669 4.83831 15.2561Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M15 12.4C15 13.836 13.836 15 12.4 15C10.9641 15 9.80005 13.836 9.80005 12.4C9.80005 10.9641 10.9641 9.80005 12.4 9.80005C13.836 9.80005 15 10.9641 15 12.4Z"
        stroke="black"
        strokeWidth="1.5"
      />
    </svg>
  );
};
