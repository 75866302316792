import { SearchIcon } from "assets/svg/SearchIcon";
import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";

import "./SearchInput.styles.scss";
import { RootState } from "redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "use-debounce";
import { useTranslation } from "react-i18next";

const SearchInput = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFocused, setIsFocused] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [debouncedSearchValue] = useDebounce(searchValue, 500);

  const {
    searchQuery,
    showSearchModal,
    showRewardModal,
    showRewardListModal,
    showInvitationModal,
    showSuccessInvitationModal,
  } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    if (
      !showRewardModal &&
      !showRewardListModal &&
      !showInvitationModal &&
      !showSuccessInvitationModal
    ) {
      dispatch(appAction.onChangedSearch(debouncedSearchValue));
    }
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (!showSearchModal) {
      setSearchValue("");
    }
  }, [showSearchModal]);

  const handleFocus = () => {
    setIsFocused(true);
    if (
      !showRewardModal &&
      !showRewardListModal &&
      !showInvitationModal &&
      !showSuccessInvitationModal
    ) {
      dispatch(appAction.toogleSearchModal(true));
    }
  };

  const handBlur = () => {
    setIsFocused(false);
  };

  const onChanged = (text) => {
    setSearchValue(text);
  };

  return (
    <div className="search-container">
      <input
        value={searchValue}
        type="text"
        placeholder={isFocused ? "" : t("p_search_input")}
        className="search-input font-regular font14"
        onFocus={handleFocus}
        onBlur={handBlur}
        onChange={(e) => onChanged(e.target.value)}
      />
      <SearchIcon className="search-icon" />
    </div>
  );
};

export default SearchInput;
