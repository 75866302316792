import React from "react";

function RedCloseIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 30}
      height={props.size ?? 30}
      fill="none"
      viewBox="0 0 30 30"
    >
      <rect x="5" y="5" width="20" height="20" rx="10" fill="#F32D68" />
      <path
        d="M13.9393 14.9999L10.227 18.7122L11.2877 19.7729L15 16.0606L18.7123 19.7729L19.773 18.7122L16.0607 14.9999L19.773 11.2876L18.7123 10.227L15 13.9393L11.2877 10.227L10.227 11.2876L13.9393 14.9999Z"
        fill="white"
      />
    </svg>
  );
}

export default RedCloseIcon;
