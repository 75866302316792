import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./index.styles.scss";
import { Grid } from "@mui/material";
import { TopicLocalItemProps } from "shared/utils/type.def";
import { PinkBorderBtn } from "components/Common/PinkBorderBtn";
import { PinkBgBtn } from "components/Common/PinkBgBtn";
import { EXTENSION_ID, SAFARI_EXTENSION_ID } from "App";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";

interface Props {
  data: TopicLocalItemProps;
  onRemove: (topicId: any) => void;
  onPublish: (topicData: TopicLocalItemProps) => void;
}

export const CardLocalTopic = ({ data, onRemove, onPublish }: Props) => {
  const { t } = useTranslation();
  const [isPublishing, setIsPublishing] = useState(false);
  const [isHidding, setIsHidding] = useState(false);

  const sendMessageToExtension = (type, data, callback) => {
    // Determine the browser and set the correct extension ID
    let extensionId;
    if (typeof browser !== "undefined") {
      extensionId = SAFARI_EXTENSION_ID; // Assuming 'browser' namespace implies Safari or similar
    } else if (typeof chrome !== "undefined") {
      // Chrome
      extensionId = EXTENSION_ID;
    } else { //Firefox
      console.error("Unsupported browser for messaging, switching to CustomEvent");
      var event = new CustomEvent(type, {detail: data});
      window.dispatchEvent(event);
      return;
    }
    
    const runtime = (typeof browser !== "undefined") ? browser.runtime : chrome.runtime;
  
    // Send message to the extension
    if (runtime.sendMessage) {
      runtime.sendMessage(extensionId, { type, data }, callback);
    } else {
      console.error("Browser does not support the `sendMessage` method.");
    }
  };

  const hideTopic = () => {
    if (isHidding) return;
  
    setIsHidding(true);
  
    try {
      sendMessageToExtension("hideTopic", data.topic, (response) => {
        console.log("Hide Response from extension:", response);
        if (response && response.success) {
          onRemove(data.topic);
          showToast("success", t("msg_interest_removed_success"));
        }
        setIsHidding(false);
      });
    } catch (error) {
      console.log("extension-hide-topic-error", { error });
      setIsHidding(false);
    }
  };

  window.addEventListener("hideTopicResponse", function(event) {
    const dataStr = (event as CustomEvent<string>).detail;
    try {
      const detail = JSON.parse(dataStr);
      console.log("Received response from extension:", detail);
      if (detail.data && isHidding) {
        onRemove(detail.data);
        showToast("success", t("msg_interest_removed_success"));
      }
      setIsHidding(false);
    } catch (error) {
      console.error("Error parsing data from extension:", error);
      setIsHidding(false);
    }
  });
  
  const publishTopic = () => {
    if (isPublishing) return;
  
    setIsPublishing(true);
  
    try {
      sendMessageToExtension("publishTopic", data.topic, (response) => {
        if (response && response.success) {
          onPublish(data);
        }
        setIsPublishing(false);
      });
    } catch (error) {
      console.log("extension-publish-topic-error", { error });
      setIsPublishing(false);
    }
  };

  window.addEventListener("publishTopicResponse", function(event) {
    const dataStr = (event as CustomEvent<string>).detail;
    try {
      const detail = JSON.parse(dataStr);
      if (detail.data == data.topic && isPublishing) {
        onPublish(data);
      }
      setIsPublishing(false);
    } catch (error) {
      console.log("extension-publish-topic-error", { error });
      setIsPublishing(false);
    }
  });

  const renderBg = () => {
    return (
      <img
        src={require("assets/image/image.png")}
        style={{ width: "100%", aspectRatio: 776 / 558 }}
      />
    );
  };

  const renderContent = () => {
    return (
      <div className="explore_card_topic_content">
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className="topic_name font-medium font40 clickable"
              data-tooltip-id={`topic_title_${data.topic}`}
              data-tooltip-content={data.name}
            >
              {data.name}
            </div>
          </div>
        </div>
        {renderBottom()}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom_container">
        <div className="font-regular font22" style={{ textAlign: "center" }}>
          {data.level === 1 ? t("New_Interest") : t("New_Subinterest")}
        </div>
        <div className="btn_container">
          <div className="btn_item">
            <PinkBorderBtn
              title={t("forget")}
              isLoading={isHidding}
              onClicked={() => {
                hideTopic();
              }}
            />
          </div>
          <div className="btn_item">
            <PinkBgBtn
              title={t("publish")}
              isLoading={isPublishing}
              onClicked={() => {
                publishTopic();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Grid item xs={12} md={12} lg={12} xl={6}>
      <div className="explore_card_topic">
        {renderBg()}
        {renderContent()}
      </div>
      <ReactTooltip id={`topic_title_${data.topic}`} />
    </Grid>
  );
};
