import React from "react";

function NewUserAvatar() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="93"
      height="94"
      fill="none"
      viewBox="0 0 93 94"
    >
      <g clipPath="url(#clip0_2568_10174)">
        <path fill="#F9EDE0" d="M61.306 91.553zM63.736 90.652z"></path>
        <path
          fill="#fff"
          d="M46.5 94C72.181 94 93 72.957 93 47S72.181 0 46.5 0 0 21.043 0 47s20.819 47 46.5 47z"
        ></path>
        <path
          fill="#F9EDE0"
          d="M43.131 93.864zM35.528 92.671zM40.476 93.595zM28.583 90.38zM56.491 92.9zM54.044 93.374zM66.55 89.415zM64.417 90.38c-.224.095-.454.18-.68.27.225-.09.454-.175.68-.27zM66.614 89.386l-.064.029c.02-.01.043-.018.064-.03zM38.956 93.374c-.332-.055-.662-.117-.993-.178.332.061.661.125.993.178zM43.95 93.927c-.274-.015-.546-.044-.819-.063.275.02.545.048.82.063zM29.265 90.651c-.226-.09-.456-.176-.68-.27.224.094.454.178.68.27zM31.694 91.552c-.284-.097-.57-.19-.851-.291.28.102.566.194.851.291zM52.525 93.595c-.321.042-.64.089-.962.123.322-.036.64-.081.962-.123zM57.472 92.67zM34.094 92.295c-.312-.088-.627-.175-.938-.27.311.095.624.182.938.27z"
        ></path>
        <path
          fill="#E4E4E4"
          d="M57.144 50.232a19.48 19.48 0 008.598-16.197c0-10.74-8.616-19.449-19.242-19.449-10.626 0-19.241 8.708-19.241 19.449a19.48 19.48 0 008.6 16.199l-.001-.002c-12.401 3.778-22.057 13.95-25.166 26.75l.002.001a47.81 47.81 0 001.57 1.812c.13.144.265.282.399.423.413.444.832.882 1.261 1.31.178.176.36.346.54.52.399.382.8.76 1.211 1.128.204.181.41.361.616.54.403.348.81.69 1.225 1.024a42.973 42.973 0 001.935 1.48c.223.16.443.323.67.48.455.318.918.623 1.385.924.21.135.415.274.627.407.538.336 1.087.657 1.64.971.146.083.288.172.436.253.715.395 1.442.775 2.18 1.133l.054.024c.703.34 1.419.663 2.142.968.224.095.453.18.68.27.522.21 1.045.418 1.577.61.283.102.568.194.852.291.484.166.972.326 1.462.474.311.094.624.181.938.269a45.714 45.714 0 002.417.606c.482.107.966.204 1.454.295.33.061.659.125.992.178.504.083 1.01.152 1.519.219.32.042.64.089.962.123.561.062 1.127.105 1.693.146.274.02.545.048.82.063.845.049 1.693.076 2.55.076.855 0 1.704-.027 2.549-.073.274-.014.546-.044.819-.063a44.34 44.34 0 001.693-.146c.323-.035.642-.08.962-.123a47.203 47.203 0 001.519-.219c.332-.055.662-.116.992-.178a42.854 42.854 0 003.871-.901c.313-.088.627-.175.938-.27.492-.148.978-.309 1.462-.472.284-.098.571-.19.852-.292a49.03 49.03 0 001.578-.61c.226-.09.455-.176.68-.27.72-.305 1.431-.624 2.132-.965l.064-.029a46.399 46.399 0 002.18-1.132c.147-.082.29-.17.435-.253a47.51 47.51 0 001.64-.971c.212-.131.42-.27.628-.407.466-.301.93-.608 1.385-.924.225-.157.446-.319.669-.481a40.509 40.509 0 001.935-1.48 46.432 46.432 0 003.051-2.692c.18-.173.363-.345.54-.52.43-.428.85-.865 1.263-1.31.131-.142.267-.28.399-.422a47.81 47.81 0 001.57-1.812l.001-.002c-3.107-12.8-12.763-22.973-25.164-26.751z"
        ></path>
      </g>
      <rect
        width="92"
        height="93"
        x="0.5"
        y="0.5"
        stroke="#F3F3F3"
        rx="46"
      ></rect>
      <defs>
        <clipPath id="clip0_2568_10174">
          <rect width="93" height="94" fill="#fff" rx="46.5"></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default NewUserAvatar;
