import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const BgPinkBorderBtn = (props: IconProps) => {
  return (
    <svg
      style={{ width: "100%" }}
      viewBox="0 0 151 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.5H135C143.56 0.5 150.5 7.43959 150.5 16V27C150.5 35.5604 143.56 42.5 135 42.5H24C11.0213 42.5 0.5 31.9787 0.5 19V8C0.5 3.85787 3.85786 0.5 8 0.5Z"
        fill="white"
        fillOpacity="0.2"
      />
      <path
        d="M8 0.5H135C143.56 0.5 150.5 7.43959 150.5 16V27C150.5 35.5604 143.56 42.5 135 42.5H24C11.0213 42.5 0.5 31.9787 0.5 19V8C0.5 3.85787 3.85786 0.5 8 0.5Z"
        stroke="#EA21A2"
      />
      <path
        d="M8 0.5H135C143.56 0.5 150.5 7.43959 150.5 16V27C150.5 35.5604 143.56 42.5 135 42.5H24C11.0213 42.5 0.5 31.9787 0.5 19V8C0.5 3.85787 3.85786 0.5 8 0.5Z"
        stroke="url(#paint0_radial_2547_26890)"
        stroke-opacity="0.7"
      />
      <path
        d="M8 0.5H135C143.56 0.5 150.5 7.43959 150.5 16V27C150.5 35.5604 143.56 42.5 135 42.5H24C11.0213 42.5 0.5 31.9787 0.5 19V8C0.5 3.85787 3.85786 0.5 8 0.5Z"
        stroke="url(#paint1_radial_2547_26890)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_2547_26890"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(39.8472 8.95833) rotate(19.4029) scale(84.4932 54.1512)"
        >
          <stop stopColor="white" stopOpacity="0.59" />
          <stop offset="0.697917" stopColor="white" stopOpacity="0" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_2547_26890"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(130.315 37.625) rotate(-102.695) scale(37.6496 140.816)"
        >
          <stop stopOpacity="0.23" />
          <stop offset="0.861815" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};
