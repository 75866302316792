import React from "react";
import { Route, Routes } from "react-router-dom";
import { RootNames } from "./rootUrl";
import { SignUpScreen } from "components/Auth/SignUp";
import { ResetPasswordScreen } from "components/Auth/ResetPassword";
import { Login } from "components/Auth/Login";
import { NoExtension } from "components/Auth/NoExtension";
import TokenHandler from "components/Auth/TokenHandler";
import { PhoneLogin } from "components/Auth/PhoneLogin";
import { Dashboard } from "components/Auth/Dashboard";

export const AuthRoutes = () => {
  return (
    <Routes>
      <Route index path={RootNames.root} element={<Dashboard />} />
      <Route index path={RootNames.login_with_phone} element={<PhoneLogin />} />
      <Route path={RootNames.login} element={<Login />} />
      <Route path={RootNames.register} element={<SignUpScreen />} />
      <Route
        path={RootNames.forget_password}
        element={<ResetPasswordScreen />}
      />
      <Route path={RootNames.login_with_phone} element={<PhoneLogin />} />
      <Route path={"/*"} element={<Dashboard />} />
      <Route path={RootNames.redditLogin} element={<TokenHandler />} />
    </Routes>
  );
};
