import React from "react";

import "./index.styles.scss";
import { CardInterestedPeople } from "./CardInterestedPeople";
import { useTranslation } from "react-i18next";

interface Props {
  arrUser: Array<any>;
}

export const InterestDetailPeople = ({ arrUser }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="interest_interested_people">
      <div className="font-medium font24">{t("interested_people")}</div>
      <div className="people_list">
        {arrUser.map((item, index) => {
          return (
            <CardInterestedPeople key={`item_user_${index}`} data={item} />
          );
        })}
      </div>
    </div>
  );
};
