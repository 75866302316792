import React from "react";

function RedditIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? "24"}
      height={props.size ?? "24"}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
        fill="#FF4500"
      />
      <path
        d="M19.998 12C19.998 11.0335 19.2145 10.25 18.2481 10.25C17.7763 10.25 17.349 10.4375 17.0343 10.741C15.8382 9.87784 14.19 9.32036 12.3543 9.25628L13.1515 5.50577L15.756 6.05945C15.7876 6.72161 16.33 7.25003 17 7.25003C17.6904 7.25003 18.25 6.69039 18.25 6.00002C18.25 5.30964 17.6904 4.75 17 4.75C16.509 4.75 16.0883 5.03575 15.8839 5.44769L12.9757 4.8295C12.8947 4.81192 12.81 4.82781 12.7406 4.87309C12.671 4.91828 12.6223 4.9892 12.6051 5.07025L11.7148 9.2552C9.85219 9.30691 8.17767 9.86514 6.96609 10.738C6.6517 10.4364 6.22589 10.2501 5.75583 10.2501C4.78936 10.2501 4.00584 11.0336 4.00584 12.0001C4.00584 12.7112 4.43067 13.3219 5.03981 13.5957C5.01281 13.7697 4.99805 13.9461 4.99805 14.125C4.99805 16.8175 8.13206 19 11.998 19C15.864 19 18.998 16.8175 18.998 14.125C18.998 13.9473 18.9835 13.772 18.9569 13.5992C19.5699 13.3269 19.998 12.7139 19.998 12ZM8.00002 13.2501C8.00002 12.5608 8.56102 12 9.25055 12C9.93947 12 10.5 12.5608 10.5 13.2501C10.5 13.9392 9.93947 14.4999 9.25055 14.4999C8.56102 14.4999 8.00002 13.9392 8.00002 13.2501ZM14.9701 16.5531C14.1169 17.405 12.4822 17.4713 12.0015 17.4713C11.5206 17.4713 9.8857 17.405 9.03361 16.5529C8.90714 16.4264 8.90714 16.2213 9.0337 16.0948C9.16013 15.9684 9.3653 15.9683 9.49181 16.0948C10.0295 16.6326 11.1798 16.8234 12.0015 16.8234C12.8231 16.8234 13.9737 16.6325 14.5123 16.0946C14.639 15.9683 14.8441 15.9685 14.9704 16.095C15.0968 16.2216 15.0967 16.4267 14.9701 16.5531ZM14.75 14.5002C14.0608 14.5002 13.5 13.9394 13.5 13.2503C13.5 12.5609 14.0608 12 14.75 12C15.4393 12 16 12.5609 16 13.2503C16 13.9394 15.4392 14.5002 14.75 14.5002Z"
        fill="white"
      />
    </svg>
  );
}

export default RedditIcon;
