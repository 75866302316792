import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const RewardItemBg = (props: IconProps) => {
  return (
    <svg
      width={props.width ?? 63}
      height={props.height ?? 69}
      viewBox="0 0 63 69"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_137_380)">
        <path
          d="M25.5 3.4641C29.2128 1.32051 33.7872 1.32051 37.5 3.4641L51.4808 11.5359C55.1936 13.6795 57.4808 17.641 57.4808 21.9282V38.0718C57.4808 42.359 55.1936 46.3205 51.4808 48.4641L37.5 56.5359C33.7872 58.6795 29.2128 58.6795 25.5 56.5359L11.5192 48.4641C7.80642 46.3205 5.51924 42.359 5.51924 38.0718V21.9282C5.51924 17.641 7.80642 13.6795 11.5192 11.5359L25.5 3.4641Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_137_380"
          x="0.519226"
          y="1.85641"
          width="61.9615"
          height="66.2872"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_137_380"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_137_380"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
