import React, { useEffect, useRef, useState } from "react";

import "./index.styles.scss";
import { apiGetUserMobileQR } from "shared/api/user";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const QRScreen = () => {
  const { t } = useTranslation();
  const params = useParams();

  const [step, setStep] = useState<"download" | "scan">(
    params.type === "mobile" ? "scan" : "download"
  );

  const [url, setUrl] = useState(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await apiGetUserMobileQR({});

    if (res && res.success) {
      setUrl(res.qr_url);
    }
  };

  const onDownloadAndroid = () => {
    window.open(process.env.REACT_APP_GOOGLE_PLAY);
  };

  const onDownloadIos = () => {
    window.open(process.env.REACT_APP_APP_STORE);
  };

  const renderDownloadView = () => {
    return (
      <div className="setting_content">
        <div className="font-bold" style={{ fontSize: 40 }}>
          {t("download_mobile_app")}
        </div>
        <div
          className="font-medium font16"
          style={{ marginTop: 16, width: "100%" }}
        >
          <div>{t("just_scan_this_qr")}</div>
          <div className="row_align_items">
            <div className="clickable hyper_text" onClick={onDownloadIos}>
              Apple App Store
            </div>
            &nbsp;and&nbsp;
            <div className="clickable hyper_text" onClick={onDownloadAndroid}>
              Google Play Store
            </div>
            .
          </div>
        </div>
        <div className="font-medium font16" style={{ width: "100%" }}>
          {t("if_you_got_the_app")}
        </div>

        <div className="qr_code_view">
          <img
            src={process.env.REACT_APP_DOWNLOAD_APP}
            className="qr_code_image"
          />
        </div>
        <div
          className="connect_btn clickable"
          onClick={() => {
            setStep("scan");
          }}
        >
          <div className="row_align_items">
            <div
              className="font-bold font14"
              style={{ color: "white", marginRight: 6 }}
            >
              {t("already_downloaded")}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderScanView = () => {
    return (
      <div className="setting_content">
        <div className="font-bold" style={{ fontSize: 40, width: "100%" }}>
          {t("connect_your_app")}
        </div>
        <div
          className="font-medium font16"
          style={{ marginTop: 16, width: "100%" }}
          dangerouslySetInnerHTML={{
            __html: t("pop_open_the_app", {
              name: process.env.REACT_APP_APP_NAME,
            }),
          }}
        ></div>

        <div className="qr_code_view">
          {url ? (
            <img src={url ?? ""} className="qr_code_image" />
          ) : (
            <div className="qr_code_image">
              <RectangleLoadingAnimation backgroundColor="white" />
            </div>
          )}
        </div>
        <div
          className="back_btn clickable"
          onClick={() => {
            setStep("download");
          }}
        >
          <div className="btn-txt font-bold font14">
            {t("back_to_download")}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="qr_container">
      <div className="left_panel">
        {step === "download" ? renderDownloadView() : renderScanView()}
      </div>
      <div className="right_panel">
        <img
          src={require("assets/image/bg_mobile_app.png")}
          className="app_image"
        />
      </div>
    </div>
  );
};
