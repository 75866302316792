import React, { useEffect, useState } from "react";

import "./index.styles.scss";
import { getReferralCode } from "shared/api/storageHelper";
import { apiAttachReferral } from "shared/api/util";
import { showToast } from "components/Common/PrimaryToast";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  onSuccess: () => void;
  onCancel: () => void;
}

export const RewardCodeBody = ({ onSuccess, onCancel }: Props) => {
  const { t } = useTranslation();
  const [isChecking, setIsChecking] = useState(false);
  const [accessCode, setAccessCode] = useState("");

  useEffect(() => {
    // checkLocalReferralCode();
  }, []);

  const checkLocalReferralCode = () => {
    const localCode = getReferralCode();
    if (!!localCode) {
      setAccessCode(localCode);
    }
  };

  const onCheckCode = async () => {
    if (!!accessCode && !isChecking) {
      setIsChecking(true);

      const res = await apiAttachReferral({ code: accessCode });
      if (res.success) {
        onSuccess();
      } else {
        showToast("error", res.error);
      }

      setIsChecking(false);
    }
  };

  return (
    <div className="reward_code_body">
      <div className="font-bold font20">{t("rewards_drop")}</div>
      <div
        className="font-regular font16"
        style={{ color: "#9DA9B6", textAlign: "center" }}
        dangerouslySetInnerHTML={{ __html: t("got_access_code") }}
      ></div>
      <TextField
        id="outlined-basic"
        name="text"
        label={t("p_rewards_access_code")}
        variant="outlined"
        value={accessCode}
        onChange={(e) => setAccessCode(e.target.value)}
        InputProps={{
          style: {
            textTransform: "capitalize",
          },
          classes: {
            input: "code-input font-regular font14",
          },
          autoFocus: true,
          autoComplete: "off",
        }}
        InputLabelProps={{
          classes: "font-regular font14",
        }}
        autoFocus={true}
        autoComplete="off"
        style={{ width: "100%" }}
      />
      <div className="register-btn clickable" onClick={onCheckCode}>
        <div className="row_align_items">
          {isChecking ? (
            <LoadingCircular size={16} color="white" />
          ) : (
            <div
              className="font-regular font16"
              style={{ color: "white", marginRight: 6 }}
            >
              {t("get_vibin")}
            </div>
          )}
        </div>
      </div>
      <div className="skip-btn clickable" onClick={onCancel}>
        <div className="row_align_items">
          <div className="btn-txt font-regular font16">{t("SKIP")}</div>
        </div>
      </div>
    </div>
  );
};
