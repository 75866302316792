import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./index.styles.scss";
import UCheckIcon from "assets/svg/UCheckIcon";

export const showToast = (type: "success" | "warn" | "error", message) => {
  if (type === "success") {
    toast.dismiss();
    toast(<SucessMessage message={message} />, {
      position: "top-center",
      autoClose: 1000,
    });
  } else if (type === "error") {
    toast.dismiss();
    toast(<ErrorMessage message={message} />, {
      position: "top-center",
      autoClose: 1000,
    });
  }
};

const SucessMessage = ({ message }) => {
  return (
    <div className="primary_toast row_align_items">
      <div className="success_toast_icon">
        <UCheckIcon color="#2BBC3A" size={24} />
      </div>
      <div className="font-regular font16">{message}</div>
    </div>
  );
};

const ErrorMessage = ({ message }) => {
  return (
    <div className="primary_toast row_align_items">
      <div className="error_toast_icon">
        <div className="font-regular font16" style={{ color: "#FF3E9A" }}>
          !
        </div>
      </div>
      <div className="font-regular font16">{message}</div>
    </div>
  );
};
