import HttpClient from "./apiClient";

export async function apiInviteByEmail(params: { email: string }) {
  try {
    const response: any = await HttpClient.postWithToken(
      "/utils/inviteByEmail",
      params
    );

    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAttachReferral(params: { code: any }) {
  try {
    const response: any = await HttpClient.postWithToken(
      "/utils/attachRewardCode",
      params
    );

    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetInviteLink(params: {}) {
  try {
    const response = await HttpClient.postWithToken(`utils/inviteLink`, params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiAuthorise(params: { type: string; token: string }) {
  try {
    const response = await HttpClient.postWithToken(
      `socials/authorise`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetActivationLink(params: { type: string }) {
  try {
    const response = await HttpClient.getWithToken(
      `utils/activationLink`,
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
