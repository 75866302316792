import React from "react";

interface Props {
  imageUrl?: any;
}

export const InterestDetailBg = ({ imageUrl }: Props) => {
  return (
    <svg width={"100%"} fill="none" viewBox="0 0 414 275">
      <defs>
        <clipPath id="customShape">
          <path
            fill="#000"
            d="M7.24 68.52C5.143 43.973 4.094 31.7 7.947 23.16c3.488-7.732 9-13.493 16.57-17.32 8.36-4.227 20.341-3.736 44.303-2.752C114.533 4.964 172.978 7 211.332 7c37.172 0 90.848-1.912 133.623-3.738 24.068-1.027 36.102-1.54 44.474 2.68 7.59 3.826 13.101 9.576 16.601 17.32 3.861 8.544 2.811 20.859.71 45.488-1.965 23.045-3.561 48.107-3.561 68.75 0 22.359.624 49.904 1.353 74.454.658 22.176.988 33.264-3.049 41.357-3.592 7.205-9.254 12.806-16.497 16.322-8.137 3.949-19.16 3.503-41.208 2.612-43.302-1.751-98.838-3.689-132.446-3.689-34.662 0-95.036 2.062-140.943 3.853-21.693.846-32.539 1.269-40.625-2.636-7.175-3.465-12.856-9.039-16.457-16.146-4.06-8.011-3.842-18.886-3.409-40.635.495-24.809.923-52.817.923-75.492 0-20.711-1.606-45.872-3.58-68.98z"
          ></path>
        </clipPath>
      </defs>
      {imageUrl ? (
        <image
          xlinkHref={imageUrl}
          width={"100%"}
          height={"100%"}
          clipPath="url(#customShape)"
          preserveAspectRatio="xMidYMid slice"
        />
      ) : (
        <path
          fill="#ECF7FF"
          d="M7.24 68.52C5.143 43.973 4.094 31.7 7.947 23.16c3.488-7.732 9-13.493 16.57-17.32 8.36-4.227 20.341-3.736 44.303-2.752C114.533 4.964 172.978 7 211.332 7c37.172 0 90.848-1.912 133.623-3.738 24.068-1.027 36.102-1.54 44.474 2.68 7.59 3.826 13.101 9.576 16.601 17.32 3.861 8.544 2.811 20.859.71 45.488-1.965 23.045-3.561 48.107-3.561 68.75 0 22.359.624 49.904 1.353 74.454.658 22.176.988 33.264-3.049 41.357-3.592 7.205-9.254 12.806-16.497 16.322-8.137 3.949-19.16 3.503-41.208 2.612-43.302-1.751-98.838-3.689-132.446-3.689-34.662 0-95.036 2.062-140.943 3.853-21.693.846-32.539 1.269-40.625-2.636-7.175-3.465-12.856-9.039-16.457-16.146-4.06-8.011-3.842-18.886-3.409-40.635.495-24.809.923-52.817.923-75.492 0-20.711-1.606-45.872-3.58-68.98z"
        ></path>
      )}
    </svg>
  );
};
