import React, { useEffect, useState } from "react";

import "./index.styles.scss";
import { useTranslation } from "react-i18next";
import NormalArrowRightIcon from "assets/svg/NormalArrowRightIcon";
import { RootNames } from "shared/routes/rootUrl";
import { useNavigate } from "react-router-dom";
import { GsapText } from "./gsapText";

export const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let publicExtensionDetected: any = null;
  const [extensionDetected, setExtensionDetected] = useState<null | boolean>(
    null
  ); // using null to represent an "unknown" initial state
  // const [fontSize, setFontSize] = useState('64px');
  // useEffect(() => {
  //   const handleResize = () => {
  //     const width = window.innerWidth;
  //     if (width < 900) {
  //       setFontSize('25px');
  //     } else if (width < 1000) {
  //       setFontSize('30px');
  //     } else if (width < 1100) {
  //       setFontSize('34px');
  //     } else if (width < 1200) {
  //       setFontSize('38px');
  //     } else if (width < 1370) {
  //       setFontSize('41px');
  //     } else if (width < 1450) {
  //       setFontSize('46px');
  //     } else if (width < 1600) {
  //       setFontSize('50px');
  //     } else if (width < 1800) {
  //       setFontSize('57px');
  //     } else {
  //       setFontSize('64px');
  //     }
  //   };

  //   window.addEventListener('resize', handleResize);
  //   handleResize();

  //   return () => window.removeEventListener('resize', handleResize);
  // }, []);

  useEffect(() => {
    let attempts = 0;
    const handleExtensionConfirm = () => {
      console.log("EXTENSION INSTALLED");
      setExtensionDetected(true);
      publicExtensionDetected = true;
    };

    // Set up the listener for the confirmation from the content script
    window.addEventListener(
      "extensionInstalledConfirm",
      handleExtensionConfirm
    );

    function checkForExtension() {
      if (publicExtensionDetected === null && attempts < 2) {
        // limited to 3 attempts
        console.log("Dispatching checkExtensionInstalled event");
        window.dispatchEvent(new CustomEvent("checkExtensionInstalled"));

        attempts++;
        setTimeout(checkForExtension, 1000 * attempts);
      } else if (publicExtensionDetected === null) {
        console.log("Extension not detected after 3 attempts");
        setExtensionDetected(false);
      }
    }

    checkForExtension();

    // Clean up the listener when the component is unmounted
    return () => {
      window.removeEventListener(
        "extensionInstalledConfirm",
        handleExtensionConfirm
      );
    };
  }, [extensionDetected]);

  const onLogin = () => {
    if(extensionDetected)
      navigate(RootNames.login_with_phone);
    else {
      navigate(RootNames.noExtension);
    }
  };

  const onReadTerm = () => {
    window.open(process.env.REACT_APP_TERMS, "_blank");
  };

  const onReadPrivacy = () => {
    window.open(process.env.REACT_APP_PRIVACY, "_blank");
  };

  const LoginButton = () => {
    return (
      <div className="login_button" onClick={onLogin}>
        <img src={require("assets/image/login_btn.png")} alt="login button" style={{height: '30px', marginRight: '8px'}}/>
        <span className="font14" style={{color: '#FFC120', marginRight: '6px', lineHeight: '20px'}}>{t("get_started_login")}</span>
        <NormalArrowRightIcon color={'#FFC120'}/>
      </div>
    );
  }
  return (
    <div className="dashboard_container">
      <div style={{width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'sticky', top: 0, backgroundColor: 'black', paddingLeft: '100px', paddingRight: '100px', zIndex: 100}}>
        {LoginButton()}
        <img src={process.env.REACT_APP_LOGO ?? ""} className="logo" alt="logo" />
      </div>
      <div className="first_container">
        <div style={{width: '100%', display: "flex" }}>
          <div className="left">
            <div style={{position: 'relative', marginBottom: '25px'}}>
              <div
                className="font-rubik font64" style={{ color: "white"}}
                dangerouslySetInnerHTML={{ __html: t("dashboard_panel_title1", { name: process.env.REACT_APP_APP_NAME }) }}
              ></div>
              <img src={require("assets/image/db_marker.png")} alt="marker" style={{height: '28px', position: 'absolute', right: 0, bottom: -16}}/>
            </div>
            <div
              className="font18" style={{lineHeight: '27px', color: '#FFFFFFB3'}}
              dangerouslySetInnerHTML={{ __html: t("dashboard_panel_desc1", { name: process.env.REACT_APP_APP_NAME }) }}
            ></div>
            <div style={{display: 'flex', gap: '15px', margin: '25px 0'}}>
              <img src={require("assets/image/db_appstore.png")} alt="appstore" style={{height: '56px'}} />
              <img src={require("assets/image/db_googleplay.png")} alt="googleplay" style={{height: '56px'}} />
            </div>
          </div>
          <div className="justify_center" style={{width: '60%'}}>
            <img style={{width: '70%', objectFit: 'contain'}} src={require("assets/image/db_poster1.png")} alt="postter2" />
          </div>
        </div>
      </div>
      <div className="second_container">
        <GsapText bgColor="" textColor="black" />
        <div className="content flex_center">
          <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
            <p className="font-rubik font64">{t("dashboard_panel_title2")}</p>
            <p className="font18" style={{lineHeight: '27px'}}>{t("dashboard_panel_desc2", { name: process.env.REACT_APP_APP_NAME })}</p>
          </div>
          <div className="justify_center" style={{width: '60%'}}>
            <img style={{width: '70%'}} src={require("assets/image/db_poster2.png")} alt="postter2" />
          </div>
        </div>
      </div>
      <div className="third_container">
        <GsapText bgColor="#EA21A2" textColor="white" />
        <div className="content flex_center">
          <div className="justify_center" style={{width: '60%'}}>
            <img style={{width: '60%'}} src={require("assets/image/db_poster3.png")} alt="postter3" />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', width: '40%'}}>
            <p className="font-rubik font64">{t("dashboard_panel_title3")}</p>
            <p className="font20" style={{lineHeight: '27px'}}>{t("dashboard_panel_desc3", { name: process.env.REACT_APP_APP_NAME })}</p>
            <p className="font20" style={{lineHeight: '27px', paddingTop: '20px'}}>Available on</p>
            <img style={{height: '40px', objectFit: 'contain', alignSelf: 'flex-start'}} src={require("assets/image/db_poster3_social.png")} alt="postter33" />
          </div>
        </div>
      </div>
      <div className="fourth_container">
        <GsapText bgColor="black" textColor="white" />
        <div className="content flex_center">
          <div className="justify_center" style={{width: '60%'}}>
            <img style={{width: '60%', height: '465px', objectFit: 'contain'}} src={require("assets/image/db_poster4.png")} alt="postter4" />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', width: '40%'}}>
            <p className="font-rubik font64">{t("dashboard_panel_title4")}</p>
            <p className="font20" style={{lineHeight: '27px'}}>{t("dashboard_panel_desc4", { name: process.env.REACT_APP_APP_NAME })}</p>

            <div style={{display: 'flex', alignItems: 'center', paddingTop: '20px'}}>
              <img style={{height: '40px', objectFit: 'contain', alignSelf: 'flex-start'}} src={require("assets/image/db_poster4_avatars.png")} alt="postter44" />
              {/* <p className="font14" style={{marginBottom: '0px', paddingLeft: '15px'}}>Over 1k happy users</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="fifth_container">
        <GsapText bgColor="#FFC120" textColor="black" />
        <div className="content flex_center">
          <div className="justify_center" style={{width: '60%'}}>
            <img style={{width: '60%', objectFit: 'contain'}} src={require("assets/image/db_poster5.png")} alt="postter4" />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', width: '40%'}}>
            <p className="font-rubik font64">{t("dashboard_panel_title5")}</p>
            <p className="font20" style={{lineHeight: '27px'}}>{t("dashboard_panel_desc5", { name: process.env.REACT_APP_APP_NAME })}</p>
            <div style={{display: 'flex', alignItems: 'center', paddingTop: '20px'}}>
              <img style={{height: '40px', objectFit: 'contain', alignSelf: 'flex-start'}} src={require("assets/image/db_poster4_avatars.png")} alt="postter55" />
              {/* <p className="font14" style={{marginBottom: '0px', paddingLeft: '15px'}}>Over 1k happy users</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="sixth_container">
        <GsapText bgColor="#EA21A2" textColor="white" />
        <div className="content flex_center" style={{width: '100%', gap: '120px'}}>
          <div style={{width: '380px'}}>
            <img style={{width: '100%', objectFit: 'contain'}} src={require("assets/image/db_poster6_1.png")} alt="postter61" />
            <p className="font-rubik font32 text_center">{t("dashboard_panel_desc6_left_title", { name: process.env.REACT_APP_APP_NAME })}</p>
            <p className="font20 text_center">{t("dashboard_panel_desc6_left_desc")}</p>
          </div>
          <div style={{width: '380px'}}>
            <img style={{width: '100%', objectFit: 'contain', alignSelf: 'flex-end'}} src={require("assets/image/db_poster6_2.png")} alt="postter62" />
            <p className="font-rubik font32 text_center">{t("dashboard_panel_desc6_right_title")}</p>
            <p className="font20 text_center">{t("dashboard_panel_desc6_right_desc", { name: process.env.REACT_APP_APP_NAME })}</p>
          </div>
        </div>
      </div>
      <div className="seventh_container">
        <GsapText bgColor="black" textColor="white" />
        <div className="content flex_center">
          <div className="justify_center" style={{width: '60%'}}>
            <img style={{width: '50%', objectFit: 'contain'}} src={require("assets/image/db_poster7.png")} alt="postter7" />
          </div>
          <div style={{display: 'flex', flexDirection: 'column', width: '40%'}}>
            <p className="font-rubik font64" style={{color: 'white'}}>{t("dashboard_panel_title7")}</p>
            <p className="font18" style={{lineHeight: '27px', paddingTop: '10px', color: 'white'}}>{t("dashboard_panel_desc7", { name: process.env.REACT_APP_APP_NAME })}</p>
          </div>
        </div>
        <div className="font16" style={{color: 'white', paddingBottom: '35px', marginTop: '-15px' }}>
          <span>{t("connect_terms_privacy")}</span>
          <span onClick={onReadTerm} style={{ fontWeight: "bold", cursor: 'pointer' }}>{t("terms_conditions")}</span>
          <span>{t("_and_")} </span>
          <span onClick={onReadPrivacy} style={{ fontWeight: "bold", cursor: 'pointer' }}>{t("privacy_policy")}</span>
        </div>
      </div>
    </div>
  );
};