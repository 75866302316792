import React from "react";

import "./GenericAvatar.styles.scss";
import { GenericAvatarProps } from "shared/utils/type.def";
import DefaultAvatarIcon from "assets/svg/DefaultAvatarIcon";
import { useTranslation } from "react-i18next";

const GenericAvatar = (props: GenericAvatarProps) => {
  const { t } = useTranslation();

  const renderImage = () => {
    if (props.image && props.image.startsWith("https")) {
      return <img src={props.image} alt="jims" className="avatar-image" />;
    } else {
      return <DefaultAvatarIcon size={56} />;
    }
  };

  return (
    <div className="avatar-wrapper">
      <div className="avatar_info">
        <div className="font-medium font16">{props.name}</div>
        <div className="font-medium font14" style={{ opacity: 0.6 }}>
          {t("level_display", { level: props.level })}
        </div>
      </div>

      {renderImage()}
    </div>
  );
};

export default GenericAvatar;
