import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const UserIcon = (props: IconProps) => {
    return (
        <svg className={props.className} width={props.width ?? 19} height={props.height ?? 22} viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.58984 11C12.3513 11 14.5898 8.76142 14.5898 5.99999C14.5898 3.23857 12.3513 1 9.58984 1C6.82842 1 4.58984 3.23857 4.58984 5.99999C4.58984 8.76142 6.82842 11 9.58984 11Z" stroke={props.color ?? "#000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M18.18 21C18.18 17.13 14.33 14 9.58999 14C4.84999 14 1 17.13 1 21" stroke={props.color ?? "#000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};