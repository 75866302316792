import React, { createContext, useContext, useEffect, useState } from 'react';
import { getToken } from 'shared/api/storageHelper';
import { io, Socket } from 'socket.io-client';

const SOCKET_SERVER_URL = process.env.REACT_APP_SERVER_URL ?? '';

interface SocketContextProps {
  socket: Socket | null;
}
// Create context
const SocketContext = createContext<SocketContextProps | null>(null);

type SocketContextProviderProps = {
  children: any;
};

// Create Provider component
export const SocketProvider: React.FC<SocketContextProviderProps> = ({ children }) => {
  const [socket, setSocket] = useState<Socket | null>(null);

  useEffect(() => {
    if (!socket) {
      connectSocket();
    }
    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [])
  

  const connectSocket = async () => {
    const token = await getToken();
    
    const newSocket: Socket = io(SOCKET_SERVER_URL, {
      query: { token: token }
    });

    newSocket.on('connect', () => {
      console.log('Socket connected');
    });

    newSocket.on('disconnect', () => {
      console.log('Socket disconnected');
    });

    setSocket(newSocket);
  };

  return <SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>;
};


// Custom Hook
export const useSocket = () => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket must be used within a SocketProvider');
  }

  return context.socket;
};
