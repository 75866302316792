import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";

import "./index.styles.scss";
import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import CloseIcon from "assets/svg/CloseIcon";
import { EmailIcon } from "assets/svg/EmailIcon";
import CopyIcon from "assets/svg/CopyIcon";
import { useDispatch } from "react-redux";
import { validateEmail } from "shared/utils/utils";
import { apiGetInviteLink, apiInviteByEmail } from "shared/api/util";
import { showToast } from "components/Common/PrimaryToast";
import { LoadingCircular } from "components/Common/LoadingCircular";
import WhatsappIcon from "assets/svg/socials/WhatsappIcon";
import TelegramIcon from "assets/svg/socials/TelegramIcon";

import { TelegramShareButton, WhatsappShareButton } from "react-share";
import { useTranslation } from "react-i18next";

export const SendInvitationModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [inviteLink, setInviteLink] = useState("");

  useEffect(() => {
    getInviteLink();
  }, []);

  const getInviteLink = async () => {
    const res = await apiGetInviteLink({});
    if (res.success) {
      setInviteLink(res.link);
    }
  };

  const onSend = async () => {
    if (!isSending && !!email && validateEmail(email)) {
      setIsSending(true);

      const res = await apiInviteByEmail({ email });

      if (res.success) {
        onHide();
        dispatch(appAction.toogleInvitationSuccessModal(true));
      } else {
        showToast("error", res.error);
      }

      setIsSending(false);
    } else if (!validateEmail(email)) {
      showToast("error", t("msg_invalid_email"));
    }
  };

  const onCopy = () => {
    showToast("success", t("msg_invitation_link_copy_success"));
    navigator.clipboard.writeText(inviteLink);
  };

  const onHide = () => {
    dispatch(appAction.toogleInvitationModal(false));
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items" style={{ justifyContent: "flex-end" }}>
        <div className="close-btn clickable" onClick={onHide}>
          <CloseIcon />
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="content-body">
        <div className="font-bold font16">{t("send_email_invitation")}</div>
        {renderEmailBody()}
        <div className="divider"></div>
        {renderSocialShare()}
      </div>
    );
  };

  const renderEmailBody = () => {
    return (
      <div className="input-container row_align_items">
        <div className="clickable">
          <EmailIcon />
        </div>
        <div style={{ flex: 1, display: "flex", alignItems: "flex-start" }}>
          <input
            value={email}
            className="border_less_input input-box font-regular font14"
            placeholder="Email Address*"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="send-btn clickable" onClick={onSend}>
          {isSending ? (
            <LoadingCircular size={16} color="main" />
          ) : (
            <div className="btn-txt font-regular font16">{t("send")}</div>
          )}
        </div>
      </div>
    );
  };

  const renderSocialShare = () => {
    return (
      <div className="social_share">
        <div className="social_item" onClick={onCopy}>
          <div className="copy_item clickable">
            <div className="clickable">
              <CopyIcon size={20} />
            </div>
          </div>
          <div className="btn-txt font-regular font12">{t("copy")}</div>
        </div>
        <WhatsappShareButton title="" url={inviteLink}>
          <div className="social_item">
            <div className="whatsapp_item clickable">
              <div className="clickable">
                <WhatsappIcon color="white" size={20} />
              </div>
            </div>
            <div className="btn-txt font-regular font12">Whatsapp</div>
          </div>
        </WhatsappShareButton>
        <TelegramShareButton title="" url={inviteLink}>
          <div className="social_item">
            <div className="telegram_item clickable">
              <div className="clickable">
                <TelegramIcon />
              </div>
            </div>
            <div className="btn-txt font-regular font12">Telegram</div>
          </div>
        </TelegramShareButton>
      </div>
    );
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_send_invitation">
        <div
          className="modal_body"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {renderHeader()}
          {renderBody()}
        </div>
      </div>
    </OpacityModal>
  );
};
