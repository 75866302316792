import React, { useEffect, useRef } from "react";

import "./index.styles.scss";
import { gsap } from "gsap";

interface Props {
  textColor: string;
  bgColor: string;
}

export const GsapText = ({textColor, bgColor}: Props) => {
  const firstText = useRef<HTMLParagraphElement>(null);
  const secondText = useRef<HTMLParagraphElement>(null);
  const slider = useRef<HTMLDivElement>(null);
  let xPercent = 0;
  useEffect( () => {
    if(secondText.current != null)
    {
      gsap.set(firstText.current, {left:  secondText.current.getBoundingClientRect().width})
      requestAnimationFrame(animate);
    }
  }, []);

  const animate = () => {
    if(xPercent < -50){
      xPercent = 0;
    }
    gsap.set(firstText.current, {xPercent: xPercent})
    gsap.set(secondText.current, {xPercent: xPercent})
    requestAnimationFrame(animate);
    xPercent -= 0.025;
  }

  return (
    <div ref={slider} style={{backgroundColor: bgColor, display: 'flex', width: 'max-content', justifyContent: 'center', alignItems: 'center'}}>
      <p className="font-rubik font64 marginPadding10" style={{marginBottom: 0, wordBreak: 'break-all', color: textColor}} ref={firstText}>
        VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE &nbsp;
      </p>
      <p className="font-rubik font64 marginPadding10" style={{marginBottom: 0, wordBreak: 'break-all', color: textColor}} ref={secondText}>
        VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE VIBE
      </p>
    </div>
  );
};