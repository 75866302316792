import React, { CSSProperties } from "react";

export const DeviceSize = {
  mobile: 768,
  tablet: 992,
  laptop: 1324,
  desktop: 2024,
};

export interface IconProps {
  width?: number;
  height?: number;
  color?: string;
  className?: string;
  style?: CSSProperties;
  image?: string;
  isTrend?: boolean;
}

export interface RoundedIconButtonProps {
  size?: string;
  color?: string;
  rounded?: string;
  isLoading?: boolean;
  children: React.ReactNode;
  style?: CSSProperties;
  syncColor?: boolean;
  onClick?: () => void;
}

export interface SpaceProps {
  width?: string;
  height?: string;
}

export interface GenericAvatarProps {
  image?: string;
  name?: string;
  level?: any;
}

export interface MenuButtonProps {
  active: boolean;
  isMainActive?: boolean;
  icon: React.ReactNode;
  title: string;
  color?: string;
  activeColor?: string;
  syncColor?: boolean;
  onClick?: React.ReactEventHandler;
}

export interface TopicLocalItemProps {
  topic: number;
  name: string;
  level: number;
  status: number;
  score: number;
  image?: string;
  timestamp: string;
}

export interface TopicNormalItemProps {
  id: number;
  is_liked: number;
  is_hidden: number;
  is_trend?: number;
  is_validated: number;
  level: number;
  topic: number;
  topic_name: string;
  threshold: number;
  threshold_type: string;
  images?: string;
  topic_image?: string;
  timestamp: string;
  user: number;
  subtopic_total?: number;
}

export interface VibeItemProps {
  creator_id: number;
  creator_image: string;
  creator_name: string;
  creator_type: string;
  vibes_score: number;
  vibes_type: string;
}

export interface MoodItemProps {
  id: number;
  images?: string;
  item_id: number;
  item_type: string;
  text: string;
  timestamp: string;
  topic_name: string;
  topic_description: string;
}
export interface OpacityModalProps {
  children: React.ReactNode;
  onClick?: () => void;
}

export interface AvatarBtnProps {
  avatar: String;
  title: String;
  name: String;
  date: String;
  percent: String;
  onClick?: (event: any) => void;
}

export interface InvitationItemProps {
  id: number;
  email: string;
  creator_name?: string;
  creator_id?: any;
  is_joined: number;
  vibes_score?: any;
  vibes_type?: any;
}
