import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig } from "axios";
import { getToken, removeCookie } from "./storageHelper";

class HttpClient {
  httpClient: AxiosInstance;

  constructor(baseURL: string) {
    const httpClient = axios.create({
      baseURL,
    });
    this.httpClient = httpClient;
  }

  post(apiEndpoint: string, params: any, config?: any) {
    try {
      return this.httpClient.post(apiEndpoint, params, config);
    } catch (error) {
      throw error;
    }
  }

  get(apiEndpoint: string, params: any = {}) {
    return this.httpClient.get(apiEndpoint, { params });
  }

  async getWithToken(apiEndpoint: string, params: any = {}) {
    this.httpClient.interceptors.request.use(async (request) => {
      const token = await getToken();

      request!.headers = { ...request!.headers } as AxiosHeaders;
      request!.headers = {
        Authorization: `Bearer ${token}`,
      };

      return request;
    });

    try {
      const response = await this.httpClient.get(apiEndpoint, { params });
      return response;
    } catch (error: any) {
      if (error.response.status === 403) {
        await removeCookie();

        return {
          data: {
            success: false,
            code: 403,
            data: null,
          },
        };
      }
    }
  }

  async postWithToken(apiEndpoint: string, params = {}, config = {}) {
    this.httpClient.interceptors.request.use(async (request) => {
      const token = await getToken();

      request!.headers = { ...request!.headers } as AxiosHeaders;
      request!.headers = {
        Authorization: `Bearer ${token}`,
      };

      return request;
    });

    try {
      const response = await this.httpClient.post(apiEndpoint, params, config);
      return response;
    } catch (error: any) {
      if (error.response.status === 403) {
        await removeCookie();

        return {
          data: {
            success: false,
            code: 403,
            data: null,
          },
        };
      }
    }
  }

  async postWithAuth0Token(
    apiEndpoint: string,
    params = { idToken: "" },
    config = {}
  ) {
    this.httpClient.interceptors.request.use(async (request) => {
      const token = params?.idToken;
      request!.headers = { ...request!.headers } as AxiosHeaders;
      request!.headers = {
        Authorization: `Bearer ${token}`,
      };

      return request;
    });

    try {
      const response = await this.httpClient.post(apiEndpoint, params, config);
      return response;
    } catch (error: any) {
      if (error.response.status === 403) {
        return {
          data: {
            success: false,
            code: 403,
            data: null,
          },
        };
      }
    }
  }
}

export default new HttpClient(
  process.env.REACT_APP_SERVER_URL ?? "http://localhost:3000"
);
