/* global chrome */

import React, { useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";

import "./index.styles.scss";
import { CardNormalTopic } from "./CardNormalTopic";
import { Grid } from "@mui/material";
import {
  TopicLocalItemProps,
  TopicNormalItemProps,
} from "shared/utils/type.def";
import { RootState } from "redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { useInterestData } from "shared/hook/fetchData";
import { CardLocalTopic } from "./CardLocalTopic";
import { EXTENSION_ID, SAFARI_EXTENSION_ID } from "App";

import { apiPublishTopic } from "shared/api/extensions";
import { showToast } from "components/Common/PrimaryToast";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { CardHiddenTopic } from "./CardHiddenTopic";
import { CardNotEnoughTopic } from "./CardNotEnoughTopic";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { CardNotValTopic } from "./CardNotValTopic";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RootNames } from "shared/routes/rootUrl";
import { CommonDropdown } from "components/Common/CommonDropdown";

interface Props {
  selectedFilter: any;
  arrBeData: any;
  isFetchedBeTopic: boolean;
  isInitialLoading: boolean;
  refetchBeTopics: () => void;
  onUpdatedFilter: (newFilter?: any) => void;
}

export const ExploreTopics = ({
  selectedFilter,
  arrBeData,
  isInitialLoading,
  isFetchedBeTopic,
  onUpdatedFilter,
  refetchBeTopics,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const arrFilters = [
    { value: "all", label: t("all") },
    { value: "new", label: t("new") },
    { value: "published", label: t("published") },
    { value: "hidden", label: t("hidden") },
  ];

  const queryClient = useQueryClient();
  const { userData, topicScrollPos } = useSelector(
    (state: RootState) => state.app
  );
  const [arrLocalTopic, setArrTopic] = useState<Array<TopicLocalItemProps>>([]);
  const [arrLocalInterest, setArrLocalInterest] = useState<Array<any>>([]);
  const [arrTotalInterest, setArrTotalInterest] = useState<Array<any>>([]);
  const [arrHidden, setArrHidden] = useState<Array<number>>([]);

  useEffect(() => {
    console.log('first')
    loadExtensionTopics();
  }, []);

  const sendMessageToExtension = (type, data, callback) => {
    // Determine the browser and set the correct extension ID
    let extensionId;
    if (typeof browser !== "undefined") {
      extensionId = SAFARI_EXTENSION_ID; // Assuming 'browser' namespace implies Safari or similar
    } else if (typeof chrome !== "undefined") {
      // Chrome
      extensionId = EXTENSION_ID;
    } else { //Firefox
      console.error("Unsupported browser for messaging, switching to CustomEvent");
      var event = new CustomEvent("getData");
      window.dispatchEvent(event);
      return;
    }

    const runtime =
      typeof browser !== "undefined" ? browser.runtime : chrome.runtime;

    // Send message to the extension
    if (runtime.sendMessage) {
      runtime.sendMessage(extensionId, { type, data }, callback);
    } else {
      console.error("Browser does not support the `sendMessage` method.");
    }
  };

  window.addEventListener("getDataResponse", function(event) {
    const dataStr = (event as CustomEvent<string>).detail;
    try {
      const data = JSON.parse(dataStr);
      console.log("Received response from extension:", data);
      if (data) {
        setArrTopic(data ?? []);
        refetchBeTopics();
      }
    } catch (error) {
      console.error("Error parsing data from extension:", error);
    }
  });

  const loadExtensionTopics = () => {
    try {
      sendMessageToExtension("getData", null, (response) => {
        if (response && response.data) {
          setArrTopic(response.data ?? []);
          refetchBeTopics();
        }
      });
    } catch (error) {
      console.log("extension_get_data_error", { error });
    }
  };

  const onConnectSocial = () => {
    navigate(RootNames.settings);
  };

  const onRemoveLocalItem = (topicId: any) => {
    // remove topic on UI
    setArrTopic((prev) => prev.filter((item, index) => item.topic !== topicId));
  };

  const onPublish = async (topicData: TopicLocalItemProps) => {
    const res = await apiPublishTopic({
      topic: topicData.topic,
      score: topicData.score,
    });

    if (res.success) {
      showToast("success", t("msg_topic_published"));
      // remove topic on local section
      setArrTopic((prev) =>
        prev.filter((item, index) => item.topic !== topicData.topic)
      );

      refetchBeTopics();
    }
  };

  const onUpdateValidate = (topicId: any, isPublished?: boolean) => {
    queryClient.setQueryData(["interests", userData?.id, null], (oldData) => {
      if (!oldData) {
        setArrLocalInterest((prev) =>
          prev.filter((item, index) => item.topic !== topicId)
        );

        refetchBeTopics();
        
        return null; 
      } // Return null if no data is available.

      // Clone the original data to avoid mutation.
      const newData = JSON.parse(JSON.stringify(oldData));

      // Find the index of the item with the provided itemId.
      const itemIndex = newData.pages
        .flatMap((page) => page.data)
        .findIndex((item) => item.id === topicId);

      if (itemIndex !== -1) {
        // Get the reference to the item to update
        const itemToUpdate = newData.pages.flatMap((page) => page.data)[
          itemIndex
        ];

        // Update the item with the new property
        itemToUpdate.is_validated = 1;

        if (isPublished) {
          itemToUpdate.is_hidden = true;
        }

        return newData;
      }

      // If the item is not found, return the original data unchanged.
      return oldData;
    });
  };

  
  const onUpdateHideStatus = (topicId: any, isHidden: number) => {
    queryClient.setQueryData(["interests", userData?.id, null], (oldData) => {
      if (!oldData) {
        // let arr = [...arrTotalInterest];
        // const itemIndex = arr.findIndex((item) => item.id === topicId);
        console.log("old hidden? ", topicId, isHidden);
        // if (itemIndex !== -1) {
        //   if (arr[itemIndex]) {
        //     arr[itemIndex].is_hidden = isHidden;
        //   }
        //   setArrTotalInterest(arr);
        //   setArrHidden((prev) => [...new Set(prev.concat(topicId))]);
        // }
        setArrHidden((prev) => [...new Set(prev.concat(topicId))]);
        return null; // Return null if no data is available.
      }

      // Clone the original data to avoid mutation.
      const newData = JSON.parse(JSON.stringify(oldData));

      // Find the index of the item with the provided itemId.
      const itemIndex = newData.pages
        .flatMap((page) => page.data)
        .findIndex((item) => item.id === topicId);

      if (itemIndex !== -1) {
        // Get the reference to the item to update
        const itemToUpdate = newData.pages.flatMap((page) => page.data)[
          itemIndex
        ];
        itemToUpdate.is_hidden = isHidden;

        return newData;
      }

      // If the item is not found, return the original data unchanged.
      return oldData;
    });

    if (selectedFilter === "published" && isHidden) {
      onUpdatedFilter("hidden");
    }

    if (selectedFilter === "hidden" && !isHidden) {
      onUpdatedFilter("published");
    }
  };

  let _arrBeTopics = arrBeData
    ? arrBeData.pages.flatMap((page) => page?.data)
    : [];
  const result: any[] = [];
  for (const item of _arrBeTopics) {        
    if (arrHidden.includes(item.id)) {
      let checkArr = _arrBeTopics.filter((val, i) => { return val.id == item.id});
        if (checkArr.length > 1) {
          console.log('finded')
        }
        else {
          result.push(item);
        }
    } else {
        result.push(item);
    }
  }
  result.map((item) => {
    if(arrHidden.length > 0)
      if(arrHidden.includes(item.id))
      {
        console.log('>>> included: ', item.id);
      }
  })
  let arrBeTopics = result;

  useEffect(() => {
    console.log(arrHidden);
  }, [arrHidden])
  
  useEffect(() => {
    console.log('isFetchedBeTopic ? ', isFetchedBeTopic)
    if(isFetchedBeTopic) {
      // let arrInterest = (arrBeData
      //   ? arrBeData.pages.flatMap((page) => page.data)
      //   : []);
      // const result: any[] = [];
      // for (const item of arrInterest) {        
      //   if (arrHidden.includes(item.id)) {
      //     let checkArr = arrInterest.filter((val, i) => { return val.id == item.id});
      //       if (checkArr.length > 1) {
      //       }
      //       else {
      //         result.push(item);
      //       }
      //   } else {
      //       result.push(item);
      //   }
      // }
      // result.map((item) => {
      //   if(arrHidden.length > 0)
      //     if(arrHidden.includes(item.id))
      //     {
      //       console.log('included: ', item.id);
      //     }
      // })
      // console.log(arrInterest.length)
      // setArrTotalInterest(arrInterest);
      
    }
  }, [isFetchedBeTopic])
  

  const renderBeTopicItem = (item, index) => {
    if (
      (item.id === "" || !item.id) &&
      item.is_hidden === 0 &&
      item.is_hidden_own !== 1
    ) {
      return (
        <CardNotEnoughTopic
          key={`explore_card_be_topic_${index}`}
          data={item}
        />
      );
    }

    if (item.is_hidden === 1 && item.user !== userData?.id) {
      return <CardHiddenTopic key={`explore_card_be_topic_${index}`} />;
    }

    if (item.is_validated === 0) {
      return (
        <CardNotValTopic
          key={`card_profile_interest_${index}`}
          data={item}
          onUpdateValidate={onUpdateValidate}
        />
      );
    }

    return (
      <CardNormalTopic
        data={item}
        key={`explore_card_be_topic_${index}`}
        index={index}
        onUpdateHideStatus={onUpdateHideStatus}
      />
    );
  };

  const renderAllEmptyView = () => {
    return (
      <div className="empty_container">
        <img
          src={require("assets/gif/empty_content.gif")}
          className="empty_image"
        />
        <div className="font-bold font24">{t("generating_interest")}</div>
        <div
          className="font-regular font16"
          style={{
            color: "rgba(0, 0, 0, 0.6)",
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          {t("keep_browsing")}
          <br />
          <div style={{ whiteSpace: "pre-line" }}>
            {t("to_get_more_accurate")}
          </div>

          <div style={{ whiteSpace: "pre-line" }}>
            {t("interests_locally_encrypted")}
          </div>

          <br />
          {t("set_autopublish")}
        </div>
        <div
          className="connect_btn font-bold font16 clickable"
          onClick={onConnectSocial}
        >
          {t("connect_socials")}
        </div>
      </div>
    );
  };

  const renderNewEmptyView = () => {
    return (
      <div className="empty_container">
        <img
          src={require("assets/gif/empty_content.gif")}
          className="empty_image"
        />
        <div className="font-bold font24">{t("no_new_interest")}</div>
        <div
          className="font-regular font16"
          style={{
            color: "rgba(0, 0, 0, 0.6)",
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          {t("keep_browsing")}
          <br />
          {t("to_get_more_accurate")}
          <br />
          {t("interests_locally_encrypted")}
          <br />
          {t("set_autopublish")}
        </div>
      </div>
    );
  };

  const renderPublishedEmptyView = () => {
    return (
      <div className="empty_container">
        <img
          src={require("assets/gif/empty_content.gif")}
          className="empty_image"
        />
        <div className="font-bold font24">{t("no_published_interest")}</div>
        <div
          className="font-regular font16"
          style={{
            color: "rgba(0, 0, 0, 0.6)",
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          {t("keep_browsing")}
          <br />
          {t("to_get_more_accurate")}
          <br />
          {t("interests_locally_encrypted")}
          <br />
          {t("set_autopublish")}
        </div>
      </div>
    );
  };

  const renderHiddenEmptyView = () => {
    return (
      <div className="empty_container">
        <img
          src={require("assets/gif/empty_content.gif")}
          className="empty_image"
        />
        <div className="font-bold font24">{t("no_hidden_interest")}</div>
        <div
          className="font-regular font16"
          style={{
            color: "rgba(0, 0, 0, 0.6)",
            maxWidth: 400,
            textAlign: "center",
          }}
        >
          {t("hide_interest_puiblic_profile")}
        </div>
      </div>
    );
  };

  if (isInitialLoading) {
    return (
      <div className="explore_topic">
        <RectangleLoadingAnimation />
      </div>
    );
  } else if (
    isFetchedBeTopic &&
    arrLocalTopic.length == 0 &&
    arrBeTopics.length == 0
  ) {
    return (
      <div
        className="explore_topic center"
        style={{ flexDirection: "column", gap: 8 }}
      >
        <div
          className="row_align_items row_space_between"
          style={{ alignItems: "center", marginBottom: 16 }}
        >
          <div className="topic_title font-adversal font24">
            {t("my_interests")}
          </div>
          <CommonDropdown
            options={arrFilters}
            defaultOption={selectedFilter}
            onSelected={onUpdatedFilter}
          />
        </div>
        {selectedFilter === "all"
          ? renderAllEmptyView()
          : selectedFilter === "new"
          ? renderNewEmptyView()
          : selectedFilter === "published"
          ? renderPublishedEmptyView()
          : renderHiddenEmptyView()}
      </div>
    );
  } else {
    return (
      <div className="explore_topic">
        <div
          className="row_align_items row_space_between"
          style={{ alignItems: "center", marginBottom: 16 }}
        >
          <div className="topic_title font-adversal font24">
            {t("my_interests")}
          </div>
          <CommonDropdown
            options={arrFilters}
            defaultOption={selectedFilter}
            onSelected={onUpdatedFilter}
          />
        </div>
        <Grid container spacing={2}>
          {(selectedFilter === "all" || selectedFilter === "new") &&
            arrLocalTopic.map((item, index) => {
              return (
                <CardLocalTopic
                  data={item}
                  onRemove={onRemoveLocalItem}
                  onPublish={onPublish}
                  key={`explore_card_local_topic_${index}`}
                />
              );
            })}
          {arrLocalInterest.concat(arrBeTopics).map((item, index) => {
            return renderBeTopicItem(item, index);
          })}
          {/* {arrBeTopics.map((item, index) => {
            return renderBeTopicItem(item, index);
          })} */}
        </Grid>
      </div>
    );
  }
};
