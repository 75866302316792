import React, { useMemo } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./index.styles.scss";
import { useNavigate } from "react-router-dom";
const MAX_TAG_LETTER_COUNT = 30;
interface Props {
  data: any;
}

export const FirstTag = ({ data }: Props) => {
  const navigate = useNavigate();

  const onDetail = (e) => {
    e.stopPropagation();
    navigate(`/subInterestDetail/${data.id}`);
  };

  const isLongTag = useMemo(() => {
    if (data.name.length > MAX_TAG_LETTER_COUNT) {
      return true;
    } else {
      return false;
    }
  }, [data]);

  return (
    <div
      className="tag_item first_tag font-regular font14 clickable"
      data-tooltip-id={isLongTag ? `tag_first_item_${data.id}` : ""}
      data-tooltip-content={data.name}
      onClick={onDetail}
    >
      {data?.name.slice(0, MAX_TAG_LETTER_COUNT) + (isLongTag ? "..." : "")}
    </div>
  );
};
