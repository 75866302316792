import React from "react";

import "./SideMenu.styles.scss";
import { MenuButtonProps } from "shared/utils/type.def";
import RoundedIconButton from "../RoundedIconButton/RoundedIconButton";

const MenuButton = (props: MenuButtonProps) => {
  return (
    <div
      className={`button-wrapper ${props.active ? "active" : ""} ${
        props.isMainActive && "main-active"
      }`}
      onClick={props.onClick}
    >
      <RoundedIconButton
        children={props.icon}
        color={
          props.active
            ? props.activeColor
              ? props.activeColor
              : `yellow`
            : props.color
        }
        rounded="circle"
        syncColor={props.syncColor}
      />
      <span
        className={`menu-title ${
          props.active ? "font-bold" : "font-regular"
        } font16 ${props.syncColor && props.color}`}
        style={props.isMainActive ? { color: "white" } : {}}
      >
        {props.title}
      </span>
    </div>
  );
};

export default MenuButton;
