import React, { useMemo } from "react";

import "./index.styles.scss";
import { useTranslation } from "react-i18next";
import { CommunicationItem } from "./CommunicationItem";
import { useSelector } from "react-redux";
import { RootState } from "redux/interfaces";

export const SettingCommunication = () => {
  const { t } = useTranslation();
  const { userSocials } = useSelector((state: RootState) => state.app);

  const arrSocial = useMemo(() => {
    return userSocials ?? [];
  }, [userSocials]);

  const discordInfo = useMemo(() => {
    if (arrSocial.filter((item, index) => item.type === "discord").length > 0) {
      return arrSocial.filter((item, index) => item.type === "discord")[0];
    } else {
      return null;
    }
  }, [userSocials]);

  const instaInfo = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === "instagram").length > 0
    ) {
      return arrSocial.filter((item, index) => item.type === "instagram")[0];
    } else {
      return null;
    }
  }, [userSocials]);

  const telegramInfo = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === "telegram").length > 0
    ) {
      return arrSocial.filter((item, index) => item.type === "telegram")[0];
    } else {
      return null;
    }
  }, [userSocials]);

  const whatsappInfo = useMemo(() => {
    if (
      arrSocial.filter((item, index) => item.type === "whatsapp").length > 0
    ) {
      return arrSocial.filter((item, index) => item.type === "whatsapp")[0];
    } else {
      return null;
    }
  }, [userSocials]);

  return (
    <div className="setting_communication">
      <div className="communication_title font-regular font14">
        {t("communication")}
      </div>
      <CommunicationItem type="discord" socialInfo={discordInfo} />
      <CommunicationItem type="instagram" socialInfo={instaInfo} />
      <CommunicationItem type="whatsapp" socialInfo={whatsappInfo} />
      <CommunicationItem type="telegram" socialInfo={telegramInfo} />
    </div>
  );
};
