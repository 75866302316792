import React, { useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-verification-code-input";
import Cookies from "js-cookie";

import "./index.styles.scss";
import {
  apiConfirmEmail,
  apiConfirmUpdatedEmail,
  apiLoginResendCode,
  apiLoginVerifyOTP,
  apiRecoverPassword,
  apiResendCode,
  apiUpdateEmail,
  apiVerifyRecoverCode,
} from "shared/api/user";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import appAction from "redux/app/action";
import { PrimaryButton } from "components/Common/PrimaryButton/PrimaryButton";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { RootNames } from "shared/routes/rootUrl";
import { useTranslation } from "react-i18next";
import { getReferralCode } from "shared/api/storageHelper";
import BackIcon from "assets/svg/BackIcon";
import HCaptcha from "@hcaptcha/react-hcaptcha";

interface Props {
  type: "signup" | "reset_pass" | "reset_email" | "phone_verify";
  email: string;
  password?: string;
  phoneNumber?: string;
  onVerifiedReset?: (code: string) => void;
  onBack?: () => void;
}

const RESEND_TIME = 60;

export const Verification = ({
  type,
  email,
  password,
  phoneNumber,
  onVerifiedReset: onVerifyReset,
  onBack,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [code, setCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  let timerRef = useRef<ReturnType<typeof setInterval> | null>(null);
  const [resendTime, setResendTime] = useState(RESEND_TIME);

  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const onCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (token) setCaptchaError(false);
  };

  const onCaptchaError = (err) => {
    console.error("hCaptcha Error", err);
    setCaptchaError(true);
  };

  useEffect(() => {
    onStartCounter();
  }, []);

  const onVerifiy = () => {
    if (!!code) {
      if (type === "signup") {
        onConfirmEmail();
      } else if (type === "reset_pass") {
        onVerifyResetPassReuqest();
      } else if (type === "reset_email") {
        onVerifyResetEmailReuqest();
      } else if (type === "phone_verify") {
        onVerifyPhoneNumber();
      }
    }
  };

  const onConfirmEmail = async () => {
    setShowError(false);

    if (!!code) {
      setIsVerifying(true);

      const res = await apiConfirmEmail({
        email,
        verificationCode: code,
        is_extension: true,
      });

      if (res?.success) {
        Cookies.set("accessToken", res.accessToken);
        Cookies.set("extensionToken", res?.extensionToken);
        localStorage.setItem("userId", res.userData.id);

        dispatch(appAction.successLogin(res.userData));

        navigate(RootNames.rewardCode);
      } else {
        setErrorMessage(res?.error);
        setShowError(true);
      }

      setIsVerifying(false);
    }
  };

  const onVerifyResetPassReuqest = async () => {
    setIsVerifying(true);

    const res = await apiVerifyRecoverCode({
      email: email,
      verificationCode: code,
    });

    if (res.success) {
      onVerifyReset && onVerifyReset(code);
    } else {
      setErrorMessage(res?.error);
      setShowError(true);
    }

    setIsVerifying(false);
  };

  const onVerifyResetEmailReuqest = async () => {
    setIsVerifying(true);

    const res = await apiConfirmUpdatedEmail({
      email: email,
      verificationCode: code,
    });

    if (res.success) {
      onVerifyReset && onVerifyReset(code);
    } else {
      setErrorMessage(res?.error);
      setShowError(true);
    }

    setIsVerifying(false);
  };

  const onVerifyPhoneNumber = async () => {
    setIsVerifying(true);

    const res = await apiLoginVerifyOTP({
      phone_number: phoneNumber!,
      verificationCode: code,
      referral: getReferralCode(),
      is_extension: true,
    });

    if (res.success) {
      Cookies.set("accessToken", res.accessToken);
      Cookies.set("extensionToken", res?.extensionToken);
      localStorage.setItem("userId", res.userData.id);

      dispatch(appAction.successLogin(res.userData));
    } else {
      setErrorMessage(res?.error);
      setShowError(true);
    }

    setIsVerifying(false);
  };

  const onResend = async () => {
    setShowError(false);

    if (type === "reset_email") {
      if (resendTime === RESEND_TIME) {
        onStartCounter();
        const res = await apiUpdateEmail({ email, password: password! });

        if (res?.success) {
        } else {
          setErrorMessage(res?.error);
          setShowError(true);
        }
      }
    } else if (type === "phone_verify") {
      if (resendTime === RESEND_TIME) {
        onStartCounter();
        const res = await apiLoginResendCode({
          phone_number: phoneNumber,
          captcha: captchaToken ?? "",
        });

        if (res?.success) {
        } else {
          setErrorMessage(res?.error);
          setShowError(true);
        }
      }
    } else {
      if (resendTime === RESEND_TIME) {
        onStartCounter();
        const res = await apiResendCode({ email });

        if (res?.success) {
        } else {
          setErrorMessage(res?.error);
          setShowError(true);
        }
      }
    }
  };

  useEffect(() => {
    onCheck();
  }, [resendTime]);

  const onCheck = () => {
    if (resendTime <= 0) {
      if (timerRef) {
        clearInterval(timerRef?.current!);
        setResendTime(RESEND_TIME);
        setIsLoaded(true);
      }
    }
  };

  const onStartCounter = () => {
    if (resendTime > 0) {
      timerRef.current = setInterval(() => {
        setResendTime((prev) => prev - 1);
      }, 1000);
    }
  };

  const handleChange = (vals) => {
    if (vals.length >= 4) {
    } else if (vals.length === 0) {
      setCode(vals);
    }
  };

  const renderResend = () => {
    return (
      <div style={{ marginTop: 32 }}>
        <div className="font-regular font16">
          {t("not_recieved")}
          <span
            className="clickable"
            style={{
              color: resendTime < RESEND_TIME || !captchaToken ? "gray" : "red",
              marginLeft: 8,
            }}
            onClick={captchaToken ? onResend : () => {}}
          >
            {resendTime == 60
              ? t("resend")
              : t("resend_time", { time: resendTime })}
          </span>
        </div>
      </div>
    );
  };

  const renderError = () => {
    if (showError) {
      return (
        <div
          className="error-message font-regular font14"
          style={{ marginTop: 32 }}
        >
          {errorMessage}
        </div>
      );
    }
  };

  return (
    <div className="verification_container">
      <div
        style={{ alignSelf: "flex-start", cursor: "pointer" }}
        onClick={() => {
          onBack && onBack();
        }}
      >
        <BackIcon />
      </div>
      <div className="content_body align-center">
        <div
          className="align-center font-medium font20"
          style={{ fontWeight: "bold" }}
        >
          {type === "reset_pass"
            ? t("change_password")
            : type === "phone_verify"
            ? t("enter_sign_code")
            : t("input_your_otp")}
        </div>
        <div className="font-regular font16" style={{ color: "#9DA9B6" }}>
          {type === "signup"
            ? t("we_have_zapped")
            : type === "phone_verify"
            ? t("check_your_phone")
            : t("check_your_email")}
        </div>
        <div style={{ marginTop: 32 }} className="font-medium font20">
          <ReactCodeInput
            autoFocus={true}
            fields={4}
            onChange={handleChange}
            onComplete={setCode}
            className="verification-code-input font-medium font20"
          />
        </div>
        {renderError()}
        <div className="submit-btn clickable" onClick={onVerifiy}>
          {isVerifying ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <div className="row_align_items">
              <div
                className="font-regular font14"
                style={{ color: "white", marginRight: 6 }}
              >
                {type === "signup" ? t("lock_in_it") : t("SUBMIT")}
              </div>
            </div>
          )}
        </div>
        {renderResend()}
        <div style={{ marginTop: 16 }}>
          {isLoaded && resendTime == RESEND_TIME && (
            <HCaptcha
              sitekey={
                process.env.REACT_APP_HCAPTCHA_SITE_KEY ??
                "10000000-ffff-ffff-ffff-000000000001"
              }
              onVerify={onCaptchaChange}
              onError={onCaptchaError}
              onExpire={() => setCaptchaToken(null)}
            />
          )}
          {isLoaded && resendTime == RESEND_TIME && captchaError && (
            <div className="error-message">Captcha verification failed</div>
          )}
        </div>
      </div>
    </div>
  );
};
