import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// Set the document title based on the environment variable
document.title = process.env.REACT_APP_APP_NAME || "Fallback App Name"; // If the env variable isn't set, it will use 'Fallback App Name'

// Update the logo URLs based on the environment variable
const logoURL = process.env.REACT_APP_LOGO;
if (logoURL) {
  const linkElems = document.querySelectorAll(
    "link[rel='icon'], link[rel='apple-touch-icon']"
  );
  linkElems.forEach((linkElem) => {
    linkElem.href = logoURL;
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
