import React, { useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./index.styles.scss";
import { Grid } from "@mui/material";
import {
  TopicLocalItemProps,
  TopicNormalItemProps,
} from "shared/utils/type.def";
import { PinkBorderBtn } from "components/Common/PinkBorderBtn";
import { PinkBgBtn } from "components/Common/PinkBgBtn";
import { showToast } from "components/Common/PrimaryToast";
import { apiHideInterest, apiUnHideInterest } from "shared/api/topics";
import { useTranslation } from "react-i18next";

interface Props {
  data: TopicNormalItemProps;
  onUpdateValidate: (topicId: any, isPublished: boolean) => void;
}

export const CardNotValTopic = ({ data, onUpdateValidate }: Props) => {
  const { t } = useTranslation();
  const [isPublishing, setIsPublishing] = useState(false);
  const [isHidding, setIsHidding] = useState(false);
  const [isTrend, setIsTrend] = useState(false);

  const hideTopic = async () => {
    if (isHidding) return;

    try {
      setIsHidding(true);

      const res = await apiHideInterest({ vibe: data.id });
      if (res.success) {
        showToast("success", t("msg_interest_hidden"));
        onUpdateValidate(data.id, true);
      } else {
        showToast("error", "Sorry, hiding interest failed - try again!");
      }

      setIsHidding(false);
    } catch (error) {
      console.log("extension-hide-topic-error", { error });
      setIsHidding(false);
    }
  };

  const publishTopic = async () => {
    if (isPublishing) return;

    try {
      setIsPublishing(true);

      const res = await apiUnHideInterest({ vibe: data.id });
      if (res.success) {
        showToast("success", t("msg_new_interest_published"));
        onUpdateValidate(data.id, false);
      } else {
        showToast("error", "Sorry, unhiding interest failed - try again!");
      }

      setIsPublishing(false);
    } catch (error) {
      console.log("extension-publish-topic-error", { error });
      setIsPublishing(false);
    }
  };

  const renderBg = () => {
    if (isTrend) {
      return (
        <img
          src={require("assets/image/bg_own_not_validated_interest_hot.png")}
          style={{ width: "100%", aspectRatio: 776 / 558 }}
        />
      );
    } else {
      return (
        <img
          src={require("assets/image/image.png")}
          style={{ width: "100%", aspectRatio: 776 / 558 }}
        />
      );
    }
  };

  const renderContent = () => {
    return (
      <div className="explore_card_topic_content">
        <div style={{ flex: 1 }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div
              className="topic_name font-medium font40 clickable"
              data-tooltip-id={`topic_title_${data.topic}`}
              data-tooltip-content={data.topic_name}
            >
              {(isTrend ? "🔥 " : "") + data.topic_name}
            </div>
          </div>
        </div>
        {renderBottom()}
      </div>
    );
  };

  const renderBottom = () => {
    return (
      <div className="bottom_container">
        <div className="font-regular font22" style={{ textAlign: "center" }}>
          {data.level === 1 ? t("New_Interest") : t("New_Subinterest")}
        </div>
        <div className="btn_container">
          <div className="btn_item">
            <PinkBorderBtn
              title={t("delete")}
              isLoading={isHidding}
              onClicked={() => {
                hideTopic();
              }}
            />
          </div>
          <div className="btn_item">
            <PinkBgBtn
              title={t("publish")}
              isLoading={isPublishing}
              onClicked={() => {
                publishTopic();
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <Grid item xs={12} md={12} lg={12} xl={6}>
      <div className="explore_card_not_val_topic">
        {renderBg()}
        {renderContent()}
      </div>
      <ReactTooltip id={`topic_title_${data.topic}`} />
    </Grid>
  );
};
