import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";

import "./index.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { ProgressIndicatorIcon } from "assets/svg/ProgressIndicatorIcon";
import { RewardEmptyIcon } from "assets/svg/RewardEmptyIcon";
import { apiGetExtensionLevel } from "shared/api/extensions";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import { RootState } from "redux/interfaces";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { useTranslation } from "react-i18next";

export const AddReferralModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const [level, setLevel] = useState(0);
  const [nextBand, setNextBand] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [arrReward, setArrReward] = useState<Array<any>>([]);
  const [nameTextWidth, setNameTextWidth] = useState(0);

  const { userData } = useSelector((state: RootState) => state.app);

  function getTextWidth(text, font = "Outfit-Medium") {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context) {
      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await apiGetExtensionLevel({ aggregated: 1 });

    if (res.success) {
      setIsLoaded(true);
      setLevel(res.level);
      setAccepted(res.accepted);
      setNextBand(res.nextBand);
      setArrReward(res.rewards);
    }
  };

  const onHide = () => {
    dispatch(appAction.toogleAddReferralCodeModal(false));
  };

  const onViewHistory = () => {
    onHide();
    dispatch(appAction.toogleRewardHistoryModal(true));
  };

  const onInvite = () => {
    onHide();
    dispatch(appAction.toogleInvitationModal(true));
  };

  const renderMainContent = () => {
    return (
      <div className="container1">
        <div className="row_align_items row_space_between">
          <div className="row_align_items">
            <RewardEmptyIcon width={70} height={70} />
            <div className="right_view">
              <div className="number-view font-medium font14">{`${accepted}/${nextBand}`}</div>
              <div className="font-bold font20">
                {t("invite_1_friend", { number: nextBand })}
              </div>
            </div>
          </div>
          <div className="row_align_items" style={{ gap: 12 }}>
            <div className="item_btn clickable" onClick={onViewHistory}>
              <div className="item_btn_txt font-regular font16">
                {t("history")}
              </div>
            </div>
            <div className="item_btn clickable" onClick={onInvite}>
              <div className="item_btn_txt font-regular font16">
                {t("invite")}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (userData)
      setNameTextWidth(getTextWidth(userData?.name ?? userData?.username));
  }, [userData]);

  const renderMainBody = () => {
    return (
      <div className="modal_body">
        <div className="carosuel_body">
          <div className="modal_header">
            <div className="font-bold font20">
              {t("level_display", { level: level })}
            </div>
            <div
              style={{ position: "absolute", right: 32, top: 32 }}
              className="clickable"
              onClick={onHide}
            >
              <RedCloseIcon />
            </div>
          </div>
          <div className="modal_progrees_bar">
            <img
              src={require("assets/image/bg_progress.png")}
              className="progrees_bar_bg"
            />
            <div
              style={{
                position: "absolute",
                top: 6,
                left: `${(level / 4) * 100}%`,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ProgressIndicatorIcon />
            </div>
          </div>
          {renderMainContent()}
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div
        className="modal_body"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RectangleLoadingAnimation />
      </div>
    );
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_reward">
        <div
          className="modal_content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            src={require("assets/image/triangle_top_icon.png")}
            style={{
              width: 32,
              height: 32,
              position: "fixed",
              right: nameTextWidth + 180,
              top: 4,
            }}
          />
          {isLoaded ? renderMainBody() : renderLoading()}
        </div>
      </div>
    </OpacityModal>
  );
};
