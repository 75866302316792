import React, { useEffect, useRef, useState } from "react";

import "./index.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/interfaces";
import useIPFS from "shared/utils-IPFS/useIPFS";
import { getBase64String } from "shared/utils-IPFS/functions";
import {
  apiEditProfile,
  apiEditProfileImage,
  apiRecoverPassword,
  apiRequestRecoverPassword,
} from "shared/api/user";
import appAction from "redux/app/action";
import DefaultAvatarIcon from "assets/svg/DefaultAvatarIcon";
import { LoadingCircular } from "components/Common/LoadingCircular";
import ArrowRightIcon from "assets/svg/ArrowRightIcon";
import { SettingSocialMedia } from "./SocialMedia";
import UCheckIcon from "assets/svg/UCheckIcon";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import EditIcon from "assets/svg/EditIcon";
import { showToast } from "components/Common/PrimaryToast";
import { Verification } from "components/Auth/Verification";
import NormalArrowRightIcon from "assets/svg/NormalArrowRightIcon";
import { TextField } from "@mui/material";
import { SettingTopic } from "./Topic";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { RootNames } from "shared/routes/rootUrl";
import { useTranslation } from "react-i18next";
import { SettingCommunication } from "./Communication";
import { apiAuthorise } from "shared/api/util";

const SettingScreen = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData } = useSelector((state: RootState) => state.app);
  const [step, setStep] = useState<"main" | "verify" | "newPassword">("main");
  const [isEditUserName, setIsEditUserName] = useState(false);
  const [isEditingUserName, setIsEditingUserName] = useState(false);
  const [newUserName, setNewUserName] = useState(
    userData?.name ?? userData?.username
  );

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [imageUrl, setImageUrl] = useState(userData?.image);

  const hiddenFileInput = useRef<any>(null);
  const { uploadBase64 } = useIPFS();

  // Reset Password Flow
  const [isSending, setIsSending] = useState(false);

  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSettingNew, setIsSettingNew] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setErrorMessage("");
    setShowError(false);
  }, [step]);

  useEffect(() => {
    const socialConnect = localStorage.getItem("socialConnect");
    if(socialConnect && socialConnect === 'reddit')
      showToast("success", t("msg_success_account_connection"));
    // if(socialConnect && socialConnect === 'twitter')
    //   showToast("success", t("msg_success_account_connection"));
    localStorage.removeItem("socialConnect");

    dispatch(appAction.loadUserInfo(userData!.id));
  }, []);

  useEffect(() => {
    if (!!searchParams && searchParams.size == 2) {
      onProcessCommunication();
    }
  }, [searchParams]);

  const onProcessCommunication = async () => {
    // connect communication
    const socialType = searchParams.get("type");
    const socialToken = searchParams.get("token");
    // console.log("socialType??? ", socialType);
    // console.log("socialToken??? ", socialToken);

    if (
      (socialType === "discord" ||
        socialType === "instagram" ||
        socialType === "whatsapp" ||
        socialType === "telegram") &&
      !!socialToken
    ) {
      // save communication status
      const res = await apiAuthorise({ type: socialType, token: socialToken });
      if (res && res.success) {
        showToast("success", t("msg_success_account_connection"));
        dispatch(appAction.loadUserInfo(userData!.id));
      } else {
        showToast("error", t("msg_failed_account_connection_already"));
      }
    }
  };

  const onEditUserName = () => {
    setIsEditUserName(true);
  };

  const onCancelEditUserName = () => {
    setIsEditUserName(false);
    setNewUserName(userData?.name ?? userData?.username);
  };

  const onUpdateUserName = async () => {
    if (!!newUserName && !isEditingUserName) {
      setIsEditingUserName(true);

      const res = await apiEditProfile({ username: newUserName });

      if (res.success) {
        showToast("success", "Username change successful!");
        setIsEditUserName(false);
        dispatch(appAction.loadUserInfo(userData!.id));
      }

      setIsEditingUserName(false);
    }
  };

  const onAddReferralCode = () => {
    navigate(RootNames.rewardCode);
  };

  const onUpdateEmail = () => {
    navigate(RootNames.updateEmail);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, "");

      const res = await apiEditProfileImage({ profileImage: url });
      if (res.success) {
        showToast("success", t("msg_profile_picture_updated_success"));
        setImageUrl(url + "");
        dispatch(appAction.loadUserInfo(userData!.id));
      }
      setIsUploadingFile(false);
    }
  };

  const onRequestRecover = async () => {
    setShowError(false);

    setIsSending(true);

    const res = await apiRequestRecoverPassword({
      email: userData?.email!,
    });

    if (res.success) {
      setStep("verify");
    } else {
      setErrorMessage(res?.error);
      setShowError(true);
    }

    setIsSending(false);
  };

  const onVerifyReset = async (code: string) => {
    setCode(code);
    setStep("newPassword");
  };

  const onSetNewPassword = async () => {
    setShowError(false);

    if (!!newPassword && !!confirmPassword && newPassword === confirmPassword) {
      if (!!code) {
        setIsSettingNew(true);
        const res = await apiRecoverPassword({
          email: userData?.email!,
          verificationCode: code,
          newPassword: newPassword!,
        });
        if (res?.success) {
          showToast("success", t("msg_password_changed"));
          setStep("main");
        } else {
          setErrorMessage(res?.error);
          setShowError(true);
        }
        setIsSettingNew(false);
      }
    } else if (!!newPassword && newPassword !== confirmPassword) {
      setErrorMessage(t("msg_password_mismatch"));
      setShowError(true);
    }
  };

  const onViewFullImage = () => {
    if (!!imageUrl) {
      dispatch(appAction.onShowImageFullView(imageUrl));
    }
  };

  const renderPicture = () => {
    return (
      <div className="picture-container">
        {userData !== undefined && imageUrl !== "" ? (
          <img
            src={imageUrl}
            className="user_picture clickable"
            onClick={onViewFullImage}
          />
        ) : (
          <DefaultAvatarIcon size={60} />
        )}
        <div>
          {isUploadingFile ? (
            <LoadingCircular color="main" size={16} />
          ) : (
            <div
              className="btn_upload font14 font-regular clickable"
              onClick={handleClick}
            >
              {t("upload_new_picture")}
            </div>
          )}
        </div>
        {/* <div
          className="btn_upload font14 font-regular clickable"
          onClick={onAddReferralCode}
        >
          🎁 &nbsp;Rewards Access Code
        </div> */}
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
          accept="image/*"
        />
      </div>
    );
  };

  const renderSubInfo = () => {
    return (
      <div className="user-info-container">
        <div className="item-row row_align_items">
          <div className="font-regular font14">{t("name")}</div>
          {isEditUserName ? (
            <div className="row_align_items">
              <input
                value={newUserName}
                placeholder={t("p_enter_your_name")}
                className="sub_input font-bold font14"
                onChange={(e) => setNewUserName(e.target.value)}
              />
              <div className="edit_done_btn font-bold font16">
                {isEditingUserName ? (
                  <LoadingCircular size={16} color="main" />
                ) : (
                  <div className="row_align_items" style={{ gap: 8 }}>
                    <div className="clickable" onClick={onUpdateUserName}>
                      <UCheckIcon color="#2BBC3A" />
                    </div>
                    <div className="clickable" onClick={onCancelEditUserName}>
                      <RedCloseIcon />
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="row_align_items clickable" onClick={onEditUserName}>
              <div className="font-regular font14">
                {userData?.name ?? userData?.username}
              </div>
              <div style={{ marginLeft: 8 }}>
                <EditIcon color="#DBDBDB" size={16} />
              </div>
            </div>
          )}
        </div>
        <div
          className="item-row row_align_items clickable"
          onClick={onUpdateEmail}
        >
          <div className="font-regular font14">{t("email")}</div>
          <div className="row_align_items clickable">
            <div className="font-regular font14">{userData?.email}</div>
            <div>
              <ArrowRightIcon />
            </div>
          </div>
        </div>
        {!!userData?.email && (
          <div
            className="item-row row_align_items clickable"
            onClick={onRequestRecover}
          >
            <div className="font-regular font14" style={{ color: "#F32D68" }}>
              {t("change_password")}
            </div>
            <div className="row_align_items">
              {isSending ? (
                <div style={{ width: 24, height: 24 }}>
                  <LoadingCircular size={16} color="main" />
                </div>
              ) : (
                <div>
                  <ArrowRightIcon />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTopic = () => {
    return <SettingTopic />;
  };

  const renderSocialMedia = () => {
    return <SettingSocialMedia />;
  };

  const renderCommunication = () => {
    return <SettingCommunication />;
  };

  const renderMainBody = () => {
    return (
      <div className="setting-body">
        {renderPicture()}
        {renderSubInfo()}
        {renderTopic()}
        {renderSocialMedia()}
        {renderCommunication()}
      </div>
    );
  };

  const renderVerifyStep = () => {
    return (
      <div className="register_verify">
        <Verification
          email={userData?.email!}
          type="reset_pass"
          onVerifiedReset={onVerifyReset}
        />
      </div>
    );
  };

  const renderResetStep = () => {
    return (
      <div className="email_step">
        <div className="font-medium font20">{t("reset_your_password")}</div>
        <div className="font-regular font14" style={{ opacity: 0.8 }}>
          {t("type_in_new_password")}
        </div>
        <TextField
          id="outlined-basic"
          label={t("p_new_password")}
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            classes: {
              input: "font-regular font16",
            },
            autoComplete: "new-password",
          }}
          autoComplete="new-password"
          type="password"
          style={{ width: "100%", marginTop: 40 }}
        />

        <TextField
          id="outlined-basic"
          label={t("p_retry_new_password")}
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            classes: {
              input: "font-regular font16",
            },
            autoComplete: "new-password",
          }}
          autoComplete="new-password"
          type="password"
          style={{ width: "100%", marginTop: 16 }}
        />
        <div className="next-btn clickable" onClick={onSetNewPassword}>
          {isSettingNew ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <div className="row_align_items">
              <div
                className="font-regular font14"
                style={{ color: "white", marginRight: 6 }}
              >
                {t("RESET")}
              </div>
              <div className="clickable">
                <NormalArrowRightIcon />
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: 66 }}>{renderBackBtn()}</div>
        <div style={{ marginTop: 16 }}>{renderError()}</div>
      </div>
    );
  };

  const renderBackBtn = () => {
    return (
      <div
        className="back-btn clickable"
        onClick={() => {
          setStep("main");
        }}
      >
        <div className="btn-txt font-regular font14">{t("BACK")}</div>
      </div>
    );
  };

  const renderError = () => {
    if (showError) {
      return (
        <div className="error-message font-regular font14">{errorMessage}</div>
      );
    }
  };

  return (
    <div className="setting-container">
      {step === "main"
        ? renderMainBody()
        : step === "verify"
        ? renderVerifyStep()
        : renderResetStep()}
    </div>
  );
};

export default SettingScreen;
