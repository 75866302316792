import React, { useEffect, useMemo, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import appAction from "redux/app/action";

import "./index.styles.scss";
import { BgTopicCard } from "assets/svg/BgTopicCard";
import { Grid } from "@mui/material";
import { TagView } from "./TagView";
import RoundedIconButton from "components/Common/RoundedIconButton/RoundedIconButton";
import { ShowIcon } from "assets/svg/ShowIcon";
import { TopicNormalItemProps } from "shared/utils/type.def";
import { getArrFromString } from "shared/utils/utils";
import { showToast } from "components/Common/PrimaryToast";
import {
  apiEditTopicInterest,
  apiHideInterest,
  apiUnHideInterest,
} from "shared/api/topics";
import { HideIcon } from "assets/svg/HideIcon";
import { RootState } from "redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { NormalPlusIcon } from "assets/svg/NormalPlusIcon";
import useIPFS from "shared/utils-IPFS/useIPFS";
import { getBase64String } from "shared/utils-IPFS/functions";
import { LoadingCircular } from "components/Common/LoadingCircular";
import EmptyHighLightIcon from "assets/svg/EmptyHighLightIcon";
import EditIcon from "assets/svg/EditIcon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";

interface Props {
  index?: number;
  data: TopicNormalItemProps;
  isVibeMatch?: boolean;
  onUpdateHideStatus: (topicId: any, isHidden: number) => void;
}

export const CardNormalTopic = ({
  index,
  data,
  isVibeMatch = false,
  onUpdateHideStatus,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: RootState) => state.app);

  const [isHidden, setIsHidden] = useState(data.is_hidden);
  const [isTrend, setIsTrend] = useState(false);
  const [isChangingVisual, setIsChangingVisual] = useState(false);
  const [displayImageUrl, setDisplayImageUrl] = useState<any>(null);

  const hiddenFileInput = useRef<any>(null);
  const { uploadBase64 } = useIPFS();
  const [isUploadingFile, setIsUploadingFile] = useState(false);

  useEffect(() => {
    setIsHidden(data.is_hidden);
    setIsTrend(false);
  }, [data]);

  const imageUrl = useMemo(() => {
    if (data?.images || data?.topic_image) {
      const arrTmpImage = getArrFromString(
        data?.images ?? data?.topic_image,
        ","
      ).filter((item, index) => item.startsWith("https"));

      if (arrTmpImage.length > 0) {
        return arrTmpImage[0];
      } else {
        return "";
      }
    } else {
      return "";
    }
  }, [data]);

  useEffect(() => {
    setDisplayImageUrl(imageUrl);
  }, [imageUrl]);

  const isOwner = useMemo(() => {
    if (data.user === userData?.id) {
      return true;
    }

    return false;
  }, [data]);

  const onVisableAction = (e) => {
    e.stopPropagation();
    if (!isChangingVisual) {
      if (isHidden) {
        onShowInterest();
      } else {
        onHideInterest();
      }
    }
  };

  const onHideInterest = async () => {
    setIsChangingVisual(true);

    const res = await apiHideInterest({ vibe: data.id });
    if (res.success) {
      showToast("success", t("msg_interest_hidden"));
      setIsHidden(1);
      onUpdateHideStatus(data.id, 1);
    } else {
      showToast("error", "Sorry, hiding interest failed - try again!");
    }

    setIsChangingVisual(false);
  };

  const onShowInterest = async () => {
    setIsChangingVisual(true);

    const res = await apiUnHideInterest({ vibe: data.id });
    if (res.success) {
      showToast("success", t("msg_interest_showing_again"));
      setIsHidden(0);
      onUpdateHideStatus(data.id, 0);
    } else {
      showToast("error", "Sorry, unhiding interest failed - try again!");
    }

    setIsChangingVisual(false);
  };

  const onViewFullImage = () => {
    navigate(`/interest/${data.id}`);
    // if (!!imageUrl) {
    //   dispatch(appAction.onShowImageFullView(imageUrl));
    // } else if (data?.subtopic_total && data?.subtopic_total > 0) {
    //   navigate(`/subinterest/${data.id}`);
    // }
  };

  const handleClick = (event) => {
    event.stopPropagation();
    if (!isUploadingFile) hiddenFileInput.current.click();
  };

  const firstText = useRef<HTMLParagraphElement>(null);
  const secondText = useRef<HTMLParagraphElement>(null);
  const slider = useRef<HTMLDivElement>(null);
  let xPercent = 0;

  useEffect( () => {
    if(secondText.current != null)
    {
      gsap.set(firstText.current, {left:  secondText.current.getBoundingClientRect().width})
      requestAnimationFrame(animate);
    }
  }, []);

  const animate = () => {
    if(xPercent < -100){
      xPercent = 0;
    }
    gsap.set(firstText.current, {xPercent: xPercent})
    gsap.set(secondText.current, {xPercent: xPercent})
    requestAnimationFrame(animate);
    xPercent -= 0.1;
  }

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, "");

      if (url !== undefined && url !== "" && url !== null) {
        const res = await apiEditTopicInterest({
          vibe: data.id,
          images: url,
        });

        if (res.success) {
          showToast("success", t("msg_interest_updated"));
          setDisplayImageUrl(url);
        }
      }

      setIsUploadingFile(false);
    }
  };

  const renderBg = () => {
    if (isTrend) {
      return (
        <div style={{ display: "flex" }}>
          <img
            src={require("../../../../../assets/image/bg_existed_image_interest_hot.png")}
            style={{ width: "100%" }}
          />
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <BgTopicCard image={displayImageUrl} isTrend={isTrend} />
          </div>
        </div>
      );
    } else {
      return <BgTopicCard image={displayImageUrl} isTrend={isTrend} />;
    }
  };

  const renderContent = () => {
    return (
      <div
        className="explore_card_topic_content"
        onClick={() => {
          onViewFullImage();
        }}
      >
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}

          >
            {
              data.topic_name.length > 14 ?
              <div className="topic_name clickable" style={{
                width: 'fit-content',
                maxWidth: '70%',
                height: 'fit-content',
                overflow: 'hidden',
              }}>
                <div
                  ref={slider}
                  className="font-medium font40"
                  data-tooltip-id={`topic_title_${data.topic}`}
                  data-tooltip-content={data.topic_name}
                  style={{display: 'flex', width: 'max-content', justifyContent: 'center', alignItems: 'center'}}
                >
                  <p style={{ marginRight: 20, marginBottom: 0, wordBreak: 'break-all' }} ref={firstText}>
                    {(isTrend ? "🔥 " : "") + data.topic_name}
                  </p>
                  <p style={{ margin: 0, wordBreak: 'break-all' }} ref={secondText}>
                    {(isTrend ? "🔥 " : "") + data.topic_name}
                  </p>
                </div>
              </div> :
              <div
                className="topic_name font-medium font40 clickable"
                data-tooltip-id={`topic_title_${data.topic}`}
                data-tooltip-content={data.topic_name}
              >
                {(isTrend ? "🔥 " : "") + data.topic_name}
              </div>
            }
            {isOwner && (
              <div
                className="clickable"
                onClick={onVisableAction}
                data-tooltip-id={
                  isHidden === 1
                    ? `topic_show_${data.topic}`
                    : `topic_hide_${data.topic}`
                }
                data-tooltip-content={isHidden === 1 ? "Show" : "Hide"}
              >
                <RoundedIconButton
                  children={isHidden == 0 ? <ShowIcon /> : <HideIcon />}
                  color={"black"}
                  rounded="circle"
                  syncColor={true}
                  isLoading={isChangingVisual}
                  style={{ opacity: 0.7 }}
                />
              </div>
            )}
            {isOwner && !!imageUrl && (
              <div
                className="clickable"
                onClick={handleClick}
                style={{ position: "absolute", right: 0, top: 68 }}
                data-tooltip-id={`topic_edit_${data.topic}`}
                data-tooltip-content={t("edit_image")}
              >
                <RoundedIconButton
                  children={<EditIcon color="black" size={20} />}
                  color={"black"}
                  rounded="circle"
                  syncColor={true}
                  isLoading={isUploadingFile}
                  style={{ opacity: 0.7 }}
                />
              </div>
            )}
          </div>
        </div>
        <div className="center_view">
          {isOwner ? (
            isUploadingFile ? (
              <div className="add_image_btn clickable">
                <LoadingCircular size={16} color="main" />{" "}
              </div>
            ) : (
              !displayImageUrl && (
                <div
                  className="add_image_btn clickable"
                  onClick={handleClick}
                  data-tooltip-id={`topic_edit_${data.topic}`}
                  data-tooltip-content={t("edit_image")}
                >
                  <div className="text_no_wrap font-regular font18">
                    {t("add_photo")}
                  </div>
                  <div
                    className="clickable"
                    style={{ marginTop: 4, marginLeft: 8 }}
                  >
                    <NormalPlusIcon color="black" />
                  </div>
                </div>
              )
            )
          ) : (
            <></>
          )}
        </div>
        <TagView data={data} />
      </div>
    );
  };

  return (
    <Grid item xs={12} md={12} lg={12} xl={6}>
      <div className="card_topic_container">
        <div className="explore_card_topic">
          {renderBg()}
          {renderContent()}

          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            accept="image/png, image/jpeg"
          />
        </div>
      </div>
      <ReactTooltip id={`topic_title_${data.topic}`} />
      <ReactTooltip id={`topic_hide_${data.topic}`} />
      <ReactTooltip id={`topic_show_${data.topic}`} />
      <ReactTooltip id={`topic_edit_${data.topic}`} />
    </Grid>
  );
};
