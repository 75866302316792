import React from "react";

import "./index.styles.scss";
import TwitterIcon from "assets/svg/socials/TwitterIcon";
import YoutubIcon from "assets/svg/socials/YoutubeIcon";
import RedditIcon from "assets/svg/socials/RedditIcon";
import SpotifyIcon from "assets/svg/socials/SpotifyIcon";


interface Props {
  type: "twitter" | "reddit" | "youtube" | "google-oauth2" | "spotify";
  onSocialLogin;
}

export const LoginSocialItem = ({ type, onSocialLogin }: Props) => {

  const handleItemClick = () => {
    onSocialLogin(type);
  };

  const renderIcon = () => {
    if (type === "youtube") {
      return <YoutubIcon size={32} />;
    } else if (type === "twitter") {
      return <TwitterIcon size={20} />;
    } else if (type === "reddit") {
      return <RedditIcon size={32} />;
    } else if (type === "spotify") {
      return <SpotifyIcon size={48} />;
    }
  };

  return (
    <div className="social-icon clickable" onClick={handleItemClick}>
      {renderIcon()}
    </div>
  );
};
