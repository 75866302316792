import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars";
import { useNavigate, useParams } from "react-router-dom";

import "./index.styles.scss";
import { useMicroInterest } from "shared/hook/fetchData";
import { CardSubInterest } from "./CardSubInterest";
import BackIcon from "assets/svg/BackIcon";
import { useTranslation } from "react-i18next";
import { apiGetTopicInterestDetail } from "shared/api/topics";
import { LoadingCircular } from "components/Common/LoadingCircular";

export const SubInterest = () => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const vibeId = params.id;
  const [interestName, setInterestName] = useState("");

  useEffect(() => {
    loadDetail();
  }, [vibeId]);

  const loadDetail = async () => {
    const res = await apiGetTopicInterestDetail({ vibe: vibeId });

    if (res.success) {
      setInterestName(res.data.topic_name);
    }
  };

  const {
    data: arrBeData,
    fetchNextPage: fetchNextPageBeTopic,
    hasNextPage: hasNextPageBeTopic,
    isFetchingNextPage: isFetchingTopic,
    isFetched: isFetchedBeTopic,
    refetch: refetchBeTopics,
  } = useMicroInterest(vibeId ?? 0);

  const onBack = () => {
    navigate(-1);
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10 &&
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight >= 0;

    if (bottom) {
      hasNextPageBeTopic && !isFetchingTopic && fetchNextPageBeTopic();
    }
  };

  const renderHeader = () => {
    return (
      <div className="header row_align_items">
        <div className="clickable" onClick={onBack}>
          <BackIcon />
        </div>
        <div className="font-regular font26">{interestName}</div>
      </div>
    );
  };

  let arrBeTopics = arrBeData
    ? arrBeData.pages.flatMap((page) => page?.data)
    : [];

  const renderBody = () => {
    return (
      <div className="subinterest_body">
        <Scrollbars
          onScroll={handleScroll}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
          style={{ paddingBottom: 100 }}
        >
          <Grid container spacing={3}>
            {arrBeTopics.map((item, index) => {
              return (
                <Grid item xs={4}>
                  <CardSubInterest
                    key={`card_subinterest_${index}`}
                    data={item}
                    onRefresh={() => {
                      refetchBeTopics();
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Scrollbars>
        {isFetchingTopic && (
          <div className="center" style={{ paddingTop: 8, paddingBottom: 8 }}>
            <LoadingCircular size={24} color="main" />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="sub_interest">
      {renderHeader()}
      {renderBody()}
    </div>
  );
};
