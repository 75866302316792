export const RootNames = {
  root: "/",
  allRoot: "/*",
  login: "/login",
  login_with_phone: "/loginWithPhone",
  register: "/register",
  forget_password: "/forgetpassword",
  login_verification: "/loginVerification",
  profile: "/profile",
  qr: "/qr",
  settings: "/settings",
  vibes: "/vibes/:userId",
  noExtension: "/noextension",
  rewardCode: "/rewardcode",
  redditLogin: "/redditLogin",
  redditConnect: "/redditConnect",
  updateEmail: "/updateemail",
  subinterest: "/subinterest/:id",
  interest: "/interest/:id",
  subInterestDetail: "/subInterestDetail/:id",
  trendDetail: "/trendDetail/:id",
};
