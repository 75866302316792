import React from "react";

import "./index.scss";
import { useTranslation } from "react-i18next";

interface Props {
  score: number;
  vibes_type?: string;
}

const highColor = "#00FF66";
const mediumColor = "yellow";
const lowColor = "#EA21A2";

const LOW_SCORE = 0.35;
const MEDIUM_SCORE = 0.65;

export const VibeMatchPercentHorizontal = ({ score, vibes_type }: Props) => {
  const { t } = useTranslation();

  const resultProgress = score * 100;

  // const statusTxt =
  //   resultProgress >= 65
  //     ? t("high")
  //     : resultProgress >= 35
  //     ? t("medium")
  //     : t("low");

  let statusTxt =
  score > MEDIUM_SCORE
    ? t("high")
    : score > LOW_SCORE && score <= MEDIUM_SCORE
    ? t("medium")
    : t("low");

  const progressBarColor =
    statusTxt === t("high")
      ? highColor
      : statusTxt === t("medium")
      ? mediumColor
      : lowColor;

  const bar1 = {
    backgroundColor: resultProgress > 0 ? progressBarColor : "transparent",
  };

  const bar2 = {
    backgroundColor: resultProgress > 35 ? progressBarColor : "transparent",
  };

  const bar3 = {
    backgroundColor: resultProgress > 65 ? progressBarColor : "transparent",
  };

  const bar4 = {
    backgroundColor: resultProgress > 65 ? progressBarColor : "transparent",
  };

  return (
    <div className="vibe_match_percent_horizontal">
      <div className="vibe_match_percent_graph">
        <div className="vibe_match_percent_item" style={bar1}></div>
        <div className="vibe_match_percent_item" style={bar2}></div>
        <div className="vibe_match_percent_item" style={bar3}></div>
        <div className="vibe_match_percent_item" style={bar4}></div>
      </div>
      <div className="font-regular font12">{vibes_type ? vibes_type : statusTxt}</div>
    </div>
  );
};
