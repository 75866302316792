import React from "react";

import "./index.styles.scss";
import { BgPinkBorderBtn } from "assets/svg/BgPinkBorderBtn";
import { LoadingCircular } from "../LoadingCircular";

interface Props {
  title: string;
  isLoading: boolean;
  onClicked: () => void;
}

export const PinkBorderBtn = ({ title, isLoading, onClicked }: Props) => {
  return (
    <div className="pink_border_btn clickable" onClick={onClicked}>
      <BgPinkBorderBtn />
      <div className="btn_content">
        {isLoading ? (
          <LoadingCircular color="main" size={24} />
        ) : (
          <div className="font-regular font18">{title}</div>
        )}
      </div>
    </div>
  );
};
