import React, { useEffect, useMemo, useState } from "react";

import "./index.styles.scss";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from "assets/svg/BackIcon";
import { PinkRoundAvatar } from "components/Common/PinkRoundAvatar";
import { CardVibeMatch } from "./CardVibeMatch";
import { PercentBtn } from "components/Common/PercentBtn";
import { apiGetTopicWithVibe } from "shared/api/topics";
import { VibeMatchInterest } from "./Interest";
import { useTranslation } from "react-i18next";
import { RootNames } from "shared/routes/rootUrl";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { useSelector } from "react-redux";
import { RootState } from "redux/interfaces";


export const VibeMatch = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const params = useParams();
  const vibeId = params.userId;

  const [vibeDetail, setVibeDetail] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  const { vibeUserInfo, userData } = useSelector((state: RootState) => state.app);

  useEffect(() => {
    loadTopPart();
    console.log("vibeUserInfo:", vibeUserInfo);
  }, [vibeId]);

  const loadTopPart = async () => {
    setIsLoading(true);

    const res = await apiGetTopicWithVibe({
      toUser: vibeId,
      isPhysical: false,
    });

    setVibeDetail({
      first: res.data,
      vibeScore: res.data.vibes_score,
      vibeType: res.data.vibes_type,
      isFollowed: res.data.isFollowed,
    });

    setIsLoading(false);
  };

  const onBack = () => {
    // navigate(RootNames.profile);
    navigate(-1);
  };

  const vibeScore = useMemo(() => {
    if (vibeDetail) {
      const tmpScore = vibeDetail.vibeScore * 100;

      return parseInt(tmpScore.toFixed(0));
    } else {
      return 0;
    }
  }, [vibeDetail]);

  const renderHeader = () => {
    return (
      <div className="header row_align_items">
        <div className="clickable" onClick={onBack}>
          <BackIcon />
        </div>
        <div className="font-adversal font24">{t("vibe_check")}</div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="match-body">
        <div className="body-header row_align_items">
          <div className="match-item">
            <div className="user_info">
              <PinkRoundAvatar size={64} url={vibeDetail.first.creator_image} />
              <div className="user_name font-bold font16">
                {vibeDetail.first.creator_name}
              </div>
            </div>
            <CardVibeMatch data={vibeDetail.first} />
          </div>
          <div className="percent-view">
            <PercentBtn
              buttonSize={100}
              percent={vibeScore}
              fontSize={24}
              vibeType={vibeDetail.vibeType}
            />
          </div>
          <div className="match-item row_align_items">
            <CardVibeMatch data={
              {
                ...vibeUserInfo,
                creator_id: userData?.id,
                creator_image: userData?.image,
                creator_name: userData?.username,
              }
            } />
            <div className="user_info">
              <PinkRoundAvatar
                size={64}
                url={userData?.image}
              />
              <div className="user_name font-bold font16">
                {userData?.username}
              </div>
            </div>
          </div>
        </div>
        <div
          className="font-adversal font24"
          style={{ marginTop: 32, marginBottom: 0 }}
        >
          {t("shared_interest")}
        </div>
        <VibeMatchInterest userId={vibeDetail.first.creator_id} />
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="vibe-match-container">
        <div className="center">
          <RectangleLoadingAnimation />
        </div>
      </div>
    );
  } else {
    return (
      <div className="vibe-match-container">
        {renderHeader()}
        {vibeDetail && renderBody()}
      </div>
    );
  }
};
