import React from "react";

import "./index.styles.scss";
import { useNavigate } from "react-router-dom";

interface Props {
  data: any;
  interestId: any;
}

export const PlusTag = ({ data, interestId }: Props) => {
  const navigate = useNavigate();

  const onClicked = (e) => {
    e.stopPropagation();
    navigate(`/subinterest/${interestId}`);
  };

  return (
    <div
      className="tag_item first_tag font-regular font14 clickable"
      onClick={onClicked}
    >
      {"+" + data}
    </div>
  );
};
