/* global chrome */

import React, { useEffect, useState } from "react";
import { PersistGate } from "redux-persist/integration/react";
import { Auth0Provider } from "@auth0/auth0-react";
import "./App.scss";
import "react-tooltip/dist/react-tooltip.css";

import "./shared/translation";
import { Provider } from "react-redux";
import store from "./redux/store";
import { AppStart } from "components/AppStart";
import { BrowserRouter } from "react-router-dom";
import { IPFSContextProvider } from "shared/contexts/IPFSContext";

import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { initGA } from "shared/utils/ga";
import Cookies from "js-cookie";
import {SocketProvider} from 'shared/contexts/socketContext';

const DOWNLOAD_URL = process.env.REACT_APP_CHROME_STORE;
export const EXTENSION_ID = process.env.REACT_APP_EXTENSION_ID;
export const SAFARI_EXTENSION_ID = process.env.REACT_APP_SAFARI_EXTENSION_ID;

const queryClient = new QueryClient();

const handleAcceptCookie = () => {
  if (process.env.REACT_APP_GA_ID) {
    initGA(process.env.REACT_APP_GA_ID);
  }
};

const handleDeclineCookie = () => {
  //remove google analytics cookies
  Cookies.remove("_ga");
  Cookies.remove("_gat");
  Cookies.remove("_gid");
};

function App() {
  const [extensionInstalled, setExtensionInstalled] = useState(false);

  // LOGIC TO CHECK IF EXISTING EXTENSION
  useEffect(() => {
    if (window.location.pathname === "/") {
      const searchParams = new URLSearchParams(window.location.search);
      const refValue = searchParams.get("$ref");
      if (refValue) {
        Cookies.set("referralCode", refValue, {
          expires: process.env.REACT_APP_REFERALL_EXPIRATION,
        });
      }
    }
    // Define the event handler
    const handleExtensionSignal = (event) => {
      if (event && event.detail === "extension_present") {
        setExtensionInstalled(true);
      }
      const isConsent = getCookieConsentValue();
      if (isConsent === "true") {
        handleAcceptCookie();
      }
    };

    // Listen for the custom event dispatched by the extension
    window.addEventListener("extensionSignal", handleExtensionSignal);

    // If the extension doesn't signal its presence within 5 seconds, redirect the user
    const timeout = setTimeout(() => {
      if (!extensionInstalled) {
        // window.location.href = DOWNLOAD_URL;
      }
    }, 2); // 5 seconds

    // Cleanup
    return () => {
      window.removeEventListener("extensionSignal", handleExtensionSignal);
      clearTimeout(timeout);
    };
  }, [extensionInstalled]);

  // LOGIC TO REQUEST DATA FROM EXTENSION (We will get interests here)
  const requestData = () => {
    chrome.runtime.sendMessage(
      EXTENSION_ID,
      { type: "getData" },
      function (response) {
        alert(
          "Received from extension: " + JSON.stringify(response.data, null, 2)
        );
      }
    );
  };

  const renderTestExtensionInstall = () => {
    return (
      <div className="App">
        <header className="App-header">
          <h1>Local React Test App</h1>
          {extensionInstalled && (
            <button onClick={requestData}>Request Data from Extension</button>
          )}
        </header>
      </div>
    );
  };

  return (
    <div className="root_container">
      <QueryClientProvider client={queryClient}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_DOMAIN ?? ""}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID ?? ""}
          authorizationParams={{
            redirect_uri: window.location.origin,
          }}
        >
          <Provider store={store.store}>
            <PersistGate loading={null} persistor={store.persistor}>
              <SocketProvider>
                <IPFSContextProvider>
                  <BrowserRouter>
                    <AppStart />
                    <CookieConsent
                      enableDeclineButton
                      onAccept={handleAcceptCookie}
                      onDecline={handleDeclineCookie}
                      buttonText="Allow full functionality"
                      declineButtonText="Restrict to essential"
                      buttonStyle={{
                        background: "#5FF646",
                        color: "#222222",
                        fontWeight: "bold",
                      }} // styling for the "Allow all" button
                      declineButtonStyle={{
                        background: "lightgray",
                        color: "darkgray",
                        fontWeight: "bold",
                      }} // styling for the "Only necessary cookies" button
                      contentStyle={{ color: "white", padding: "0px 20px" }}
                    >
                      🍪 We use cookies and related tech to get you precise vibe
                      matches in your area. Click 'allow' to get the optimal
                      experience, your privacy is important to us!
                      <span style={{ color: "gray" }}>
                        -&gt;{" "}
                        <a
                          href={process.env.REACT_APP_POLICIES_URL}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ color: "gray" }}
                        >
                          Policies
                        </a>
                      </span>
                    </CookieConsent>
                  </BrowserRouter>
                  <ToastContainer />
                </IPFSContextProvider>
              </SocketProvider>
            </PersistGate>
          </Provider>
        </Auth0Provider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
