import React from "react";

function PictureIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="28"
      fill="none"
      viewBox="0 0 42 28"
    >
      <g fill="#000" clipPath="url(#clip0_19_15790)">
        <path d="M5.771 16.015L1.29 20.579C-1.422 23.339.533 28 4.402 28h33.196c3.869 0 5.824-4.661 3.113-7.421L30.23 9.906a4 4 0 00-5.708 0l-6.927 7.053a3.636 3.636 0 01-5.188 0l-.927-.944a4 4 0 00-5.708 0z"></path>
        <circle cx="14" cy="5" r="5"></circle>
      </g>
      <defs>
        <clipPath id="clip0_19_15790">
          <path fill="#fff" d="M0 0H42V28H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default PictureIcon;
