import React from "react";
import ReactLoading from "react-loading";

interface Props {
  backgroundColor?: string;
}

export const RectangleLoadingAnimation = ({ backgroundColor }: Props) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ReactLoading
        type={"bars"}
        color={backgroundColor ?? "rgba(0, 0, 0, 0.1)"}
        height={100}
        width={100}
      />
    </div>
  );
};
