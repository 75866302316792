import React, {
  createContext,
  useEffect,
  useContext,
  useState
} from 'react';
import { create as ipfsHttpClient, IPFSHTTPClient } from 'ipfs-http-client';
import { useCookies } from 'react-cookie';
import { sha256 } from 'js-sha256';
import {
  getIPFSURL,
  sizeToString,
  _arrayBufferToBase64
} from 'shared/utils-IPFS/functions';
import axios from 'axios';
import {Buffer} from 'buffer';

type IPFSContextType = {
  isUploading: boolean;
  progress: number;
  uploadWithFileName: (file, animation) => void;
  upload: (file) => void;
  uploadBase64: (base64String, type, path) => void;
};

export const IPFSContext = createContext<IPFSContextType | null>(null);

type IPFSContextProviderProps = {
  children: any;
};

export const IPFSContextProvider: React.FunctionComponent<
  IPFSContextProviderProps
> = ({ children }) => {
  const [ipfs, setIpfs] = useState<IPFSHTTPClient>();
  const [multiAddr, setMultiAddr] = useState(getIPFSURL());
  const [cookies] = useCookies(['accessToken']);
  console.log(cookies.accessToken)
  const token = cookies.accessToken ?? '';
  const [showUploadingModal, setShowUploadingModal] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    if (!multiAddr) return;
    let configuration = { url: multiAddr, headers: {} };
    if (token) configuration.headers = { Authorization: 'Bearer ' + token };
    const ipfsInstance = ipfsHttpClient({ ...configuration });
    setIpfs(ipfsInstance);
  }, [multiAddr, token]);

  const uploadWithFileName = async (newFile, animation = true) => {
    if (newFile && newFile.name) {
      setIsUploading(true);
      setShowUploadingModal(animation);
      const fileDetails = {
        path: newFile.name,
        content: newFile
      };

      console.log('File size to Upload', sizeToString(newFile.size));
      const options = {
        wrapWithDirectory: true,
        progress: (prog) => {
          const value = ((100.0 * prog) / newFile.size).toFixed(0);
          setProgress(parseInt(value));
          console.log(`IPFS Upload: ${value}%`);
        }
      };

      try {
        const added = await ipfs?.add(fileDetails, options);
        console.log('The File is Uploaded Successfully to IPFS');
        setShowUploadingModal(false);
        setProgress(0);
        return added;
      } catch (err) {
        setShowUploadingModal(false);
        setProgress(0);
        console.error(err);
      }
    }
  };

  const upload = async (file) => {
    try {
      setIsUploading(true);
      setShowUploadingModal(true);
      const added = await ipfs?.add(file, {
        progress: (prog) => {
          const value = ((100.0 * prog) / file.size).toFixed(0);
          setProgress(parseInt(value));
          console.log(`IPFS Upload: ${value}%`);
        },
        pin: false
      });
      console.log('IPFS Upload: 100%');
      setShowUploadingModal(false);
      setProgress(0);
      return added;
    } catch (err) {
      console.error(err);
      setShowUploadingModal(false);
      setProgress(0);
    }
  };

  const onUploadProgress = (progressEvent) => {
    const { loaded, total } = progressEvent;
    let percent = Math.floor((loaded * 100) / total);
    if (percent < 100) {
      setProgress(percent);
      console.log(`${loaded} bytes of ${total} bytes. ${percent}%`);
    }
  };

  const uploadBase64 = async (
    base64String: string,
    type: string,
    path: string
  ) => {
    try {
      setIsUploading(true);
      const SERVER_URL = process.env.REACT_APP_SERVER_URL ?? '';
      const content = base64String.split(',')[1];
      const contentHash = await sha256(base64String);
      const filePath = contentHash + `${path === '' ? '' : '.' + path}`;
      console.log({ filePath });
      console.log('Bucket Uploading...', token);
      const response = await axios.post(
        SERVER_URL + '/utils/getUploadUrl',
        {
          key: filePath
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          }
        }
      );
      const signedUrl = response.data.url;

      const buffer = Buffer.from(content,'base64');
      await axios({
        method: 'PUT',
        url: signedUrl,
        data: buffer,
        headers: { 'Content-Type': type, 'Content-Encoding': 'base64' },
      });

      const imageUrl = signedUrl.substring(0, signedUrl.indexOf('?') - 0);
      console.log(imageUrl);
      setIsUploading(false);
      return imageUrl;

    } catch (e) {}
  };

  const context = {
    isUploading,
    progress,
    uploadWithFileName,
    upload,
    uploadBase64
  };

  return (
    <IPFSContext.Provider value={context}>{children}</IPFSContext.Provider>
  );
};

export const useContextIPFS = () => {
  const context = useContext(IPFSContext);
  if (!context) {
    throw new Error(
      'useContextIPFS hook must be used inside IPFSContextProvider'
    );
  }

  return context;
};
