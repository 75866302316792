import i18n from "i18next";
import { formatDistance, Locale } from "date-fns";
import { es, de, enUS } from "date-fns/locale";

export const getArrFromString = (str?: string, keyWord = ";") => {
  if (str) {
    return str.split(keyWord);
  } else {
    return [];
  }
};

export const getDeviceType = () => {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return "android";
  }

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    return "ios";
  }

  return "desktop";
};

export const getFormatedDate = (timeStamp: string) => {
  if (!!timeStamp) {
    const date = new Date(timeStamp);
    let locale: Locale = enUS;
    const currentLng = i18n.language;

    switch (currentLng) {
      case "de":
        locale = de;
        break;
      case "es":
        locale = es;
        break;
      default:
        locale = enUS;
    }

    return formatDistance(date, new Date(), {
      addSuffix: true,
      locale: locale,
    });
  } else {
    return "";
  }
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
