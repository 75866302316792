import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./index.styles.scss";
import { useDispatch, useSelector } from "react-redux";
import { ProgressIndicatorIcon } from "assets/svg/ProgressIndicatorIcon";
import { RewardEmptyIcon } from "assets/svg/RewardEmptyIcon";
import { RewardItemBg } from "assets/svg/RewardItemBg";
import ArrowRightIcon from "assets/svg/ArrowRightIcon";
import { apiGetExtensionLevel } from "shared/api/extensions";
import CheckIcon from "assets/svg/CheckIcon";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import { RootState } from "redux/interfaces";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import { RewardCodeBody } from "components/Main/RewardCode/RewardCodeBody";
import { useTranslation } from "react-i18next";

export const RewardModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoaded, setIsLoaded] = useState(false);
  const [level, setLevel] = useState(0);
  const [nextBand, setNextBand] = useState(0);
  const [accepted, setAccepted] = useState(0);
  const [arrReward, setArrReward] = useState<Array<any>>([]);
  const [nameTextWidth, setNameTextWidth] = useState(0);
  const [oGroup, setOGroup] = useState(null);

  const { userData, userSocials } = useSelector(
    (state: RootState) => state.app
  );

  function getTextWidth(text, font = "Outfit-Medium") {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");

    if (context) {
      context.font = font || getComputedStyle(document.body).font;

      return context.measureText(text).width;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const res = await apiGetExtensionLevel({ aggregated: 1 });

    if (res.success) {
      setIsLoaded(true);
      setLevel(res.level);
      setAccepted(res.accepted);
      setNextBand(res.nextBand);
      setArrReward(res.rewards);
      setOGroup(res.ogroup);

      const newUserData = { ...userData, level: res.level };
      dispatch(
        appAction.loadUserInfoSuccess({ userInfo: newUserData, userSocials })
      );
    }
  };

  const onHide = () => {
    dispatch(appAction.toogleRewardModal(false));
  };

  const onViewHistory = () => {
    onHide();
    dispatch(appAction.toogleRewardHistoryModal(true));
  };

  const onInvite = () => {
    onHide();
    dispatch(appAction.toogleInvitationModal(true));
  };

  const renderMainContent = () => {
    return (
      <div className="container1">
        <div className="row_align_items row_space_between">
          <div className="row_align_items">
            <RewardEmptyIcon width={70} height={70} />
            <div className="right_view">
              <div className="number-view font-medium font14">{`${accepted}/${nextBand}`}</div>
              <div className="font-bold font20">
                {t("invite_1_friend", { number: nextBand })}
              </div>
            </div>
          </div>
          <div className="row_align_items" style={{ gap: 12 }}>
            <div className="item_btn clickable" onClick={onViewHistory}>
              <div className="item_btn_txt font-regular font16">
                {t("history")}
              </div>
            </div>
            <div className="item_btn clickable" onClick={onInvite}>
              <div className="item_btn_txt font-regular font16">
                {t("invite")}
              </div>
            </div>
          </div>
        </div>

        {oGroup ? renderRewards() : renderReferrralCode()}
      </div>
    );
  };

  const renderRewards = () => {
    if (arrReward.length == 0) {
      return (
        <div className="reward_list">
          <div className="font-regular font16">{t("new_rewards_loading")}</div>
        </div>
      );
    } else {
      return (
        <div className="reward_list">
          {arrReward.map((item, index) => {
            return <RewardItem key={`reward_item_${index}`} data={item} />;
          })}
        </div>
      );
    }
  };

  const renderReferrralCode = () => {
    return (
      <div style={{ marginTop: 40 }}>
        <RewardCodeBody
          onCancel={() => {
            onHide();
          }}
          onSuccess={() => {
            onHide();
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    if (userData)
      setNameTextWidth(getTextWidth(userData?.name ?? userData?.username));
  }, [userData]);

  const renderMainBody = () => {
    return (
      <div className="modal_body">
        <div className="carosuel_body">
          <div className="modal_header">
            <div className="font-bold font20">
              {t("level_display", { level })}
            </div>
            <div
              style={{ position: "absolute", right: 32, top: 32 }}
              className="clickable"
              onClick={onHide}
            >
              <RedCloseIcon />
            </div>
          </div>
          <div className="modal_progrees_bar">
            <img
              src={require("assets/image/bg_progress.png")}
              className="progrees_bar_bg"
            />
            <div
              style={{
                position: "absolute",
                top: 6,
                left: `${(level / 4) * 100}%`,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <ProgressIndicatorIcon />
            </div>
          </div>
          {renderMainContent()}
        </div>
      </div>
    );
  };

  const renderLoading = () => {
    return (
      <div
        className="modal_body"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <RectangleLoadingAnimation />
      </div>
    );
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_reward">
        <div
          className="modal_content"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <img
            src={require("assets/image/triangle_top_icon.png")}
            style={{
              width: 32,
              height: 32,
              position: "fixed",
              right: nameTextWidth + 180,
              top: 4,
            }}
          />
          {isLoaded ? renderMainBody() : renderLoading()}
        </div>
      </div>
    </OpacityModal>
  );
};

interface RewardItemProps {
  data: any;
}

const RewardItem = ({ data }: RewardItemProps) => {
  const dispatch = useDispatch();

  const onDetail = () => {
    if (data.is_reward_blocked === 1) {
    } else {
      dispatch(appAction.toogleRewardDetailModal(true, data));
    }
  };

  const renderTick = () => {
    return (
      <div className="tick_icon">
        <CheckIcon width={14} height={12} />
      </div>
    );
  };

  const renderLock = () => {
    return <div className="lock_icon">🔒</div>;
  };

  return (
    <div>
      <div
        className={`reward_item ${
          data.is_reward_blocked === 1 ? "" : "clickable"
        } ${data.is_reward_blocked == 1 && "reward_item_blur"}`}
        onClick={onDetail}
        data-tooltip-id={`description_${data.id}`}
        data-tooltip-html={`<div class="tooltipe-layout"><div class="font-bold font16">${data.name}</div> <div class="font-regular font14">${data.description}</div></div>`}
      >
        <div className="lvl_view font-bold font12">{`Lvl ${data.level}`}</div>
        <div className="icon_container">
          <RewardItemBg />
          <div className="font24 emoji_container">{data.emoji}</div>
          {data.is_redeemed === 1 && renderTick()}
          {data.is_reward_blocked === 1 && renderLock()}
        </div>
        <div className="reward_name font-medium font16">{data.name}</div>
      </div>
      <ReactTooltip id={`description_${data.id}`} html="true" />
    </div>
  );
};
