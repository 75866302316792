import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";

import "./index.styles.scss";
import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import { useDispatch } from "react-redux";
import CloseIcon from "assets/svg/CloseIcon";
import { useNavigate } from "react-router-dom";
import { RootNames } from "shared/routes/rootUrl";
import { useTranslation } from "react-i18next";

export const ConfirmDownloadAppModal = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onHide = () => {
    dispatch(appAction.toogleConfirmDownloadAppModal(false));
  };

  const onCancel = () => {
    onHide();
    navigate(RootNames.qr);
  };

  const onConfirm = () => {
    onHide();
    navigate(RootNames.qr + "/mobile");
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items" style={{ width: "100%" }}>
        <div
          className="font-bold font20"
          style={{ marginLeft: 40, textAlign: "center", flex: 1 }}
        ></div>
        <div className="close-btn clickable" onClick={onHide}>
          <CloseIcon />
        </div>
      </div>
    );
  };

  return (
    <OpacityModal
      onClick={() => {
        // onHide();
      }}
    >
      <div className="modal_confirm_download_app">
        <div
          className="modal_body"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {/* {renderHeader()} */}
          <div className="content_view" style={{ marginTop: 16 }}>
            <div
              className="font-bold"
              style={{ fontSize: 24 }}
              dangerouslySetInnerHTML={{ __html: t("got_the_mobile_app") }}
            ></div>
          </div>

          <div className="row_align_items" style={{ gap: 16 }}>
            <div className="btn-cancel clickable" onClick={onCancel}>
              <div className="btn-txt font-regular font16">{t("no")}</div>
            </div>
            <div className="btn-confirm clickable" onClick={onConfirm}>
              <div className="btn-txt font-bold font16">{t("yes")}</div>
            </div>
          </div>
        </div>
      </div>
    </OpacityModal>
  );
};
