import React, { useEffect, useState } from "react";
import appAction from "redux/app/action";

import "./index.styles.scss";
import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import CheckIcon from "assets/svg/CheckIcon";
import { useDispatch } from "react-redux";
import CloseIcon from "assets/svg/CloseIcon";
import { apiRedeemReward } from "shared/api/extensions";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";

interface Props {
  data: any;
}

export const RewardDetailModal = ({ data }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      loadData();
    }
  }, [data]);

  const loadData = async () => {
    const res = await apiRedeemReward({ reward: data.id });

    if (res.success) {
    } else {
      showToast("error", res.error);
    }
  };

  const onHide = () => {
    dispatch(appAction.toogleRewardDetailModal(false, null));
  };

  const renderHeader = () => {
    return (
      <div className="row_align_items" style={{ width: "100%" }}>
        <div
          className="font-bold font20"
          style={{ marginLeft: 40, textAlign: "center", flex: 1 }}
        >
          {t("how_to_redeem")}
        </div>
        <div className="close-btn clickable" onClick={onHide}>
          <CloseIcon />
        </div>
      </div>
    );
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_reward_detail">
        <div
          className="modal_body"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {renderHeader()}
          <div className="content_view">
            <div className="font-regular font16">
              {data?.redeemption_info.split("\n").map((iStr, index) => {
                return (
                  <div key={`desc_${index}`}>{iStr === "" ? <br /> : iStr}</div>
                );
              })}
            </div>
          </div>

          <div className="btn-thank clickable" onClick={onHide}>
            <div className="btn-txt font-bold font16">
              {t("claim_to_redeem")}
            </div>
          </div>
        </div>
      </div>
    </OpacityModal>
  );
};
