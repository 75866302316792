import React, { useEffect, useState } from "react";

import "./index.styles.scss";
import { useTranslation } from "react-i18next";
import RedditIcon from "assets/svg/socials/RedditIcon";
import TelegramIcon from "assets/svg/socials/TelegramIcon";
import InstagramIcon from "assets/svg/socials/InstagramIcon";
import WhatsappIcon from "assets/svg/socials/WhatsappIcon";
import { LoadingCircular } from "components/Common/LoadingCircular";
import RedCloseIcon from "assets/svg/RedCloseIcon";
import ArrowRightIcon from "assets/svg/ArrowRightIcon";
import DiscordIcon from "assets/svg/socials/DiscordIcon";
import { apiGetActivationLink } from "shared/api/util";
import { apiDeletionSocialHandle } from "shared/api/user";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "redux/interfaces";
import appAction from "redux/app/action";
import { showToast } from "components/Common/PrimaryToast";

interface Props {
  type: "discord" | "instagram" | "whatsapp" | "telegram";
  socialInfo: any;
}

export const CommunicationItem = ({ type, socialInfo }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userId } = useSelector((state: RootState) => state.app);

  const [isConnected, setIsConnected] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  useEffect(() => {
    if (socialInfo && socialInfo.id) {
      setIsConnected(true);
    } else {
      setIsConnected(false);
    }
  }, [socialInfo]);

  const onConnectSocial = async () => {
    if (isConnected) return;

    if (type === "discord") {
      window.open(process.env.REACT_APP_DISCORD_URL, "_blank");
    } else if (type === "instagram" || type === "telegram" || "whatsapp") {
      const res = await apiGetActivationLink({ type });
      if (res.success) {
        const url = res.link;
        window.open(url, "_blank");
      } else {
        console.warn("No link provided by BE");
      }
    }
  };

  const onDelete = async () => {
    if (!isDeleting) {
      setIsDeleting(true);

      const res = await apiDeletionSocialHandle({ id: socialInfo.id });

      if (res.success && userId) {
        showToast("success", t("msg_success_account_disconnect"));
        dispatch(appAction.loadUserInfo(userId!));
      } else {
        showToast("error", t("msg_failed_account_disconnect"));
      }

      setIsDeleting(false);
    }
  };

  const renderIcon = () => {
    if (type === "discord") {
      return <DiscordIcon size={28} color="black" />;
    } else if (type === "instagram") {
      return <InstagramIcon size={24} color="black" />;
    } else if (type === "whatsapp") {
      return <WhatsappIcon size={24} color="black" />;
    } else if (type === "telegram") {
      return <TelegramIcon size={24} color="black" />;
    }
  };

  const renderConnectName = () => {
    if (type === "discord") {
      return t("connect_discord");
    } else if (type === "instagram") {
      return t("connect_instagram");
    } else if (type === "whatsapp") {
      return t("connect_whatsapp");
    } else if (type === "telegram") {
      return t("connect_telegram");
    }
  };

  const renderName = () => {
    if (!isConnected) {
      return <div>{renderConnectName()}</div>;
    } else {
      return (
        <div>{!!socialInfo?.handle ? socialInfo?.handle : t("connected")}</div>
      );
    }
  };

  const renderAction = () => {
    if (isConnecting || isDeleting) {
      return (
        <div>
          <LoadingCircular size={16} color="main" />
        </div>
      );
    }

    if (isConnected) {
      return (
        <div className="clickable" onClick={() => onDelete()}>
          <RedCloseIcon />
        </div>
      );
    } else {
      return (
        <div className="clickable">
          <ArrowRightIcon />
        </div>
      );
    }
  };

  return (
    <div
      className="communication_item clickable"
      onClick={() => onConnectSocial()}
    >
      <div className="row_align_items">
        <div className="social-icon">{renderIcon()}</div>
        <div className="social-name font-regular font14">{renderName()}</div>
      </div>
      <div className="social-action-icon clickable">{renderAction()}</div>
    </div>
  );
};
