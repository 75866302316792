import React, { useState } from "react";
import appAction from "redux/app/action";
import SearchInput from "../SearchInput/SearchInput";
import GenericAvatar from "../GenericAvatar/GenericAvatar";
import { useNavigate } from "react-router-dom";
import { RootNames } from "shared/routes/rootUrl";
import { RootState } from "redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import "./StickyHeader.styles.scss";

export const StickyHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    userData,
    showInvitationModal,
    showRewardListModal,
    showRewardModal,
    showSuccessInvitationModal,
    showSearchModal,
    showQRModal,
  } = useSelector((state: RootState) => state.app);
  const [showModal, setShowModal] = useState(false);

  const onViewMain = () => {
    navigate(RootNames.profile);
  };

  const onViewProfile = () => {
    navigate(RootNames.settings);
  };

  const onViewReward = () => {
    if (
      showInvitationModal ||
      showRewardListModal ||
      showRewardModal ||
      showSuccessInvitationModal ||
      showSearchModal ||
      showQRModal
    ) {
      dispatch(appAction.toogleCreatingMoodModal(false));
      dispatch(appAction.toogleRewardHistoryModal(false));
      dispatch(appAction.toogleRewardModal(false));
      dispatch(appAction.toogleInvitationModal(false));
      dispatch(appAction.toogleInvitationSuccessModal(false));
      dispatch(appAction.toogleSearchModal(false));
      dispatch(appAction.onChangedSearch(""));
      dispatch(appAction.toogleUserQRModal(false));
    } else {
      dispatch(appAction.toogleRewardModal(true));
    }
  };

  return (
    <div className="header-container sticky-header">
      <div
        className="header-left row_align_items clickable"
        onClick={onViewMain}
      >
        <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
        <div className="font-app-title font28">
          {process.env.REACT_APP_APP_NAME}
        </div>
      </div>
      <div className="header-center">
        <SearchInput />
      </div>

      <div className="header-right">
        <div
          style={{ marginRight: 32 }}
          className="clickable"
          onClick={onViewReward}
        >
          <div style={{ fontSize: 32 }}>🎁</div>
        </div>
        <div onClick={onViewProfile}>
          <GenericAvatar
            name={userData?.name ?? userData?.username}
            image={userData?.image}
            level={userData?.level}
          />
        </div>
      </div>
    </div>
  );
};
