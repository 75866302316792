import React, { useMemo, useState, useEffect, useRef } from "react";

import "./index.styles.scss";
import { FirstTag } from "./FirstTag";
import { PlusTag } from "./PlusTag";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { getArrFromString } from "shared/utils/utils";

interface Props {
  data: any;
}

const MAX_DISPLAY_COUNT = 3;

export const TagView = ({ data }: Props) => {
  const arrTag = useMemo(() => {
    if (data.subtopic_names) {
      const arrTmpName = getArrFromString(data.subtopic_names, ";;;");
      const arrTmpId = getArrFromString(data.subtopic_ids, ";;;");

      return arrTmpName.map((item, index) => {
        return {
          id: arrTmpId[index],
          name: arrTmpName[index],
        };
      });
    } else {
      return [];
    }
  }, [data]);

  const firstTag = useMemo(() => {
    if (arrTag.length > 0) return arrTag[0];
    return null;
  }, [arrTag]);

  const moreTagsTooltip = useMemo(() => {
    if (arrTag.length > 1) {
      const moreTags = arrTag.slice(1, arrTag.length);
      let htmlTxt = "";
      for (var i = 0; i < moreTags.length; i++) {
        htmlTxt = htmlTxt + "- " + moreTags[i].name + "<br />";
      }

      return htmlTxt;
    }
    return null;
  }, [arrTag]);

  const plusCount = useMemo(() => {
    if (data.subtopic_total > 1) return data.subtopic_total - 1;

    return 0;
  }, [arrTag]);

  return (
    <div className="tag_view">
      {firstTag && <FirstTag data={firstTag} />}
      <div style={{ flex: 1 }}></div>
      {data.subtopic_total > 1 && (
        <div
          className="clickable"
          data-tooltip-id={`tag_more_${data.id}`}
          data-tooltip-html={moreTagsTooltip}
        >
          <PlusTag data={plusCount} interestId={data.id} />
        </div>
      )}
      <ReactTooltip
        id={`tag_more_${data.id}`}
        html={"true"}
        style={{ zIndex: 99 }}
      />
      {firstTag && (
        <ReactTooltip
          id={`tag_first_item_${firstTag.id}`}
          style={{ zIndex: 99 }}
        />
      )}
    </div>
  );
};
