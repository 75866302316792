import React from "react";

function TelegramIcon(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      fill="none"
      viewBox="0 0 23 22"
    >
      <path
        fill={props.color ?? "#fff"}
        d="M21.765 1.428a2.048 2.048 0 00-2.078-.324L1.766 8.339a2.043 2.043 0 00.104 3.818l3.625 1.261 2.02 6.682c.028.089.068.174.119.252.008.012.019.021.027.033a.988.988 0 00.281.265c.095.063.2.11.31.136l.013.001.006.003c.067.014.135.02.203.02l.018-.003a.992.992 0 00.302-.052c.022-.008.041-.02.063-.03a.994.994 0 00.205-.114l.152-.129 2.702-2.983 4.03 3.122c.355.276.792.427 1.242.427a2.054 2.054 0 002.007-1.633l3.263-16.017a2.03 2.03 0 00-.693-1.97zM8.87 13.736a.994.994 0 00-.272.506l-.31 1.504-.784-2.593 4.065-2.117-2.699 2.7zm8.302 5.304l-4.763-3.69a1 1 0 00-1.353.12l-.866.955.306-1.487 7.083-7.083a1 1 0 00-1.169-1.593L6.245 11.554 2.52 10.191 20.499 3 17.172 19.04z"
      ></path>
    </svg>
  );
}

export default TelegramIcon;
