import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const LogoutIcon = (props: IconProps) => {
    return (
        <svg className={props.className} width={props.width ?? 20} height={props.height ?? 20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.41675 6.30001C7.67508 3.30001 9.21675 2.07501 12.5917 2.07501H12.7001C16.4251 2.07501 17.9167 3.56668 17.9167 7.29168V12.725C17.9167 16.45 16.4251 17.9417 12.7001 17.9417H12.5917C9.24175 17.9417 7.70008 16.7333 7.42508 13.7833" stroke={props.color ?? "#000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M12.4999 10H3.0166" stroke={props.color ?? "#000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M4.87492 7.20834L2.08325 10L4.87492 12.7917" stroke={props.color ?? "#000"} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};