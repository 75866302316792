import React from "react";
import appAction from "redux/app/action";

import "./index.styles.scss";
import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import CheckIcon from "assets/svg/CheckIcon";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

export const SuccessInvitationModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onHide = () => {
    dispatch(appAction.toogleInvitationSuccessModal(false));
  };

  return (
    <OpacityModal
      onClick={() => {
        onHide();
      }}
    >
      <div className="modal_success_invitation">
        <div
          className="modal_body"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="success_icon">
            <CheckIcon />
          </div>
          <div className="font-bold font20" style={{ marginTop: 18 }}>
            {t("invite_on_the_way")}
          </div>
          <div
            className="font-regular font14"
            style={{
              marginTop: 16,
              marginLeft: 32,
              marginRight: 32,
              textAlign: "center",
            }}
          >
            {t("shot_your_invite")}
          </div>
          <div className="btn-thank clickable" onClick={onHide}>
            <div className="btn-txt font-regular font16">{t("got_it")}</div>
          </div>
        </div>
      </div>
    </OpacityModal>
  );
};
