import React, { useEffect, useState } from "react";

import { IconProps } from "../../shared/utils/type.def";

export const BgTopicCard = (props: IconProps) => {
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0,
  });
  const getImageDimension = () => {
    if (!props.image) {
      return;
    }
    const img = new Image();
    img.src = props.image;
    img.onload = () => {
      setImageDimensions({
        height: img.height,
        width: img.width,
      });
    };
  };

  useEffect(() => {
    getImageDimension();
  }, [props.image]);

  const mainColor = props.isTrend ? "#FF2E00" : "#65D0FF";

  const renderImage = () => {
    return (
      <image
        href={props.image}
        style={
          imageDimensions.width > imageDimensions.height
            ? { height: "100%" }
            : { width: "100%" }
        }
      />
    );
  };

  const renderImageMask = () => {
    return (
      <svg
        width={"98%"}
        viewBox="-6 0 394 279"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="topic_mask_shape">
          <path
            d="M194.026 273.58L193.999 273.579L193.973 273.58C164.445 275.191 135.657 276.364 109.394 277.218C89.4555 277.866 74.5309 278.35 62.8437 277.811C51.1587 277.271 42.7646 275.709 35.871 272.296C22.6374 265.744 14.3708 257.437 7.88374 244.172C4.50895 237.271 3.02116 227.89 2.61204 214.212C2.20314 200.541 2.87183 182.649 3.76505 158.759C3.9178 154.674 4 150.667 4 146.773C4 139.901 3.74388 132.433 3.29575 124.626C1.91475 100.566 0.880805 82.548 1.0558 68.6879C1.23085 54.8228 2.61676 45.1868 6.03241 37.8636C12.5558 23.8774 21.2127 14.8708 34.9296 7.79877C42.1167 4.09329 51.1233 2.31867 63.8188 1.57502C76.5145 0.831359 92.841 1.12057 114.648 1.50732C142.722 2.00523 170.927 2.36315 194 2.36315C217.073 2.36315 245.278 2.00523 273.352 1.50732C295.159 1.12057 311.485 0.831359 324.181 1.57502C336.877 2.31867 345.883 4.09329 353.07 7.79877C366.787 14.8708 375.444 23.8774 381.968 37.8636C385.383 45.1868 386.769 54.8228 386.944 68.6879C387.119 82.548 386.085 100.566 384.704 124.626C384.256 132.433 384 139.901 384 146.773C384 150.667 384.082 154.674 384.235 158.759C385.127 182.626 385.795 200.5 385.386 214.16C384.976 227.826 383.487 237.202 380.111 244.104C373.623 257.369 365.35 265.68 352.116 272.229C345.22 275.641 336.827 277.202 325.147 277.739C313.464 278.275 298.546 277.787 278.617 277.134C252.804 276.288 224.177 275.139 194.026 273.58Z"
            fill="white"
          />
        </mask>
        <g mask="url(#topic_mask_shape)">{renderImage()}</g>
      </svg>
    );
  };

  return (
    <div style={{ width: "100%" }}>
      {props.isTrend ? (
        <svg
          style={{ width: "100%" }}
          viewBox="0 0 388 279"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {props.image && props.image.startsWith("https") && (
            <g>{renderImageMask()}</g>
          )}
        </svg>
      ) : (
        <svg
          style={{ width: "100%" }}
          viewBox="0 0 388 279"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <mask
            id="mask0_216_483"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="388"
            height="279"
          >
            <path
              d="M194.026 273.58L193.999 273.579L193.973 273.58C164.445 275.191 135.657 276.364 109.394 277.218C89.4555 277.866 74.5309 278.35 62.8437 277.811C51.1587 277.271 42.7646 275.709 35.871 272.296C22.6374 265.744 14.3708 257.437 7.88374 244.172C4.50895 237.271 3.02116 227.89 2.61204 214.212C2.20314 200.541 2.87183 182.649 3.76505 158.759C3.9178 154.674 4 150.667 4 146.773C4 139.901 3.74388 132.433 3.29575 124.626C1.91475 100.566 0.880805 82.548 1.0558 68.6879C1.23085 54.8228 2.61676 45.1868 6.03241 37.8636C12.5558 23.8774 21.2127 14.8708 34.9296 7.79877C42.1167 4.09329 51.1233 2.31867 63.8188 1.57502C76.5145 0.831359 92.841 1.12057 114.648 1.50732C142.722 2.00523 170.927 2.36315 194 2.36315C217.073 2.36315 245.278 2.00523 273.352 1.50732C295.159 1.12057 311.485 0.831359 324.181 1.57502C336.877 2.31867 345.883 4.09329 353.07 7.79877C366.787 14.8708 375.444 23.8774 381.968 37.8636C385.383 45.1868 386.769 54.8228 386.944 68.6879C387.119 82.548 386.085 100.566 384.704 124.626C384.256 132.433 384 139.901 384 146.773C384 150.667 384.082 154.674 384.235 158.759C385.127 182.626 385.795 200.5 385.386 214.16C384.976 227.826 383.487 237.202 380.111 244.104C373.623 257.369 365.35 265.68 352.116 272.229C345.22 275.641 336.827 277.202 325.147 277.739C313.464 278.275 298.546 277.787 278.617 277.134C252.804 276.288 224.177 275.139 194.026 273.58Z"
              fill="url(#paint0_linear_216_483)"
              stroke="white"
            />
          </mask>
          <g mask="url(#mask0_216_483)">
            <g opacity="0.2" filter="url(#filter0_f_216_483)">
              <path
                d="M2.79657 124.655C0.035428 76.5503 -1.34514 52.4982 5.57928 37.6523C12.1495 23.5657 20.8851 14.4771 34.7005 7.35436C49.2606 -0.152422 71.0592 0.234183 114.656 1.00739C142.73 1.50528 170.932 1.86315 194 1.86315C217.068 1.86315 245.27 1.50528 273.344 1.00739C316.941 0.234183 338.739 -0.152422 353.3 7.35435C367.115 14.4771 375.85 23.5657 382.421 37.6523C389.345 52.4982 387.965 76.5503 385.203 124.655C384.756 132.455 384.5 139.913 384.5 146.773C384.5 150.696 384.584 154.735 384.739 158.854C386.536 206.517 387.434 230.349 380.592 244.345C374.064 257.699 365.716 266.088 352.395 272.682C338.432 279.593 318.526 278.941 278.714 277.637C252.869 276.791 224.2 275.641 194 274.079C164.425 275.693 135.593 276.867 109.297 277.721C69.4658 279.015 49.5502 279.661 35.5915 272.749C22.2721 266.153 13.9298 257.767 7.40324 244.413C0.563477 230.418 1.4627 206.564 3.26115 158.854C3.41642 154.735 3.5 150.696 3.5 146.773C3.5 139.913 3.2443 132.455 2.79657 124.655Z"
                fill="url(#paint1_linear_216_483)"
              />
            </g>
            <g filter="url(#filter1_i_216_483)">
              <path
                d="M2.79657 124.655C0.035428 76.5503 -1.34514 52.4982 5.57928 37.6523C12.1495 23.5657 20.8851 14.4771 34.7005 7.35436C49.2606 -0.152422 71.0592 0.234183 114.656 1.00739C142.73 1.50528 170.932 1.86315 194 1.86315C217.068 1.86315 245.27 1.50528 273.344 1.00739C316.941 0.234183 338.739 -0.152422 353.3 7.35435C367.115 14.4771 375.85 23.5657 382.421 37.6523C389.345 52.4982 387.965 76.5503 385.203 124.655C384.756 132.455 384.5 139.913 384.5 146.773C384.5 150.696 384.584 154.735 384.739 158.854C386.536 206.517 387.434 230.349 380.592 244.345C374.064 257.699 365.716 266.088 352.395 272.682C338.432 279.593 318.526 278.941 278.714 277.637C252.869 276.791 224.2 275.641 194 274.079C164.425 275.693 135.593 276.867 109.297 277.721C69.4658 279.015 49.5502 279.661 35.5915 272.749C22.2721 266.153 13.9298 257.767 7.40324 244.413C0.563477 230.418 1.4627 206.564 3.26115 158.854C3.41642 154.735 3.5 150.696 3.5 146.773C3.5 139.913 3.2443 132.455 2.79657 124.655Z"
                fill="#F0F9FF"
                fillOpacity="0.01"
              />
            </g>
            <path
              d="M194.026 273.58L193.999 273.579L193.973 273.58C164.402 275.194 135.574 276.367 109.281 277.221C89.357 277.868 74.4435 278.352 62.7651 277.813C51.089 277.273 42.7014 275.712 35.8134 272.301C22.593 265.754 14.3305 257.448 7.85246 244.193C4.48178 237.297 2.9976 227.925 2.59264 214.262C2.18791 200.606 2.86129 182.735 3.76079 158.873C3.91627 154.748 4 150.703 4 146.773C4 139.901 3.74388 132.433 3.29575 124.626C1.91475 100.566 0.880805 82.548 1.0558 68.6879C1.23085 54.8228 2.61676 45.1868 6.03241 37.8636C12.5558 23.8774 21.2127 14.8708 34.9296 7.79877C42.1167 4.09329 51.1233 2.31867 63.8188 1.57502C76.5145 0.831359 92.841 1.12057 114.648 1.50732C142.722 2.00523 170.927 2.36315 194 2.36315C217.073 2.36315 245.278 2.00523 273.352 1.50732C295.159 1.12057 311.485 0.831359 324.181 1.57502C336.877 2.31867 345.883 4.09329 353.07 7.79877C366.787 14.8708 375.444 23.8774 381.968 37.8636C385.383 45.1868 386.769 54.8228 386.944 68.6879C387.119 82.548 386.085 100.566 384.704 124.626C384.256 132.433 384 139.901 384 146.773C384 150.703 384.084 154.748 384.239 158.873C385.138 182.712 385.811 200.565 385.405 214.21C384.999 227.862 383.514 237.228 380.143 244.126C373.663 257.38 365.395 265.689 352.173 272.234C345.283 275.644 336.897 277.204 325.225 277.741C313.551 278.277 298.645 277.79 278.731 277.137C252.888 276.291 224.222 275.141 194.026 273.58Z"
              stroke="url(#paint2_linear_216_483)"
            />
            <path
              d="M194.026 273.58L193.999 273.579L193.973 273.58C164.402 275.194 135.574 276.367 109.281 277.221C89.357 277.868 74.4435 278.352 62.7651 277.813C51.089 277.273 42.7014 275.712 35.8134 272.301C22.593 265.754 14.3305 257.448 7.85246 244.193C4.48178 237.297 2.9976 227.925 2.59264 214.262C2.18791 200.606 2.86129 182.735 3.76079 158.873C3.91627 154.748 4 150.703 4 146.773C4 139.901 3.74388 132.433 3.29575 124.626C1.91475 100.566 0.880805 82.548 1.0558 68.6879C1.23085 54.8228 2.61676 45.1868 6.03241 37.8636C12.5558 23.8774 21.2127 14.8708 34.9296 7.79877C42.1167 4.09329 51.1233 2.31867 63.8188 1.57502C76.5145 0.831359 92.841 1.12057 114.648 1.50732C142.722 2.00523 170.927 2.36315 194 2.36315C217.073 2.36315 245.278 2.00523 273.352 1.50732C295.159 1.12057 311.485 0.831359 324.181 1.57502C336.877 2.31867 345.883 4.09329 353.07 7.79877C366.787 14.8708 375.444 23.8774 381.968 37.8636C385.383 45.1868 386.769 54.8228 386.944 68.6879C387.119 82.548 386.085 100.566 384.704 124.626C384.256 132.433 384 139.901 384 146.773C384 150.703 384.084 154.748 384.239 158.873C385.138 182.712 385.811 200.565 385.405 214.21C384.999 227.862 383.514 237.228 380.143 244.126C373.663 257.38 365.395 265.689 352.173 272.234C345.283 275.644 336.897 277.204 325.225 277.741C313.551 278.277 298.645 277.79 278.731 277.137C252.888 276.291 224.222 275.141 194.026 273.58Z"
              stroke="url(#paint3_linear_216_483)"
            />
            <path
              d="M194.026 273.58L193.999 273.579L193.973 273.58C164.402 275.194 135.574 276.367 109.281 277.221C89.357 277.868 74.4435 278.352 62.7651 277.813C51.089 277.273 42.7014 275.712 35.8134 272.301C22.593 265.754 14.3305 257.448 7.85246 244.193C4.48178 237.297 2.9976 227.925 2.59264 214.262C2.18791 200.606 2.86129 182.735 3.76079 158.873C3.91627 154.748 4 150.703 4 146.773C4 139.901 3.74388 132.433 3.29575 124.626C1.91475 100.566 0.880805 82.548 1.0558 68.6879C1.23085 54.8228 2.61676 45.1868 6.03241 37.8636C12.5558 23.8774 21.2127 14.8708 34.9296 7.79877C42.1167 4.09329 51.1233 2.31867 63.8188 1.57502C76.5145 0.831359 92.841 1.12057 114.648 1.50732C142.722 2.00523 170.927 2.36315 194 2.36315C217.073 2.36315 245.278 2.00523 273.352 1.50732C295.159 1.12057 311.485 0.831359 324.181 1.57502C336.877 2.31867 345.883 4.09329 353.07 7.79877C366.787 14.8708 375.444 23.8774 381.968 37.8636C385.383 45.1868 386.769 54.8228 386.944 68.6879C387.119 82.548 386.085 100.566 384.704 124.626C384.256 132.433 384 139.901 384 146.773C384 150.703 384.084 154.748 384.239 158.873C385.138 182.712 385.811 200.565 385.405 214.21C384.999 227.862 383.514 237.228 380.143 244.126C373.663 257.38 365.395 265.689 352.173 272.234C345.283 275.644 336.897 277.204 325.225 277.741C313.551 278.277 298.645 277.79 278.731 277.137C252.888 276.291 224.222 275.141 194.026 273.58Z"
              stroke="url(#paint4_linear_216_483)"
            />
          </g>
          <defs>
            <filter
              id="filter0_f_216_483"
              x="-145.482"
              y="-145.405"
              width="678.963"
              height="569.968"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="73.0091"
                result="effect1_foregroundBlur_216_483"
              />
            </filter>
            <filter
              id="filter1_i_216_483"
              x="0.536499"
              y="-3.38669"
              width="386.927"
              height="281.932"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="-4" />
              <feGaussianBlur stdDeviation="3" />
              <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
              />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.396078 0 0 0 0 0.815686 0 0 0 0 1 0 0 0 0.3 0"
              />
              <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_216_483"
              />
            </filter>
            <linearGradient
              id="paint0_linear_216_483"
              x1="181"
              y1="-2"
              x2="181"
              y2="280"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="white" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_216_483"
              x1="194"
              y1="-2"
              x2="194"
              y2="280"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#7EE0FF" />
              <stop offset="1" stopColor="#00C2FF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_216_483"
              x1="210.5"
              y1="281"
              x2="210.5"
              y2="269"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={mainColor} />
              <stop offset="1" stopColor={mainColor} stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_216_483"
              x1="100.5"
              y1="-127.5"
              x2="210.5"
              y2="27.5"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor={mainColor} />
              <stop offset="1" stopColor={mainColor} stopOpacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_216_483"
              x1="390.5"
              y1="5.50001"
              x2="360"
              y2="49"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#00FF66" />
              <stop offset="1" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
          {props.image && props.image.startsWith("https") && (
            <g>{renderImageMask()}</g>
          )}
        </svg>
      )}
    </div>
  );
};
