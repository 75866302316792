import React, { useMemo, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";

import "./index.styles.scss";
import { apiHideInterest, apiUnHideInterest } from "shared/api/topics";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";
import RoundedIconButton from "components/Common/RoundedIconButton/RoundedIconButton";
import { ShowIcon } from "assets/svg/ShowIcon";
import { HideIcon } from "assets/svg/HideIcon";
import { useSelector } from "react-redux";
import { RootState } from "redux/interfaces";
import { useNavigate } from "react-router-dom";

interface Props {
  data: any;
  enableAction?: boolean;
  fontSize?: number;
  onRefresh: () => void;
}

export const CardSubInterest = ({
  data,
  enableAction = true,
  fontSize = 20,
  onRefresh,
}: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userData } = useSelector((state: RootState) => state.app);

  const [isHidden, setIsHidden] = useState(data.is_hidden);
  const [isChangingVisual, setIsChangingVisual] = useState(false);

  const onVisableAction = (e) => {
    e.stopPropagation();

    if (!isChangingVisual) {
      if (isHidden) {
        onShowInterest();
      } else {
        onHideInterest();
      }
    }
  };

  const onHideInterest = async () => {
    setIsChangingVisual(true);

    const res = await apiHideInterest({ vibe: data.id });
    if (res.success) {
      showToast("success", t("msg_interest_hidden"));
      setIsHidden(1);
      onRefresh();
    } else {
      showToast("error", "Sorry, hiding interest failed - try again!");
    }

    setIsChangingVisual(false);
  };

  const onShowInterest = async () => {
    setIsChangingVisual(true);

    const res = await apiUnHideInterest({ vibe: data.id });
    if (res.success) {
      showToast("success", t("msg_interest_showing_again"));
      setIsHidden(0);
      onRefresh();
    } else {
      showToast("error", "Sorry, unhiding interest failed - try again!");
    }

    setIsChangingVisual(false);
  };

  const onDetail = () => {
    navigate(`/subInterestDetail/${data.id}`);
  };

  const isOwner = useMemo(() => {
    if (data.item_id === userData?.id) {
      return true;
    }

    return false;
  }, [data]);

  const renderContent = () => {
    return (
      <div className="card_subinterest_content">
        <div
          className="interest_name font-regular"
          style={{ fontSize: fontSize }}
          data-tooltip-id={`subtopic_name_${data.id}`}
          data-tooltip-content={data.topic_name}
        >
          {data.topic_name}
        </div>
        {isOwner && enableAction && (
          <div
            className="clickable"
            onClick={onVisableAction}
            data-tooltip-id={
              isHidden === 0
                ? `topic_show_${data.topic}`
                : `topic_hide_${data.topic}`
            }
            data-tooltip-content={isHidden === 0 ? "Show" : "Hide"}
          >
            <RoundedIconButton
              children={isHidden == 0 ? <ShowIcon /> : <HideIcon />}
              color={"black"}
              rounded="circle"
              syncColor={true}
              isLoading={isChangingVisual}
              style={{ opacity: 0.7 }}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="card_subinterest clickable" onClick={onDetail}>
      <img
        src={require("./../../../../assets/image/bg_card_subinterest.png")}
        className="bg_img"
      />
      {renderContent()}
      <ReactTooltip id={`subtopic_name_${data.id}`} />
    </div>
  );
};
