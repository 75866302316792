import React, { useState } from "react";

import "./index.styles.scss";
import appAction from "redux/app/action";
import { apiUpdateEmail } from "shared/api/user";
import { TextField } from "@mui/material";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { useNavigate } from "react-router-dom";
import { Verification } from "components/Auth/Verification";
import { RootState } from "redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";
import { validateEmail } from "shared/utils/utils";

export const UpdateEmailScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userData, userSocials } = useSelector(
    (state: RootState) => state.app
  );
  const [step, setStep] = useState<"reset" | "verify">("reset");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onBack = () => {
    navigate(-1);
  };

  const onUpdate = async () => {
    setShowError(false);
    setErrorMessage("");

    if (!validateEmail(email)) {
      setShowError(true);
      setErrorMessage(t("msg_invalid_email_entered"));
      return;
    }

    if (password === confirmPassword && !!email && !!password) {
      setShowError(false);
      setIsRegistering(true);

      const res = await apiUpdateEmail({
        email,
        password,
      });

      if (res?.success) {
        setStep("verify");
      } else {
        setErrorMessage(res?.error);
        setShowError(true);
      }

      setIsRegistering(false);
    } else if (!!password && password !== confirmPassword) {
      setErrorMessage(t("msg_password_mismatch"));
      setShowError(true);
    }
  };

  const onSuccess = () => {
    const newUserData = { ...userData, email };

    dispatch(
      appAction.loadUserInfoSuccess({
        userInfo: newUserData,
        socials: userSocials,
      })
    );

    showToast("success", t("msg_email_updated_success"));
    onBack();
  };

  const renderVerifyStep = () => {
    return (
      <div className="main-body">
        <div className="verify_content">
          <div className="register_verify">
            <Verification
              email={email}
              password={password}
              type="reset_email"
              onVerifiedReset={onSuccess}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderLogo = () => {
    return (
      <div>
        <div className="row_align_items">
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderResetBody = () => {
    return (
      <div className="main-body">
        <div className="main-content">
          <div className="font-medium font20">{t("update_email")}</div>
          <TextField
            id="outlined-basic"
            label={t("p_new_email_address")}
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            InputProps={{
              classes: {
                input: "font-regular font16",
              },
            }}
            style={{ width: "100%" }}
          />
          <TextField
            id="outlined-basic"
            label={t("p_password")}
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              classes: {
                input: "font-regular font16",
              },
              autoComplete: "new-password",
            }}
            autoComplete="new-password"
            type="password"
            style={{ width: "100%" }}
          />

          <TextField
            id="outlined-basic"
            label={t("p_confirm_password")}
            variant="outlined"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            InputProps={{
              classes: {
                input: "font-regular font16",
              },
              autoComplete: "new-password",
            }}
            autoComplete="new-password"
            type="password"
            style={{ width: "100%" }}
          />

          {renderConfinue()}
          {renderBackBtn()}
          {renderError()}
        </div>
      </div>
    );
  };

  const renderConfinue = () => {
    return (
      <div className="register-btn clickable" onClick={onUpdate}>
        {isRegistering ? (
          <LoadingCircular color="white" size={16} />
        ) : (
          <div className="row_align_items">
            <div
              className="font-regular font14"
              style={{ color: "white", marginRight: 6 }}
            >
              {t("CONTINUE")}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderBackBtn = () => {
    return (
      <div className="back-btn clickable" onClick={onBack}>
        <div className="btn-txt font-regular font14">{t("BACK")}</div>
      </div>
    );
  };

  const renderError = () => {
    if (showError) {
      return (
        <div className="error-message font-regular font14">{errorMessage}</div>
      );
    }
  };

  return (
    <div className="update_email_container row" style={{ marginLeft: 0 }}>
      <div className="left-panel col-md-8">
        {renderLogo()}
        {step === "reset" ? renderResetBody() : renderVerifyStep()}
      </div>
      <div className="right-panel col-md-4"></div>
    </div>
  );
};
