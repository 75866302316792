import React, { useEffect, useRef, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useQueryClient } from "@tanstack/react-query";
import appAction from "redux/app/action";

import "./index.styles.scss";
import { ExploreHeader } from "./Header";
import { ExploreTopics } from "./Topics";
import { ExploreVibe } from "./Vibes";
import { ExploreTrend } from "./Trend";
import { useInterestData } from "shared/hook/fetchData";
import { RootState } from "redux/interfaces";
import { useDispatch, useSelector } from "react-redux";
import { LoadingCircular } from "components/Common/LoadingCircular";
import ArrowUpperIcon from "assets/svg/ArrowUpperIcon";

const ExploreScreen = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const scrollRef = useRef<any>(null);
  const { userData, topicScrollPos } = useSelector(
    (state: RootState) => state.app
  );
  const [selectedFilter, setSelectedFilter] = useState<
    "all" | "new" | "published" | "hidden"
  >("all");
  const [isShowenScrollupBtn, setIsShowenScrollupBtn] = useState(false);

  const {
    data: arrBeData,
    fetchNextPage: fetchNextPageBeTopic,
    hasNextPage: hasNextPageBeTopic,
    isFetchingNextPage: isFetchingTopic,
    isInitialLoading,
    isFetched: isFetchedBeTopic,
    refetch: refetchBeTopics,
  } = useInterestData(
    userData?.id,
    dispatch,
    undefined,
    selectedFilter === "all" ? null : selectedFilter
  );

  useEffect(() => {
    if (scrollRef && scrollRef.current && topicScrollPos) {
      scrollRef.current.scrollTop(topicScrollPos);
    }
  }, [isFetchedBeTopic]);

  // useEffect(() => {
  //   refetchBeTopics();
  // }, [selectedFilter]);

  // useEffect(() => {
  //   console.log('>>>',arrBeData)
  // }, [arrBeData]);

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10 &&
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight >= 0;

    if (bottom) {
      hasNextPageBeTopic && !isFetchingTopic && fetchNextPageBeTopic();
      setIsShowenScrollupBtn(true);
    }
    else {
      setIsShowenScrollupBtn(false);
    }

    dispatch(appAction.onUpdateScrollPos(e.target.scrollTop));
  };

  const onScrollUp = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop({
        top: scrollRef.current.getScrollTop(),
        behavior: "smooth",
      });
    }
  }

  return (
    <div className="explore_body">
      <div className="center-content" style={{position:'relative'}}>
        <Scrollbars
          ref={scrollRef}
          onScroll={handleScroll}
          renderThumbVertical={(props) => (
            <div {...props} className="thumb-vertical" />
          )}
          style={{ paddingBottom: 100 }}
        >
          {/* <ExploreHeader /> */}
          <ExploreTrend />
          <ExploreTopics
            selectedFilter={selectedFilter}
            isFetchedBeTopic={isFetchedBeTopic}
            isInitialLoading={isInitialLoading}
            arrBeData={arrBeData}
            refetchBeTopics={refetchBeTopics}
            onUpdatedFilter={setSelectedFilter}
          />
        </Scrollbars>
        {isFetchingTopic && (
          <div className="center" style={{ paddingTop: 8, paddingBottom: 8 }}>
            <LoadingCircular size={24} color="main" />
          </div>
        )}
        {/* {isShowenScrollupBtn && (
          <div className="scrollUpBtn" style={{transform: 'rotate(180deg)' }}
          onClick={onScrollUp}>
            <ArrowUpperIcon size={50} color={'black'}/>
          </div>
        )} */}
      </div>
      <div className="right-content">
        <ExploreVibe />
      </div>
    </div>
  );
};

export default ExploreScreen;
