import HttpClient from "./apiClient";

export async function apiPublishTopic(params) {
  try {
    const response = await HttpClient.postWithToken(
      "/extension/publish",
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetInvitations(params) {
  try {
    const response = await HttpClient.getWithToken(
      "/extension/invitations",
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiGetExtensionLevel(params: { aggregated: number }) {
  try {
    const response = await HttpClient.getWithToken("/extension/levels", params);
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiEditPublishThreshold(params: { threshold: number }) {
  try {
    const response = await HttpClient.postWithToken(
      "/extension/editPublishThreshold",
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}

export async function apiRedeemReward(params: { reward: number }) {
  try {
    const response = await HttpClient.postWithToken(
      "/extension/redeemReward",
      params
    );
    return response?.data;
  } catch (e) {
    console.log(e);
  }
}
