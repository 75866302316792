const actions = {
  SUCCESS_LOGIN: "SUCCESS_LOGIN",
  SUCCESS_LOGOUT: "SUCCESS_LOGOUT",
  LOAD_USER_INFO: "LOAD_USER_INFO",
  LOAD_USER_INFO_SUCCESS: "LOAD_USER_INFO_SUCCESS",
  LOAD_USER_INFO_FAILED: "LOAD_USER_INFO_FAILED",
  TOOGLE_CREATING_MOOD_MODAL: "TOOGLE_CREATING_MOOD_MODAL",
  TOOGLE_REWARD_MODAL: "TOOGLE_REWARD_MODAL",
  TOOGLE_REWARD_HISTORY_MODAL: "TOOGLE_REWARD_HISTORY_MODAL",
  TOOGLE_REWARD_DETAIL_MODAL: "TOOGLE_REWARD_DETAIL_MODAL",
  TOOGLE_INVITATION_MODAL: "TOOGLE_INVITATION_MODAL",
  TOOGLE_INVITATION_SUCCESS_MODAL: "TOOGLE_INVITATION_SUCCESS_MODAL",
  TOOGLE_ADD_REFERRAL_CODE_MODAL: "TOOGLE_ADD_REFERRAL_CODE_MODAL",
  TOOGLE_SEARCH_MODAL: "TOOGLE_SEARCH_MODAL",
  CHANGED_SEARCH: "CHANGED_SEARCH",
  SHOW_IMAGE_FULL_MODAL: "SHOW_IMAGE_FULL_MODAL",
  SHOW_QR_MODAL: "SHOW_QR_MODAL",
  SHOW_CONFIRM_DOWNLOAD_APP_MODAL: "SHOW_CONFIRM_DOWNLOAD_APP_MODAL",
  CLOSE_IMAGE_FULL_MODAL: "CLOSE_IMAGE_FULL_MODAL",

  UPDATE_SCROLL_POS_TOPICS: "UPDATE_SCROLL_POS_TOPICS",
  UPDATE_SCROLL_POS_VIBEUSER: "UPDATE_SCROLL_POS_VIBEUSER",
  UPDATE_IS_NEW_USER: "UPDATE_IS_NEW_USER",
  UPDATE_VIBE_USER_INFO: "UPDATE_VIBE_USER_INFO",

  successLogin: (data) => ({
    payload: data,
    type: actions.SUCCESS_LOGIN,
  }),
  successLogout: () => ({
    type: actions.SUCCESS_LOGOUT,
  }),
  loadUserInfo: (userId: number) => ({
    type: actions.LOAD_USER_INFO,
    payload: userId,
  }),
  loadUserInfoSuccess: (data) => ({
    type: actions.LOAD_USER_INFO_SUCCESS,
    payload: data,
  }),
  loadUserInfoFailed: () => ({
    type: actions.LOAD_USER_INFO_FAILED,
  }),
  toogleCreatingMoodModal: (show: boolean) => {
    return {
      type: actions.TOOGLE_CREATING_MOOD_MODAL,
      payload: show,
    };
  },
  toogleRewardModal: (show: boolean) => {
    return {
      type: actions.TOOGLE_REWARD_MODAL,
      payload: show,
    };
  },
  toogleRewardHistoryModal: (show: boolean) => {
    return {
      type: actions.TOOGLE_REWARD_HISTORY_MODAL,
      payload: show,
    };
  },
  toogleInvitationModal: (show: boolean) => {
    return {
      type: actions.TOOGLE_INVITATION_MODAL,
      payload: show,
    };
  },
  toogleInvitationSuccessModal: (show: boolean) => {
    return {
      type: actions.TOOGLE_INVITATION_SUCCESS_MODAL,
      payload: show,
    };
  },
  toogleSearchModal: (show: boolean) => {
    return {
      type: actions.TOOGLE_SEARCH_MODAL,
      payload: show,
    };
  },
  toogleRewardDetailModal: (show: boolean, rewardInfo: any) => {
    return {
      type: actions.TOOGLE_REWARD_DETAIL_MODAL,
      payload: { show, rewardInfo },
    };
  },
  toogleUserQRModal: (show: boolean) => {
    return {
      type: actions.SHOW_QR_MODAL,
      payload: show,
    };
  },
  toogleAddReferralCodeModal: (show: boolean) => {
    return {
      type: actions.TOOGLE_ADD_REFERRAL_CODE_MODAL,
      payload: show,
    };
  },
  toogleConfirmDownloadAppModal: (show: boolean) => {
    return {
      type: actions.SHOW_CONFIRM_DOWNLOAD_APP_MODAL,
      payload: show,
    };
  },
  onChangedSearch: (query: string) => {
    return {
      type: actions.CHANGED_SEARCH,
      payload: query,
    };
  },
  onShowImageFullView: (imageUrl: string) => {
    return {
      type: actions.SHOW_IMAGE_FULL_MODAL,
      payload: imageUrl,
    };
  },
  onHideImageFullView: () => {
    return {
      type: actions.CLOSE_IMAGE_FULL_MODAL,
    };
  },
  onUpdateScrollPos: (pos: any) => {
    return {
      type: actions.UPDATE_SCROLL_POS_TOPICS,
      payload: pos,
    };
  },
  onUpdateScrollPosVibes: (pos: any) => {
    return {
      type: actions.UPDATE_SCROLL_POS_VIBEUSER,
      payload: pos,
    };
  },
  onUpdateIsNewUser: (isNew: boolean) => {
    return {
      type: actions.UPDATE_IS_NEW_USER,
      payload: isNew,
    };
  },
  onUpdateVibeUserInfo: (vibeUserInfo: any) => {
    return {
      type: actions.UPDATE_VIBE_USER_INFO,
      payload: vibeUserInfo,
    };
  },
};

export default actions;
