import React from "react";

function TwitterIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.size ?? 18}
      height={props.size ?? 18}
      fill="none"
      viewBox="0 0 18 18"
    >
      <g clip-path="url(#clip0_104_2183)">
        <path
          d="M10.6756 7.62177L17.2324 0H15.6787L9.9854 6.61788L5.43819 0H0.193523L7.0698 10.0074L0.193523 18H1.74737L7.75962 11.0113L12.5618 18H17.8065L10.6752 7.62177H10.6756ZM8.54742 10.0956L7.85071 9.09906L2.30724 1.16971H4.69385L9.1675 7.56895L9.86421 8.56546L15.6794 16.8835H13.2928L8.54742 10.096V10.0956Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_104_2183">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TwitterIcon;
