import OpacityModal from "components/Common/OpacityModal/OpacityModal";
import { RectangleLoadingAnimation } from "components/Common/RectangleLoadingAnimation";
import React from "react";

import "./index.styles.scss";
import { useTranslation } from "react-i18next";

export const CreatingMoodModal = () => {
  const { t } = useTranslation();

  return (
    <OpacityModal onClick={() => {}}>
      <div className="modal_creating_mood">
        <div className="modal_content">
          <div style={{ color: "#E3F4FF" }} className="font-bold font32">
            {t("creating_mood")}
          </div>
          <RectangleLoadingAnimation backgroundColor="#E3F4FF" />
        </div>
      </div>
    </OpacityModal>
  );
};
