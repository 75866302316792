import React, { useEffect } from "react";

import "./index.styles.scss";
import { PinkRoundAvatar } from "components/Common/PinkRoundAvatar";
import { VibeMatchPercentHorizontal } from "components/Common/VibeMatchPercentHorizontal";
import { PinkBgBtn } from "components/Common/PinkBgBtn";
import { useNavigate } from "react-router-dom";
import { RootState } from "redux/interfaces";
import { useSelector } from "react-redux";

interface Props {
  data: any;
}

export const CardInterestedPeople = ({ data }: Props) => {
  const naivgate = useNavigate();
  const { userId } = useSelector((state: RootState) => state.app);

  const onVibe = () => {
    naivgate(`/vibes/${data.creator_id}`);
  };

  useEffect(() => {
    console.log(data);
   }, []);
  const renderBg = () => {
    return (
      <img
        src={require("assets/image/bg_interested_people.png")}
        style={{ width: "100%", objectFit: "contain" }}
      />
    );
  };

  const renderContent = () => {
    return (
      <div className="item_content">
        <div className="left_content">
          <PinkRoundAvatar size={52} url={data.creator_image} />
          <div className="user_info">
            <div className="font-medium font16">{data.creator_name}</div>
            <VibeMatchPercentHorizontal score={data.vibes_score} vibes_type={data.vibes_type}/>
          </div>
        </div>
        <div className="right_content">
          <div style={{ width: 90 }}>
            {data.creator_id !== userId && (
              <PinkBgBtn
                title="Vibes"
                isLoading={false}
                isSmall={true}
                onClicked={() => {
                  onVibe();
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="card_interested_people">
      {renderBg()}
      {renderContent()}
    </div>
  );
};
