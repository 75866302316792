import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const PhoneIcon = (props: IconProps) => {
  return (
    <svg
      style={props.style}
      width={props.width ?? 30}
      height={props.height ?? 30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2857 21.4V8.6H10.7143V21.4H19.2857ZM9 7V23H21V7H9Z"
        fill="black"
      />
      <path
        d="M16 11C16 11.5523 15.5523 12 15 12C14.4477 12 14 11.5523 14 11C14 10.4477 14.4477 10 15 10C15.5523 10 16 10.4477 16 11Z"
        fill="black"
      />
    </svg>
  );
};
