import React, { useRef, useState } from "react";

import "./index.styles.scss";
import NewUserAvatar from "assets/svg/NewUserAvatar";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { useTranslation } from "react-i18next";
import useIPFS from "shared/utils-IPFS/useIPFS";
import { getBase64String } from "shared/utils-IPFS/functions";
import { apiEditProfile, apiEditProfileImage } from "shared/api/user";
import { showToast } from "components/Common/PrimaryToast";
import { useDispatch, useSelector } from "react-redux";
import appAction from "redux/app/action";
import { RootState } from "redux/interfaces";
import { PinkRoundAvatar } from "components/Common/PinkRoundAvatar";
import { useNavigate } from "react-router-dom";
import { RootNames } from "shared/routes/rootUrl";

export const SetProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userData } = useSelector((state: RootState) => state.app);
  const [username, setUserName] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [imageUrl, setImageUrl] = useState<any>(userData?.image);

  const hiddenFileInput = useRef<any>(null);
  const { uploadBase64 } = useIPFS();

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    event.preventDefault();
    const files = event.target.files;
    if (files.length) {
      const name = files[0].name;
      const size = files[0].size;
      const type = files[0].type;
      setIsUploadingFile(true);

      const base64String = await getBase64String(files[0]);
      const url = await uploadBase64(base64String, type, "");

      const res = await apiEditProfileImage({ profileImage: url });
      if (res.success) {
        showToast("success", t("msg_profile_picture_updated_success"));
        setImageUrl(url + "");
        dispatch(appAction.loadUserInfo(userData!.id));
      }
      setIsUploadingFile(false);
    }
  };

  const onSave = async () => {
    if (!isSaving && !isUploadingFile && !!username) {
      setIsSaving(true);

      const res = await apiEditProfile({ username: username });

      if (res.success) {
        dispatch(appAction.loadUserInfo(userData!.id));
        dispatch(appAction.onUpdateIsNewUser(false));
        navigate(RootNames.rewardCode);
      }
    } else if (username === "") {
      showToast("error", t("msg_enter_your_name"));
    }
  };

  const renderLogo = () => {
    return (
      <div>
        <div className="row_align_items">
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="set_profile_body">
        <div className="font-bold font20">{t("wlecome_complete_profile")}</div>
        <div className="picture_view">
          {imageUrl && imageUrl.startsWith("https") ? (
            <PinkRoundAvatar size={94} url={imageUrl} />
          ) : (
            <NewUserAvatar />
          )}
          <div className="upload_picture_btn clickable" onClick={handleClick}>
            {isUploadingFile ? (
              <LoadingCircular size={24} color="main" />
            ) : (
              <div className="font-regular font14">
                {t("new_picture_upload")}
              </div>
            )}
          </div>
          <input
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: "none" }}
            accept="image/*"
          />
        </div>
        <div className="user_name">
          <input
            value={username}
            type="text"
            className="font-regular font16 name_input"
            placeholder={t("set_user_name")}
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>
        <div className="next-btn clickable" onClick={onSave}>
          {isSaving ? (
            <LoadingCircular color="white" size={24} />
          ) : (
            <div className="row_align_items">
              <div
                className="font-bold font16"
                style={{ color: "white", marginRight: 6 }}
              >
                {t("CONTINUE")}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="set_profile">
      <div className="left-panel col-md-8">
        {renderLogo()}
        <div className="main-body">
          <div className="main-content">{renderBody()}</div>
        </div>
      </div>
      <div className="right-panel col-md-4"></div>
    </div>
  );
};
