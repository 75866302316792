import React, { useState } from "react";
import Cookies from "js-cookie";

import "./index.styles.scss";
import appAction from "redux/app/action";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import NormalArrowRightIcon from "assets/svg/NormalArrowRightIcon";
import { LoadingCircular } from "components/Common/LoadingCircular";
import { RootNames } from "shared/routes/rootUrl";
import { apiLoginWithEmail } from "shared/api/user";
import { getCurrentLng, getReferralCode } from "shared/api/storageHelper";
import { useAuth0 } from "@auth0/auth0-react";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";
import { apiGetUserVibeInfo } from "shared/api/topics";

interface Props {
  onVerify: (email: string) => void;
}

export const LoginEmail = ({ onVerify }: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading: isLoadingSocialLogin } = useAuth0();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLogging, setIsLogging] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onRegister = () => {
    navigate(-1);
    // navigate(RootNames.register);
  };

  const onForgetPassword = () => {
    navigate(RootNames.forget_password);
  };

  const loadUserVibeInfo = async () => {
    const res = await apiGetUserVibeInfo({});

    if (res.success) {
      const userVibeInfo = res.user_highlight;
      dispatch(appAction.onUpdateVibeUserInfo(userVibeInfo));
    }
  };

  const onLogin = async () => {
    setShowError(false);

    if (!!email && !!password && !isLogging && !isLoadingSocialLogin) {
      setIsLogging(true);

      const res = await apiLoginWithEmail({
        email,
        password,
        is_extension: true,
        referral: getReferralCode(),
      });

      if (res?.success) {
        if (res.verified === 1) {
          Cookies.set("accessToken", res.accessToken);
          Cookies.set("extensionToken", res?.extensionToken);
          localStorage.setItem("userId", res.userData.id);

          dispatch(appAction.successLogin(res.userData));
          dispatch(appAction.onUpdateIsNewUser(false));
          loadUserVibeInfo();
          navigate("/");
        } else {
          // go to verify screen
          onVerify(email);
        }
      } else {
        setErrorMessage(res?.error ?? res);
        setShowError(true);
      }

      setIsLogging(false);
    } else if (password === "") {
      showToast("error", t("msg_enter_your_password_to_login"));
    }
  };

  const renderActions = () => {
    return (
      <div className="actions">
        <div className="login-btn clickable" onClick={onLogin}>
          {isLogging || isLoadingSocialLogin ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <div className="row_align_items">
              <div
                className="text_no_wrap font-regular font14"
                style={{ color: "white", marginRight: 6 }}
              >
                {t("LOGIN")}
              </div>
              <div className="clickable">
                <NormalArrowRightIcon />
              </div>
            </div>
          )}
        </div>
        <div
          className="font-medium font14 clickable"
          onClick={onForgetPassword}
        >
          {t("forgot_your_password")}
        </div>
      </div>
    );
  };

  const renderCreateNewAccount = () => {
    return (
      <div className="create-btn clickable" onClick={onRegister}>
        <div className="btn-txt">{t("BACK")}</div>
      </div>
    );
  };

  const renderDivider = () => {
    return <div className="divider"></div>;
  };

  const renderError = () => {
    if (showError) {
      return (
        <div className="error-message font-regular font14">{errorMessage}</div>
      );
    }
  };

  return (
    <div className="login-email">
      <div className="font-medium font20">{t("sign_in")}</div>
      <input
        value={email}
        className="input-box font-regular font16"
        placeholder={t("p_email_address")}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        value={password}
        className="input-box font-regular font16"
        placeholder={t("p_password")}
        type="password"
        onChange={(e) => setPassword(e.target.value)}
      />
      {renderActions()}
      {renderCreateNewAccount()}
      {renderDivider()}
      {renderError()}
    </div>
  );
};
