import React from "react";
import { Route, Routes } from "react-router-dom";
import { RootNames } from "./rootUrl";
import { QRScreen } from "components/Main/QR";
import ExploreScreen from "components/Main/Explore";
import SettingScreen from "components/Main/Setting";
import { VibeMatch } from "components/Main/Explore/VibeMatch";
import RedditConnect from "components/Main/Setting/SocialMedia/RedditConnect";
import { SubInterest } from "components/Main/SubInterest";
import { InterestDetail } from "components/Main/Explore/Interest/InterestDetail";
import { SubInterestDetail } from "components/Main/Explore/Interest/SubInterestDetail";
import { TrendDetail } from "components/Main/Explore/Trend/TrendDetail";

export const MainRoutes = () => {
  const socialConnect = localStorage.getItem("socialConnect");
  
  return (
    <Routes>
      <Route index path={RootNames.root} element={<QRScreen />} />
      <Route path={RootNames.qr} element={(socialConnect === 'reddit' || socialConnect === 'twitter') ? <SettingScreen /> : <QRScreen/>} />
      <Route path={RootNames.profile} element={<ExploreScreen />} />
      <Route path={RootNames.settings} element={<SettingScreen />} />
      <Route path={RootNames.qr + "/:type"} element={<QRScreen />} />
      <Route path={RootNames.vibes} element={<VibeMatch />} />
      <Route path={RootNames.redditConnect} element={<RedditConnect />} />
      <Route path={RootNames.subinterest} element={<SubInterest />} />
      <Route path={RootNames.interest} element={<InterestDetail />} />
      <Route
        path={RootNames.subInterestDetail}
        element={<SubInterestDetail />}
      />
      <Route path={RootNames.trendDetail} element={<TrendDetail />} />
    </Routes>
  );
};
