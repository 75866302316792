import React from "react";

import "./index.styles.scss";
import { useTranslation } from "react-i18next";

export const NoExtension = () => {
  const { t } = useTranslation();

  const onInstall = () => {
    let url = "";
    if (typeof browser !== "undefined") { //Safari
      url = process.env.REACT_APP_SAFARI_STORE ?? "";
    } else if (typeof chrome !== "undefined") { // Chrome
      url = process.env.REACT_APP_CHROME_STORE ?? "";
    }
    window.location.href = url;
  };

  const renderLogo = () => {
    return (
      <div>
        <div className="row_align_items">
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className="main-body">
        <div className="main-content">
          <div className="font-medium font20">{t("ready_for_incoming")}</div>
          <div className="font-regular font16">
            {t("first_snag", { link: process.env.REACT_APP_APP_NAME })}
            <div>{t("qucik_click")}</div>
            <div>{t("then_roll_onto")}</div>
          </div>
          <div className="register-btn clickable" onClick={onInstall}>
            <div className="row_align_items">
              <div
                className="font-regular font16"
                style={{ color: "white", marginRight: 6 }}
              >
                {t("get_the_vibe_extension")}
              </div>
            </div>
          </div>
          <div
            className="font-regular font14"
            style={{ textAlign: "center", opacity: 0.6 }}
          >
            {t("your_data_locked")}
            <div>{t("only_you_can_see")}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="no-extension row" style={{ marginLeft: 0 }}>
      <div className="left-panel col-md-8">
        {renderLogo()}
        {renderBody()}
      </div>
      <div className="right-panel col-md-4"></div>
    </div>
  );
};
