import React from "react";

import { IconProps } from "../../shared/utils/type.def";

export const NormalPlusIcon = (props: IconProps) => {
  return (
    <svg
      style={props.style}
      width={props.width ?? 24}
      height={props.height ?? 24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.874 20.857a.801.801 0 01-.802-.801v-3.3a4.101 4.101 0 00-4.101-4.102H3.637a.78.78 0 110-1.56h3.327a4.108 4.108 0 004.108-4.109V3.658a.801.801 0 011.603 0v3.344a4.09 4.09 0 004.09 4.091h3.311a.78.78 0 010 1.561h-3.31a4.09 4.09 0 00-4.091 4.091v3.311a.801.801 0 01-.801.801z"
        fill={props.color ?? "white"}
      />
    </svg>
  );
};
