import { useInfiniteQuery } from "@tanstack/react-query";
import {
  apiGetSubTopics,
  apiGetTopicCommon,
  apiGetTopicHighlights,
  apiGetTopicTrend,
  apiGetTopicUserFeed,
  apiGetTopicUserMatch,
  apiGetTopicUserTrend,
  apiGetTopicUsers,
} from "shared/api/topics";
import appActions from "redux/app/action";
import { apiGetInvitations } from "shared/api/extensions";

export const useInterestData = (
  userId: number | undefined,
  dispatch: any,
  query?: string,
  type?: any
) => {
  return useInfiniteQuery(
    ["interests", userId, query, type],
    async ({ pageParam }) => {
      const res = await apiGetTopicUserFeed({
        userId: userId,
        limit: 10,
        offset: !!query ? 0 : pageParam || 0,
        // query: query,
        type,
      });
      if (res.success) {
        return { data: res.topics, lastId: res.lastId, hasMore: res.hasMore };
      } else if (res?.code === 403) {
        dispatch(appActions.successLogout());
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
      cacheTime: 1000 * 60 * 30, // Unused data is deleted from cache after 30 minutes
    }
  );
};

export const useVibeMatchInterestData = (
  userId: number | undefined,
  query?: string
) => {
  return useInfiniteQuery(
    ["vibe_check", userId, query],
    async ({ pageParam }) => {
      const res = await apiGetTopicCommon({
        toUser: userId,
        limit: 20,
        offset: pageParam,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
      staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Unused data is deleted from cache after 30 minutes
    }
  );
};

export const useUserMatch = (dispatch: any, query?: string) => {
  return useInfiniteQuery(
    ["user_match", query],
    async ({ pageParam }) => {
      const res = await apiGetTopicUserMatch({
        limit: 10,
        offset: !!query ? 0 : pageParam || 0,
        query: query,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else if (res?.code === 403) {
        dispatch(appActions.successLogout());
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
      cacheTime: 1000 * 60 * 30, // Unused data is deleted from cache after 30 minutes
    }
  );
};

export const useUserMood = (dispatch: any, userId: any) => {
  return useInfiniteQuery(
    ["user_mood"],
    async ({ pageParam }) => {
      const res = await apiGetTopicHighlights({
        item_id: userId,
        item_type: "user",
        limit: 10,
        offset: pageParam,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else if (res?.code === 403) {
        dispatch(appActions.successLogout());
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
      cacheTime: 1000 * 60 * 30, // Unused data is deleted from cache after 30 minutes
    }
  );
};

export const useInvitation = (dispatch: any) => {
  return useInfiniteQuery(
    ["user_invitation"],
    async ({ pageParam }) => {
      const res = await apiGetInvitations({
        limit: 10,
        offset: pageParam,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else if (res?.code === 403) {
        dispatch(appActions.successLogout());
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
      cacheTime: 1000 * 60 * 30, // Unused data is deleted from cache after 30 minutes
    }
  );
};

export const useCommunityTrendData = () => {
  return useInfiniteQuery(
    ["communityTrendData"],
    async ({ pageParam }) => {
      const res = await apiGetTopicTrend({
        limit: 5,
        offset: pageParam || 0,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
      staleTime: 1000 * 60 * 5, // The data will be considered fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // The cache will be kept for 30 minutes
      retry: 1, // Retry once if the query fails
    }
  );
};

export const useTrendData = (
  userId?: number,
  limit?: number,
  queryName?: string
) => {
  return useInfiniteQuery(
    [queryName ?? "profileTrendData", userId],
    async ({ pageParam }) => {
      const res = await apiGetTopicUserTrend({
        userId: userId,
        limit: limit ?? 5,
        offset: pageParam || 0,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
    }
  );
};

export const useInterestTopUser = (vibe: any, limit?: number) => {
  return useInfiniteQuery(
    ["interestDetailTopUser", vibe],
    async ({ pageParam }) => {
      const res = await apiGetTopicUsers({
        vibe: vibe,
        limit: limit ?? 5,
        offset: pageParam || 0,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
    }
  );
};

export const useMicroInterest = (
  vibeId: any,
  type?: "new" | "public" | "hidden"
) => {
  return useInfiniteQuery(
    ["micro_interest", vibeId, type],
    async ({ pageParam }) => {
      const res = await apiGetSubTopics({
        vibe: vibeId,
        filter: type,
        limit: 20,
        offset: pageParam,
      });

      if (res.success) {
        return { data: res.data, lastId: res.lastId, hasMore: res.hasMore };
      } else {
        throw new Error("Error fetching data");
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage && lastPage.hasMore) {
          return lastPage.lastId;
        }
        return undefined;
      },
      staleTime: 1000 * 60 * 5, // Data is considered fresh for 5 minutes
      cacheTime: 1000 * 60 * 30, // Unused data is deleted from cache after 30 minutes
    }
  );
};
