import React from "react";

import "./index.styles.scss";
import { useInterestTopUser } from "shared/hook/fetchData";
import { PinkRoundAvatar } from "components/Common/PinkRoundAvatar";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/interfaces";

interface Props {
  vibeId: any;
  arrTopUser: Array<any>;
}

export const InterestDetailTop = ({ vibeId, arrTopUser }: Props) => {
  const { t } = useTranslation();
  const { userId } = useSelector((state: RootState) => state.app);
  const naivgate = useNavigate();

  const onVibe = (item: any) => {
    if (userId !== item.creator_id) naivgate(`/vibes/${item.creator_id}`);
  };

  return (
    <div className="interest_top">
      <div className="font-medium font24">{t("top_5")}</div>
      <div className="interest_top_list">
        {arrTopUser.map((item, index) => {
          return (
            <div
              className="item_top_user clickable"
              key={`interest_card_top_${index}`}
              onClick={() => {
                onVibe(item);
              }}
            >
              <PinkRoundAvatar
                size={64}
                url={item.creator_image}
                enableFullImageView={false}
              />
              <div className="font-regular font16 item_top_user_name">
                {item.creator_name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
