import React, { useEffect, useState } from "react";

import "./index.styles.scss";
import { apiRecoverPassword, apiRequestRecoverPassword } from "shared/api/user";
import { Verification } from "../Verification";
import { PrimaryButton } from "components/Common/PrimaryButton/PrimaryButton";
import { TextField } from "@mui/material";
import { LoadingCircular } from "components/Common/LoadingCircular";
import NormalArrowRightIcon from "assets/svg/NormalArrowRightIcon";
import { useNavigate } from "react-router-dom";
import { showToast } from "components/Common/PrimaryToast";
import { useTranslation } from "react-i18next";

export const ResetPasswordScreen = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [step, setStep] = useState<"request" | "verify" | "newPassword">(
    "request"
  );

  const [email, setEmail] = useState("");
  const [isSending, setIsSending] = useState(false);

  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSettingNew, setIsSettingNew] = useState(false);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    setErrorMessage("");
    setShowError(false);
  }, [step]);

  const onBack = () => {
    navigate(-1);
  };

  const onRequestRecover = async () => {
    setShowError(false);

    if (!!email) {
      setIsSending(true);

      const res = await apiRequestRecoverPassword({
        email,
      });

      if (res.success) {
        setStep("verify");
      } else {
        setErrorMessage(res?.error);
        setShowError(true);
      }

      setIsSending(false);
    }
  };

  const onVerifyReset = async (code: string) => {
    setCode(code);
    setStep("newPassword");
  };

  const onSetNewPassword = async () => {
    setShowError(false);

    if (!!newPassword && !!confirmPassword && newPassword === confirmPassword) {
      if (newPassword.length < 10) {
        setShowError(true);
        setErrorMessage(t("msg_too_easy_to_guess"));
        return;
      }

      if (!!code) {
        setIsSettingNew(true);
        const res = await apiRecoverPassword({
          email,
          verificationCode: code,
          newPassword: newPassword!,
        });
        if (res?.success) {
          showToast("success", t("msg_password_changed"));
          navigate("/");
        } else {
          setErrorMessage(res?.error);
          setShowError(true);
        }
        setIsSettingNew(false);
      }
    } else if (!!newPassword && newPassword !== confirmPassword) {
      setErrorMessage(t("msg_password_mismatch"));
      setShowError(true);
    }
  };

  const renderLogo = () => {
    return (
      <div>
        <div className="row_align_items">
          <img src={process.env.REACT_APP_LOGO ?? ""} className="app-logo" />
          <div className="font-app-title font28">
            {process.env.REACT_APP_APP_NAME}
          </div>
        </div>
      </div>
    );
  };

  const renderRequestStep = () => {
    return (
      <div className="email_step">
        <div className="font-medium font20">{t("reset_your_password")}</div>
        <div className="font-regular font14" style={{ opacity: 0.8 }}>
          {t("type_in_registered_email")}
        </div>
        <TextField
          id="outlined-basic"
          label={t("p_email_address")}
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          InputProps={{
            classes: {
              input: "font-regular font16",
            },
          }}
          style={{ width: "100%", marginTop: 40 }}
        />
        <div className="next-btn clickable" onClick={onRequestRecover}>
          {isSending ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <div className="row_align_items">
              <div
                className="font-regular font14"
                style={{ color: "white", marginRight: 6 }}
              >
                {t("NEXT")}
              </div>
              <div className="clickable">
                <NormalArrowRightIcon />
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: 66 }}>{renderBackBtn()}</div>
        <div style={{ marginTop: 16 }}>{renderError()}</div>
      </div>
    );
  };

  const renderBackBtn = () => {
    return (
      <div className="back-btn clickable" onClick={onBack}>
        <div className="btn-txt font-regular font14">{t("BACK_TO_LOGIN")}</div>
      </div>
    );
  };

  const renderVerifyStep = () => {
    return (
      <div className="register_verify">
        <Verification
          email={email}
          type={"reset_pass"}
          onVerifiedReset={onVerifyReset}
        />
      </div>
    );
  };

  const renderResetStep = () => {
    return (
      <div className="email_step">
        <div className="font-medium font20">{t("reset_your_password")}</div>
        <div className="font-regular font14" style={{ opacity: 0.8 }}>
          {t("type_in_new_password")}
        </div>
        <TextField
          id="outlined-basic"
          label={t("p_new_password")}
          variant="outlined"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          InputProps={{
            classes: {
              input: "font-regular font16",
            },
          }}
          type="password"
          style={{ width: "100%", marginTop: 40 }}
        />

        <TextField
          id="outlined-basic"
          label={t("p_retry_new_password")}
          variant="outlined"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          InputProps={{
            classes: {
              input: "font-regular font16",
            },
          }}
          type="password"
          style={{ width: "100%", marginTop: 16 }}
        />
        <div className="next-btn clickable" onClick={onSetNewPassword}>
          {isSettingNew ? (
            <LoadingCircular color="white" size={16} />
          ) : (
            <div className="row_align_items">
              <div
                className="font-regular font14"
                style={{ color: "white", marginRight: 6 }}
              >
                {t("RESET")}
              </div>
              <div className="clickable">
                <NormalArrowRightIcon />
              </div>
            </div>
          )}
        </div>
        <div style={{ marginTop: 66 }}>{renderBackBtn()}</div>
        <div style={{ marginTop: 16 }}>{renderError()}</div>
      </div>
    );
  };

  const renderError = () => {
    if (showError) {
      return (
        <div className="error-message font-regular font14">{errorMessage}</div>
      );
    }
  };

  return (
    <div className="reset_password_container row" style={{ marginLeft: 0 }}>
      <div className="left-panel col-md-8">
        {renderLogo()}
        <div className="main-body">
          <div className="main-content">
            {step === "request"
              ? renderRequestStep()
              : step === "verify"
              ? renderVerifyStep()
              : renderResetStep()}
          </div>
        </div>
      </div>
      <div className="right-panel col-md-4"></div>
    </div>
  );
};
